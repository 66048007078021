import React, { useCallback, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { dummyiddata, idcardtableColumns } from '../common/mockData';
import { useNavigate } from 'react-router';
import { Btn, H4 } from '../../../../AbstractElements';
import EditCommonModal from '../common/EditCommonModal';
import IdCardFilter from './IdCardFilter';
import { CSVLink } from 'react-csv';
import { API_URL } from '../config/api_urls';
import { DELETE, get, patch } from '../config';
import { toast } from 'react-toastify';

function Idcard() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [originalData,setOriginalData] = useState([])
  const [toggleDelet, setToggleDelet] = useState(false);
  const [idcardModal, setIdcardModal] = useState(false);
  const [regionalPartner,setRegionalPartner] = useState('');
  const [paymentStatus,setPaymentStatus] = useState('');
  const [csvData,setCsvData] = useState([]);
  const [editIdCard,setEditIdCard] = useState();

  

 

useEffect(()=>{
  fetchIdCardList();
},[])

  const fetchIdCardList = useCallback(() => { 
		get(API_URL.IDCARDS.GET_IDCARDS)
			.then((response) => {
        console.log(response);
        
				if (response?.data.length >= 0) {
					setData(response.data);
					setOriginalData(response.data);
				}
			})
			.catch((error) => {
				console.error("Error:", error);
			});
	}, []);




  const handleNavigate =()=>{
    navigate(`${process.env.PUBLIC_URL}/id-card/new/:layout`)
  }

  const onSave = (formData) => {
    console.log(formData);
    
    if (formData.id) {
      patch(API_URL.IDCARDS.PATCH_IDCARD(formData.id), formData)
        .then((response) => {
          console.log(response);
          
          toast.success("Updated successfully");
          fetchIdCardList();
        })
        .catch((error) => {
          console.error("Error updating data:", error);
          toast.error("Oops! something went wrong, try again");
        });
    } else {
      toast.error("Oops!something went wrong!")
    }
  };

  const handleEditdata =(row,e)=>{
    console.log(row);
    console.log(e.target.value);
    
    
    const formData = {
      id: row.id,
      status : e.target.value
    }
    onSave(formData)
  }
 

  const idcardToggle =()=>{
    setIdcardModal(!idcardModal);
  }
  const handleSave = () => {
    idcardToggle();
    const filteredDatas = data.filter(item=>{
      return (regionalPartner ? item.regional_partner_name === regionalPartner : true) && (paymentStatus ? item.payment_status === paymentStatus : true) ;
    })
    setData(filteredDatas);
  };
  
  const handleCancel = () => {
    idcardToggle();
    setRegionalPartner("");
    setPaymentStatus("");
    setData(originalData)
  };


  const handleExport=()=>{
    setCsvData(data);
  }

  const handleDeletedata = (row)=>{
    console.log(row);
    
    if(row?.id){
     DELETE(API_URL.IDCARDS.DELETE_IDCARD(row?.id))
     .then((response) => {

       toast.success("Deleted successfully");
       fetchIdCardList();
     })
     .catch((error) => {
       toast.error("Oops! something went wrong",error);
     });
    }
   }


 

  return (
    <>
       <div className={`d-flex align-items-center justify-content-between  p-4`}>
                    <H4 attrH4={{ className: 'text-muted m-0' }}>All Id Card Data</H4>
                    <div style={{width:"400px",display:'flex',justifyContent:"space-around"}}>
                    <Btn attrBtn={{ color: 'primary', onClick:handleNavigate }}>+ Free Id Card</Btn>
                    <Btn attrBtn={{ color: 'primary',onClick:idcardToggle }}>Filter</Btn>
                    <EditCommonModal
                    isOpen={idcardModal}
                    toggle={idcardToggle}
                    title="Filter"
                    onSave={handleSave}
                    onCancel={handleCancel}
                    >
                      <IdCardFilter
                        regionalPartner={regionalPartner}
                        setRegionalPartner={setRegionalPartner}
                        paymentStatus={paymentStatus}
                        setPaymentStatus={setPaymentStatus}
                      />
                    </EditCommonModal>
                    <CSVLink data={csvData} filename="IdCard-data.csv" className="btn btn-primary" onClick={handleExport}>
            Export
          </CSVLink>
                    </div>
                   
                </div>
    <div>
       <DataTable
                data={data}
                columns={idcardtableColumns(handleEditdata,handleDeletedata)}
                striped={true}
                center={true}
                pagination
                selectableRows={false}
                clearSelectedRows={toggleDelet}
            />
    </div>
    </>
   
  )
}

export default Idcard
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import FormCommon from '../common/FormCommon';
import { get, patch, post } from '../config';
import { API_URL } from '../config/api_urls';
import { toast } from 'react-toastify';


const AppVersion = () => {

    const [data,setData] = useState([]);
    const [formDataConfig, setFormDataConfig] = useState({
        initialState: {
          id: null,
          app_version: ""
        },
        fields: [
          {
            name: "app_version",
            label: "App Version",
            type: "text",
            placeholder: "Enter App Version",
            required: true,
          }
        ]
      });


      useEffect(() => {
        fetchAppVersion();
      }, [])
      
      const fetchAppVersion = useCallback(() => {
        get(API_URL.APP_VERSION.GET_APP_VERSION)
          .then((response) => {
            if (response?.data.length > 0)
              setData(response.data);
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }, []);

      

     useEffect(()=>{
if(data && data.length > 0){
  setFormDataConfig(prevValue => ({
    ...prevValue,
    initialState: data[0]
  }));
}
},[data])



      const handleSave = async (formData) => {
        
        if (formData?.id) {
          patch(API_URL.APP_VERSION.PATCH_APP_VERSION(formData?.id), formData)
            .then((response) => {
              toast.success("Updated successfully");
              fetchAppVersion();
            })
            .catch((error) => {
              console.error("Error updating data:", error);
              toast.error("Oops! something went wrong");
            });
        }else{
          try {
            await post(API_URL.APP_VERSION.POST_APP_VERSION, formData);
            toast.success("AppVersion Submited successfully");
            fetchAppVersion();
          } catch (error) {
            console.error("Error saving data:", error);
            toast.error("Oops! something went wrong");
          }
        }
        
      };





    return (
    <div>
        <FormCommon
        isModal={false}
        title={`App Version`}
        formDataConfig={formDataConfig}
        onSave={handleSave}
        // editMode={!!formDataConfig?.initialState?.id}
      />
    </div>
    );
};

export default AppVersion;
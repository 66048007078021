import React, { Fragment} from 'react';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';

const EditCommonModal = ({ isOpen, formDataConfig = {}, toggle, title, children, onSave, onCancel }) => {
    
    return (
        <Fragment>
            <Modal isOpen={isOpen} toggle={toggle}>
                <ModalHeader>{title}</ModalHeader>
                <ModalBody>
                    {children}
                    <div className="d-flex justify-content-end">
                        <Button color="secondary" onClick={onCancel}>Cancel</Button>
                        <Button color="primary" className="ms-1" onClick={onSave}>Save</Button>
                    </div>
                </ModalBody>
            </Modal>
        </Fragment>
    );
};

export default EditCommonModal;

import React, { useCallback, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { jobopeningstabledata, jobopeningstableColumns } from '../common/mockData';
import { Btn, H4 } from '../../../../AbstractElements';
import { useNavigate } from 'react-router';
import { Input } from 'reactstrap';
import { Search } from 'react-feather';
import { API_URL } from '../config/api_urls';
import { DELETE, get } from '../config';
import { toast } from 'react-toastify';

function EventOpenings() { 

  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [toggleDelet, setToggleDelet] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [editJobOpening,setEditJobOpening] = useState();
  const [eventCategory, setEventCategory] = useState();
  const [eventTypes,setEventTypes] = useState();
  const [states,setStates] = useState();
  const [countries,setCountries] = useState();
  const [cities,setCities] = useState();


  useEffect(() => {
    fetchEvents();
    fetchEventCategory();
    fetchEventTypes();
    fetchStates();
    fetchCountries();
    fetchCities();
  }, [])
  
  const fetchEvents = useCallback(() => {
    get(API_URL.EVENTS.GET_EVENTS)
      .then((response) => {
        if (response?.data.length > 0)
          console.log(response.data);
          
          setData(response.data)
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);


  const fetchEventCategory = useCallback(() => {
    get(API_URL.EVENT_CATEGORY.GET_EVENT_CATEGORY)
      .then((response) => {
        if (response?.data.length > 0)
          setEventCategory(response.data)
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const fetchEventTypes = useCallback(() => {
    get(API_URL.EVENT_TYPES.GET_EVENT_TYPES)
      .then((response) => {
        if (response?.data.length > 0)
          setEventTypes(response.data)
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const fetchCities = useCallback(() => {
    get(API_URL.CITIES.GET_CITIES)
      .then((response) => {
        // console.log("response ", response);
        if (response?.data.length > 0)
          setCities(response.data)
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const fetchStates = useCallback(() => {
    get(API_URL.STATES.STATES_GET)
      .then((response) => {
        if (response?.data.length > 0)
          setStates(response.data)
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);


  const fetchCountries = useCallback(() => {
    get(API_URL.COUNTRIES.COUNTRIES_GET)
      .then((response) => {
        if (response?.data.length > 0)
          setCountries(response.data)
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);



  const handleNavigate = () => {
    navigate(`${process.env.PUBLIC_URL}/events/openings/new/:layout`);
  };

  const handleEditdata =(row)=>{
    setEditJobOpening(row)
    }


  useEffect(() => {
      if (editJobOpening) {
        navigate(`${process.env.PUBLIC_URL}/events/openings/new/:layout`, { state: { jobOpeningsData: editJobOpening } });
      }
    }, [editJobOpening, navigate]);  


  const handleInput = event => {
    setSearchValue(event.target.value);
  };

  const handleSearch = () => {
    const filteredData = searchValue
      ? data.filter(item => item.title.toLowerCase().includes(searchValue.toLowerCase()))
      : data;
    setData(filteredData);
  };

  const handleRefresh = () => {
    setSearchValue(''); 
    fetchEvents();
  };


  const handleDeletedata = (row)=>{
    if(row?.uuid){
     DELETE(API_URL.EVENTS.DELETE_EVENT(row?.uuid))
     .then((response) => {
       toast.success("Deleted successfully");
       fetchEvents();
     })
     .catch((error) => {
       toast.error("Oops! something went wrong");
     });
    }
   }




  return (
    <>
      <div className={`d-flex align-items-center justify-content-between p-4`}>
        <H4 attrH4={{ className: 'text-muted m-0' }}>Event Openings</H4>
        <div className='d-flex'>
          <Btn attrBtn={{ color: 'primary', onClick: handleNavigate }}>+ Add Event Openings</Btn>
          <div className="faq-form ms-2 d-flex align-items-center">
            <Input className="form-control" type="text" placeholder="Search Event" value={searchValue} onChange={handleInput} />
            <div className="search-icon-container ms-2">
            <Search className="search-icon" onClick={handleSearch} /> 
            </div>
          </div>
          <button type='button' className='btn' style={{width:'30px',height:'38px',padding:'0', borderRadius:'10px',display:'flex',justifyContent:'center',alignItems:'center'}} onClick={handleRefresh}><i className="fa fa-refresh " style={{fontSize:'18px'}}></i></button>
        </div>
      </div>
      <div>
        <DataTable
          data={data}
          columns={jobopeningstableColumns(handleEditdata,handleDeletedata,eventCategory,eventTypes,states,countries,cities)}
          striped={true}
          center={true}
          pagination
          selectableRows={false}
          clearSelectedRows={toggleDelet}
        />
      </div>
    </>
  );
}

export default EventOpenings;

import React, { useCallback, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { attributesdummydata, attributestableColumns } from '../common/mockData';
import { useNavigate } from 'react-router';
import { Btn, H4 } from '../../../../AbstractElements';
import { DELETE, get } from '../config';
import { API_URL } from '../config/api_urls';
import { toast } from 'react-toastify';

function Attributes() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleDelet, setToggleDelet] = useState(false);
  const [editAttribute, setEditAttribute] = useState();



  useEffect(() => {
    if (editAttribute) {
      navigate(`${process.env.PUBLIC_URL}/attributes/new/:layout`, { state: { attributeData: editAttribute } });
    }
  }, [editAttribute, navigate]);

  useEffect(() => {
    fetchAttributes();
  }, [])


  const handleRowSelected = useCallback(state => {
    setSelectedRows(state.selectedRows);
  }, []);


  const fetchAttributes = useCallback(() => {
    get(API_URL.ATTRIBUTES.GET_ATTRIBUTES)
      .then((response) => {
        console.log(response);

        if (response?.data.length >= 0) {
          const sortedResponse = response?.data?.sort((a, b) => {
            return a.order_number - b.order_number;
          })
          setData(sortedResponse);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);



  const handleEditdata = (row) => {
    setEditAttribute(row)
  }

  const handleNavigate = () => {
    navigate(`${process.env.PUBLIC_URL}/attributes/new/:layout`)
  }

  const handleDeletedata = (row) => {
    if (row?.id) {
      DELETE(API_URL.ATTRIBUTES.DELETE_ATTRIBUTES(row?.id))
        .then((response) => {
          toast.success("Deleted successfully");
          fetchAttributes();
        })
        .catch((error) => {
          toast.error("Oops! something went wrong");
        });
    }
  }

  return (
    <>
      <div className={`d-flex align-items-center justify-content-between  p-4`}>
        <H4 attrH4={{ className: 'text-muted m-0' }}>Attributes & Measurements</H4>
        <Btn attrBtn={{ color: 'primary', onClick: () => handleNavigate() }}>+ Add New Attribute</Btn>
      </div>
      <div>
        <DataTable
          data={data}
          columns={attributestableColumns(handleEditdata, handleDeletedata)}
          striped={true}
          center={true}
          pagination
          selectableRows={false}
          onSelectedRowsChange={handleRowSelected}
          clearSelectedRows={toggleDelet}
        />
      </div>
    </>

  )
}

export default Attributes
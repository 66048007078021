import React, { useCallback, useEffect, useState } from 'react'
import EditCommonModal from '../common/EditCommonModal';
import { get } from '../config';
import { API_URL } from '../config/api_urls';
import { FormGroup, Label } from 'reactstrap';
import Select from 'react-select';

function NewUserPreference({editUserPreferenceData,isOpen,toggle,title,onSave,onCancel}) {

    const [users,setUsers] = useState([]);
 console.log("users ", users);
    const [preferences,setPreferences] = useState([]);
 console.log("preferences ", preferences);
    const [isEditing,setIsEditing] = useState(false);
    const [formDataConfig, setFormDataConfig] = useState({
            initialState: {
              id: null,
              user_name: "",
              preference_name: ""
            },
            fields: [
              {
                name: "user_name",
                label: "User",
                type: "select",
                options:[],
                required: true,
              },
              {
                name: "preference_name",
                label: "Preference",
                type: "select",
                options:[],
                required: true,
              }
            ],
          });

       

          useEffect(() => {
            if (editUserPreferenceData) {
              setIsEditing(true);
              setFormDataConfig(value => ({
                ...value,
                initialState: {
                  ...editUserPreferenceData,
                  user_name : users?.find(i => i.label === editUserPreferenceData?.user_name) ,
                  preference_name : preferences?.find(i => i.label === editUserPreferenceData?.preference_name)
                }
                
              }));
            } else {
              setIsEditing(false);
              setFormDataConfig(value => ({
                ...value,
                initialState: {
                  id: null,
                  user_name: "",
                  preference_name:""
                },
              }));
            }
          }, [editUserPreferenceData]);
        





          useEffect(()=>{
            fetchUsers();
            fetchPreferences();
          },[])
        
//users dropdown
const fetchUsers = useCallback(async () => {
  try {
    const response = await get(API_URL.USERS.GET_USERS);
    const user_uuids = response?.data?.map((i) => ({
      label: i.name,
      value: i.uuid,
    }));
    user_uuids.unshift({ label: "Select User", value: "" });
    if (user_uuids.length > 0) {
      setUsers(user_uuids);
      setFormDataConfig((prevConfig) => ({
        ...prevConfig,
        fields: prevConfig.fields.map((field) =>
          field.name === "user_name" ? { ...field, options: user_uuids } : field
        ),
      }));
    }

  } catch (error) {
    console.error("Error:", error);
  }
}, []);

//preferences dropdown
const fetchPreferences = useCallback(async () => {
  try {
    const response = await get(API_URL.PREFERENCES.GET_PREFERENCES);
    const preference_uuids = response?.data?.map((i) => ({
      label: i.name,
      value: i.id,
    }));
    preference_uuids.unshift({ label: "Select Preference", value: "" });
    if (preference_uuids.length > 0) {
      setPreferences(preference_uuids);
      setFormDataConfig((prevConfig) => ({
        ...prevConfig,
        fields: prevConfig.fields.map((field) =>
          field.name === "preference_name" ? { ...field, options: preference_uuids } : field
        ),
      }));
    }

  } catch (error) {
    console.error("Error:", error);
  }
}, []);
            const handleSave = () => {
              onSave(formDataConfig.initialState);
            };
                  
            const handleCancel = () => {
              onCancel();   
            };  
            
            const setUser = (e) => {
              setFormDataConfig({
                ...formDataConfig,
                initialState: {
                  ...formDataConfig.initialState,
                  user_name: e
                }
              })
            }    
            
            const setPreference = (e) => {
              setFormDataConfig({
                ...formDataConfig,
                initialState: {
                  ...formDataConfig.initialState,
                  preference_name: e
                }
              })
            }

  return (
    <EditCommonModal
    isOpen={isOpen}
    toggle={toggle}
    title={`${isEditing ? 'Edit' : 'Add'} ${title}`}
    onSave={handleSave}
    onCancel={handleCancel}
    > 
       <div className='mb-4'>
				<FormGroup>
          <Label className="col-form-label">Select User</Label>
				  <Select
					value={formDataConfig?.initialState?.user_name}
					onChange={setUser}
					options={formDataConfig?.fields?.find(i => i.name === "user_name")?.options}
					className="js-example-basic-single col-sm-12"
					/>

          <Label className="col-form-label mt-3">Select Preference</Label>
				  <Select
					value={formDataConfig?.initialState?.preference_name}
					onChange={setPreference}
					options={formDataConfig?.fields?.find(i => i.name === "preference_name")?.options}
					className="js-example-basic-single col-sm-12"
					/>

				</FormGroup>
			</div>

    </EditCommonModal>
  )
}

export default NewUserPreference
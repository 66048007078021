import { Input } from "reactstrap";
import React from 'react';
import { Btn } from "../../../../AbstractElements";

//------------------------------------------- Users list----------------------------------
export const usersdummytabledata =
	[{
		"id": 1,
		"fullname": "Miof mela",
		"image": "http://dummyimage.com/60x60.png/cc0000/ffffff",
		"blocked": false,
		"featured": true,
		"priority": "High",
		"created_date": "5/20/2024",
		"state": null,
		"phone": "637-404-2498",
		"category": "Model",
		"age": 49,
		"gender": "Female"
	}, {
		"id": 2,
		"fullname": "Peterus",
		"image": "http://dummyimage.com/60x60.png/ff4444/ffffff",
		"blocked": true,
		"featured": true,
		"priority": "Medium",
		"created_date": "2/21/2024",
		"state": "Porto",
		"phone": "666-809-5084",
		"category": "Organiser",
		"age": 22,
		"gender": "Male"
	}, {
		"id": 3,
		"fullname": "Jodie",
		"image": "http://dummyimage.com/60x60.png/cc0000/ffffff",
		"blocked": true,
		"featured": false,
		"priority": "High",
		"created_date": "10/21/2023",
		"state": null,
		"phone": "266-493-9292",
		"category": "Designer",
		"age": 55,
		"gender": "Female"
	}, {
		"id": 4,
		"fullname": "Jenilee",
		"image": "http://dummyimage.com/60x60.png/5fa2dd/ffffff",
		"blocked": true,
		"featured": false,
		"priority": "Medium",
		"created_date": "4/23/2024",
		"state": null,
		"phone": "561-453-4697",
		"category": "choreographer",
		"age": 12,
		"gender": "Female"
	}, {
		"id": 5,
		"fullname": "Tom",
		"image": "http://dummyimage.com/60x60.png/cc0000/ffffff",
		"blocked": false,
		"featured": false,
		"priority": "Medium",
		"created_date": "8/5/2023",
		"state": null,
		"phone": "257-108-9707",
		"category": "Fashion photographer",
		"age": 19,
		"gender": "Male"
	}, {
		"id": 6,
		"fullname": "Kent",
		"image": "http://dummyimage.com/60x60.png/dddddd/000000",
		"blocked": true,
		"featured": false,
		"priority": "Low",
		"created_date": "1/14/2024",
		"state": null,
		"phone": "424-491-9199",
		"category": "Make up artist",
		"age": 30,
		"gender": "Male"
	}, {
		"id": 7,
		"fullname": "Blondelle",
		"image": "http://dummyimage.com/60x60.png/dddddd/000000",
		"blocked": true,
		"featured": false,
		"priority": "High",
		"created_date": "8/11/2023",
		"state": "Kuala Lumpur",
		"phone": "584-331-7070",
		"category": "Nail artist",
		"age": 97,
		"gender": "Female"
	}, {
		"id": 8,
		"fullname": "Renault",
		"image": "http://dummyimage.com/60x60.png/ff4444/ffffff",
		"blocked": true,
		"featured": false,
		"priority": "Medium",
		"created_date": "11/3/2023",
		"state": null,
		"phone": "597-729-2463",
		"category": "Casting company",
		"age": 47,
		"gender": "Male"
	}, {
		"id": 9,
		"fullname": "Allard",
		"image": "http://dummyimage.com/60x60.png/5fa2dd/ffffff",
		"blocked": true,
		"featured": false,
		"priority": "High",
		"created_date": "12/25/2023",
		"state": null,
		"phone": "910-716-3044",
		"category": "Production company",
		"age": 22,
		"gender": "Male"
	}, {
		"id": 10,
		"fullname": "Jonis",
		"image": "http://dummyimage.com/60x60.png/ff4444/ffffff",
		"blocked": true,
		"featured": false,
		"priority": "High",
		"created_date": "10/29/2023",
		"state": null,
		"phone": "694-208-6632",
		"category": "Show director",
		"age": 98,
		"gender": "Female"
	}, {
		"id": 11,
		"fullname": "Sibyl",
		"image": "http://dummyimage.com/60x60.png/5fa2dd/ffffff",
		"blocked": true,
		"featured": false,
		"priority": "High",
		"created_date": "12/3/2023",
		"state": "Västra Götaland",
		"phone": "836-577-1302",
		"category": "Visual jockey",
		"age": 56,
		"gender": "Male"
	}, {
		"id": 12,
		"fullname": "Kathe",
		"image": "http://dummyimage.com/60x60.png/cc0000/ffffff",
		"blocked": true,
		"featured": true,
		"priority": "High",
		"created_date": "4/26/2024",
		"state": "Guarda",
		"phone": "208-587-1005",
		"category": "Fashion institute",
		"age": 2,
		"gender": "Female"
	}, {
		"id": 13,
		"fullname": "Marcie",
		"image": "http://dummyimage.com/60x60.png/cc0000/ffffff",
		"blocked": true,
		"featured": true,
		"priority": "Low",
		"created_date": "6/9/2024",
		"state": null,
		"phone": "332-252-1270",
		"category": "Cosmetic companies",
		"age": 37,
		"gender": "Female"
	}, {
		"id": 14,
		"fullname": "Felecia",
		"image": "http://dummyimage.com/60x60.png/cc0000/ffffff",
		"blocked": false,
		"featured": false,
		"priority": "Medium",
		"created_date": "7/31/2023",
		"state": null,
		"phone": "171-397-5809",
		"category": "Backstage manager",
		"age": 97,
		"gender": "Agender"
	}, {
		"id": 15,
		"fullname": "Emmy",
		"image": "http://dummyimage.com/60x60.png/5fa2dd/ffffff",
		"blocked": false,
		"featured": false,
		"priority": "Medium",
		"created_date": "1/8/2024",
		"state": null,
		"phone": "322-138-5572",
		"category": "marketing & sales team",
		"age": 64,
		"gender": "Female"
	}, {
		"id": 16,
		"fullname": "Stefania",
		"image": "http://dummyimage.com/60x60.png/5fa2dd/ffffff",
		"blocked": true,
		"featured": true,
		"priority": "High",
		"created_date": "6/24/2024",
		"state": null,
		"phone": "694-873-9866",
		"category": "Fashion show social media influencer",
		"age": 50,
		"gender": "Female"
	}, {
		"id": 17,
		"fullname": "Jarad",
		"image": "http://dummyimage.com/60x60.png/dddddd/000000",
		"blocked": true,
		"featured": true,
		"priority": "Low",
		"created_date": "11/24/2023",
		"state": null,
		"phone": "200-619-3780",
		"category": "Celebrity management",
		"age": 42,
		"gender": "Male"
	}, {
		"id": 18,
		"fullname": "Neall",
		"image": "http://dummyimage.com/60x60.png/dddddd/000000",
		"blocked": true,
		"featured": true,
		"priority": "Medium",
		"created_date": "8/23/2023",
		"state": "Vila Real",
		"phone": "346-850-3127",
		"category": "IMODA Supporter",
		"age": 80,
		"gender": "Male"
	}, {
		"id": 19,
		"fullname": "Jennine",
		"image": "http://dummyimage.com/60x60.png/5fa2dd/ffffff",
		"blocked": true,
		"featured": false,
		"priority": "High",
		"created_date": "1/31/2024",
		"state": null,
		"phone": "556-258-1597",
		"category": "MC/anchor",
		"age": 44,
		"gender": "Non-binary"
	}, {
		"id": 20,
		"fullname": "Rickie",
		"image": "http://dummyimage.com/60x60.png/cc0000/ffffff",
		"blocked": false,
		"featured": false,
		"priority": "High",
		"created_date": "9/25/2023",
		"state": "Västra Götaland",
		"phone": "564-852-8407",
		"category": "fashion club",
		"age": 53,
		"gender": "Polygender"
	}, {
		"id": 21,
		"fullname": "Rickie",
		"image": "http://dummyimage.com/60x60.png/cc0000/ffffff",
		"blocked": false,
		"featured": false,
		"priority": "High",
		"created_date": "9/25/2023",
		"state": "Västra Götaland",
		"phone": "564-852-8407",
		"category": "Venue partner",
		"age": 53,
		"gender": "Polygender"
	}, {
		"id": 22,
		"fullname": "Rickie",
		"image": "http://dummyimage.com/60x60.png/cc0000/ffffff",
		"blocked": false,
		"featured": false,
		"priority": "High",
		"created_date": "9/25/2023",
		"state": "Västra Götaland",
		"phone": "564-852-8407",
		"category": "Fashion magazine",
		"age": 53,
		"gender": "Polygender"
	}, {
		"id": 23,
		"fullname": "Rickie",
		"image": "http://dummyimage.com/60x60.png/cc0000/ffffff",
		"blocked": false,
		"featured": false,
		"priority": "High",
		"created_date": "9/25/2023",
		"state": "Västra Götaland",
		"phone": "564-852-8407",
		"category": "Media partner",
		"age": 53,
		"gender": "Polygender"
	}, {
		"id": 24,
		"fullname": "Rickie",
		"image": "http://dummyimage.com/60x60.png/cc0000/ffffff",
		"blocked": false,
		"featured": false,
		"priority": "High",
		"created_date": "9/25/2023",
		"state": "Västra Götaland",
		"phone": "564-852-8407",
		"category": "Fashion Brand",
		"age": 53,
		"gender": "Polygender"
	}]

export const userstableColumns = (handleEditdata, handleDeletedata,userRoles,userCategory,countries,states,hearAboutus) => [
	{
		name: 'ID',
		selector: row => `${row.id}`,
		sortable: true,
		center: false,
		minWidth: '100px',
	},
	{
		name: 'NAME',
		selector: row => `${row.name}`,
		sortable: true,
		center: false,
		minWidth: '200px',
	},
	{
		name: 'COVER IMAGE',
		selector: row => (<div style={{ width: '100px', height: '100px', overflow: 'hidden', display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
			<img
				src={row.cover_image}
				alt=""
				style={{
					width: '100%',
					height: '100%',
					objectFit: 'cover',
					border: '1px solid #ddd',
					borderRadius: '4px',
					boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
					padding: '5px',
					margin: '5px',
					transition: 'transform 0.2s',
				}}
				onMouseOver={(e) => e.currentTarget.style.transform = 'scale(1.2)'}
				onMouseOut={(e) => e.currentTarget.style.transform = 'scale(1.0)'}
			/>
		</div>
		),
		center: true,
		minWidth: '150px'
	},
	{
		name: 'PROFILE IMAGE',
		selector: row => (<div style={{ width: '100px', height: '100px', overflow: 'hidden', display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
			<img
				src={row.profile_image}
				alt=""
				style={{
					width: '100%',
					height: '100%',
					objectFit: 'cover',
					border: '1px solid #ddd',
					borderRadius: '4px',
					boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
					padding: '5px',
					margin: '5px',
					transition: 'transform 0.2s',
				}}
				onMouseOver={(e) => e.currentTarget.style.transform = 'scale(1.2)'}
				onMouseOut={(e) => e.currentTarget.style.transform = 'scale(1.0)'}
			/>
		</div>

		),
		center: true,
		minWidth: '150px'
	},
	{
		name: 'AGE',
		selector: row => row.age,
		sortable: true,
		center: false,
	},
	{
		name: 'GENDER',
		selector: row => `${row.gender}`,
		center: false,
		minWidth: '140px'
	},
	{
		name: 'DATE OF BIRTH',
		selector: row => `${row.date_of_birth}`,
		sortable: true,
		center: false,
		minWidth: '150px'
	},
	{
		name: 'EMAIL',
		selector: row => `${row.email}`,
		sortable: true,
		center: false,
		minWidth: '200px',
	},
	{
		name: 'BLOCKED',
		selector: row => <div className="form-check form-switch">
			<Btn attrBtn={{ color: 'none' }}><input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked={row?.is_blocked} /> </Btn>
		</div>,
		sortable: true,
		center: true,
	},
	{
		name: 'FEATURED',
		selector: row => <div className="form-check form-switch">
			<input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" onChange={console.log} checked={row?.is_featured} />
		</div>,
		center: false,
		minWidth: '110px'
	},
	{
		name: 'HEAR ABOUTUS',
		selector: row => hearAboutus?.find(i=>i.id === row?.hearaboutus_id)?.aboutus,
		sortable: true,
		center: false,
		minWidth: '150px'
	},
	{
		name: 'USER ROLE',
		selector: row =>userRoles?.find((i)=> i.id === row?.user_role_id)?.role,
		sortable: true,
		center: false,
		minWidth: '200px'
	},
	{
		name: 'CATEGORY',
		selector: row => userCategory?.find((i)=>i.id === row?.category_id)?.name ,
		sortable: true,
		center: false,
		minWidth: '130px'
	},
	{
		name: 'STATE',
		selector: row => states?.find(i=>i.id === row?.state_id)?.name,
		center: false,
		minWidth: '150px'
	},
	{
		name: 'COUNTRY',
		selector: row => countries?.find(i=>i.id === row?.country_id)?.name,
		sortable: true,
		center: false,
		minWidth: '150px'
	},
	{
		name: 'WHATSAPP NUMBER',
		selector: row => row["whatsapp_number"],
		sortable: true,
		center: false,
		minWidth: '170px'
	},
	{
		name: 'CREATED DATE',
		selector: row => `${row.created_at}`,
		sortable: true,
		center: false,
		minWidth: '180px'
	},

	{
		name: 'ACTION',
		selector: row => <div className="d-flex " >
			<Btn attrBtn={{ color: 'none', onClick: () => handleEditdata(row) }}><i className="fa fa-edit edit-btn" style={{ fontSize: '18px' }}></i></Btn>
			<Btn attrBtn={{ color: 'none', onClick: () => handleDeletedata(row) }}><i className="icofont icofont-ui-delete" style={{ fontSize: '18px' }}></i></Btn>
		</div>,
		sortable: false,
		center: true,
		minWidth: '200px'
	}
];

//------------------------------- User Role------------------------------

export const userroledummytabledata = [{
	"id": 1,
	"image": "http://dummyimage.com/100x100.png/cc0000/ffffff",
	"role": "Help Desk Operator",
	"created_date": "5/26/2024"
}, {
	"id": 2,
	"image": "http://dummyimage.com/100x100.png/cc0000/ffffff",
	"role": "Pharmacist",
	"created_date": "8/24/2023"
}, {
	"id": 3,
	"image": "http://dummyimage.com/100x100.png/cc0000/ffffff",
	"role": "VP Accounting",
	"created_date": "1/16/2024"
}, {
	"id": 4,
	"image": "http://dummyimage.com/100x100.png/dddddd/000000",
	"role": "Marketing Manager",
	"created_date": "2/19/2024"
}, {
	"id": 5,
	"image": "http://dummyimage.com/100x100.png/dddddd/000000",
	"role": "Computer Systems Analyst IV",
	"created_date": "2/12/2024"
}, {
	"id": 6,
	"image": "http://dummyimage.com/100x100.png/ff4444/ffffff",
	"role": "GIS Technical Architect",
	"created_date": "2/28/2024"
}, {
	"id": 7,
	"image": "http://dummyimage.com/100x100.png/cc0000/ffffff",
	"role": "Software Test Engineer IV",
	"created_date": "1/4/2024"
}, {
	"id": 8,
	"image": "http://dummyimage.com/100x100.png/5fa2dd/ffffff",
	"role": "Marketing Manager",
	"created_date": "6/12/2024"
}, {
	"id": 9,
	"image": "http://dummyimage.com/100x100.png/5fa2dd/ffffff",
	"role": "Health Coach III",
	"created_date": "11/7/2023"
}, {
	"id": 10,
	"image": "http://dummyimage.com/100x100.png/dddddd/000000",
	"role": "Database Administrator IV",
	"created_date": "1/14/2024"
}, {
	"id": 11,
	"image": "http://dummyimage.com/100x100.png/cc0000/ffffff",
	"role": "Research Associate",
	"created_date": "3/9/2024"
}, {
	"id": 12,
	"image": "http://dummyimage.com/100x100.png/5fa2dd/ffffff",
	"role": "Graphic Designer",
	"created_date": "1/12/2024"
}, {
	"id": 13,
	"image": "http://dummyimage.com/100x100.png/5fa2dd/ffffff",
	"role": "Structural Engineer",
	"created_date": "10/5/2023"
}, {
	"id": 14,
	"image": "http://dummyimage.com/100x100.png/cc0000/ffffff",
	"role": "Community Outreach Specialist",
	"created_date": "7/31/2023"
}, {
	"id": 15,
	"image": "http://dummyimage.com/100x100.png/cc0000/ffffff",
	"role": "Financial Advisor",
	"created_date": "5/3/2024"
}, {
	"id": 16,
	"image": "http://dummyimage.com/100x100.png/5fa2dd/ffffff",
	"role": "Speech Pathologist",
	"created_date": "12/6/2023"
}, {
	"id": 17,
	"image": "http://dummyimage.com/100x100.png/cc0000/ffffff",
	"role": "Senior Financial Analyst",
	"created_date": "7/26/2023"
}, {
	"id": 18,
	"image": "http://dummyimage.com/100x100.png/dddddd/000000",
	"role": "Senior Editor",
	"created_date": "8/17/2023"
}, {
	"id": 19,
	"image": "http://dummyimage.com/100x100.png/cc0000/ffffff",
	"role": "Payment Adjustment Coordinator",
	"created_date": "8/2/2023"
}, {
	"id": 20,
	"image": "http://dummyimage.com/100x100.png/cc0000/ffffff",
	"role": "Structural Analysis Engineer",
	"created_date": "7/12/2024"
}]

export const userroletableColumns = (editmodalToggle, handleDeletedata) => [
	{
		name: 'ID',
		selector: row => row['id'],
		sortable: true,
		center: false,
		minWidth: '50px'
	},
	{
		name: 'ROLE',
		selector: row => `${row.role}`,
		sortable: true,
		center: false,
	},
	{
		name: 'CREATED DATE',
		selector: row => `${row.created_at}`,
		sortable: true,
		center: false,
	},
	{
		name: 'ACTION',
		selector: row => <div className="d-flex" >
			<Btn attrBtn={{ color: "none", onClick: () => editmodalToggle(row) }}><i className="fa fa-edit edit-btn" style={{ fontSize: '18px' }}></i></Btn>
			<Btn attrBtn={{ color: "none", onClick: () => handleDeletedata(row) }}><i className="icofont icofont-ui-delete" style={{ fontSize: '18px' }}></i></Btn>
		</div>,
		sortable: true,
		center: true,
	}
];


//-------------------------------- User Category----------------------------------
export const usercategorytabledata = [{
	"id": 1,
	"category": "Kids"
}, {
	"id": 2,
	"category": "Mr"
}, {
	"id": 3,
	"category": "Mrs"
}, {
	"id": 4,
	"category": "Ms"
}, {
	"id": 5,
	"category": "Transgender"
}]

export const usercategorytableColumns = (editmodalToggle, handleDeletedata) => [
	{
		name: 'ID',
		selector: row => row['id'],
		sortable: true,
		center: false,
	},
	{
		name: 'Category',
		selector: row => `${row.name}`,
		center: false,
	},
	{
		name: 'ACTION',
		selector: row => <div className="d-flex " >
			<Btn attrBtn={{ color: 'none', onClick: () => editmodalToggle(row) }}><i className="fa fa-edit edit-btn" style={{ fontSize: '18px' }}></i></Btn>
			<Btn attrBtn={{ color: 'none', onClick: () => handleDeletedata(row) }}><i className="icofont icofont-ui-delete" style={{ fontSize: '18px' }}></i></Btn>
		</div>,
		sortable: true,
		center: true,
		minWidth: '150px',
		maxWidth: '200px',
	}
];


// -----------------------------Hear AboutUs---------------------------------

export const hearabouttabledata = [{
	"id": 1,
	"about_us": "Charles Williams"
}, {
	"id": 2,
	"about_us": "Soumya Singh"
}
]

export const hearabouttableColumns = (editmodalToggle, handleDeletedata) => [
	{
		name: 'ID',
		selector: row => row['id'],
		sortable: true,
		center: false,
	},
	{
		name: 'ABOUT US',
		selector: row => `${row.aboutus}`,
		sortable: true,
		center: false,
	},
	{
		name: 'ACTION',
		selector: row => <div className="d-flex " >
			<Btn attrBtn={{ color: 'none', onClick: () => editmodalToggle(row) }}><i className="fa fa-edit edit-btn" style={{ fontSize: '18px' }}></i></Btn>
			<Btn attrBtn={{ color: 'none', onClick: () => handleDeletedata(row) }}><i className="icofont icofont-ui-delete" style={{ fontSize: '18px' }}></i></Btn>
		</div>,
		sortable: true,
		center: true,
		minWidth: '150px',
		maxWidth: '200px',
	}
];


// -----------------------------Regional Partners---------------------------------

export const regionalpartnertabledata = [{
	"id": 1,
	"regional_partners": "Simeon",
	"total_enrolment": "27",
	"paid_users": 1,
	"unpaid_users": "29389"
}, {
	"id": 2,
	"regional_partners": "Kata",
	"total_enrolment": "4",
	"paid_users": 2,
	"unpaid_users": "033"
}, {
	"id": 3,
	"regional_partners": "Job",
	"total_enrolment": "6",
	"paid_users": 3,
	"unpaid_users": "35"
}, {
	"id": 4,
	"regional_partners": "Justen",
	"total_enrolment": "20",
	"paid_users": 4,
	"unpaid_users": "6"
}, {
	"id": 5,
	"regional_partners": "Adoree",
	"total_enrolment": "41",
	"paid_users": 5,
	"unpaid_users": "1"
}, {
	"id": 6,
	"regional_partners": "Waldo",
	"total_enrolment": "17",
	"paid_users": 6,
	"unpaid_users": "90146"
}, {
	"id": 7,
	"regional_partners": "Bobbie",
	"total_enrolment": "0093",
	"paid_users": 7,
	"unpaid_users": "2"
}, {
	"id": 8,
	"regional_partners": "Heindrick",
	"total_enrolment": "479",
	"paid_users": 8,
	"unpaid_users": "68"
}, {
	"id": 9,
	"regional_partners": "Quinlan",
	"total_enrolment": "02",
	"paid_users": 9,
	"unpaid_users": "7"
}, {
	"id": 10,
	"regional_partners": "Emlen",
	"total_enrolment": "7602",
	"paid_users": 10,
	"unpaid_users": "9"
}, {
	"id": 11,
	"regional_partners": "Felita",
	"total_enrolment": "6938",
	"paid_users": 11,
	"unpaid_users": "199"
}, {
	"id": 12,
	"regional_partners": "Rhianon",
	"total_enrolment": "15",
	"paid_users": 12,
	"unpaid_users": "20845"
}, {
	"id": 13,
	"regional_partners": "Hartley",
	"total_enrolment": "05",
	"paid_users": 13,
	"unpaid_users": "0"
}, {
	"id": 14,
	"regional_partners": "Arlana",
	"total_enrolment": "9",
	"paid_users": 14,
	"unpaid_users": "84101"
}, {
	"id": 15,
	"regional_partners": "Faun",
	"total_enrolment": "09588",
	"paid_users": 15,
	"unpaid_users": "25580"
}, {
	"id": 16,
	"regional_partners": "Yvon",
	"total_enrolment": "92",
	"paid_users": 16,
	"unpaid_users": "9"
}, {
	"id": 17,
	"regional_partners": "Curran",
	"total_enrolment": "125",
	"paid_users": 17,
	"unpaid_users": "03549"
}, {
	"id": 18,
	"regional_partners": "Ring",
	"total_enrolment": "39",
	"paid_users": 18,
	"unpaid_users": "66288"
}, {
	"id": 19,
	"regional_partners": "Cathe",
	"total_enrolment": "3",
	"paid_users": 19,
	"unpaid_users": "75"
}, {
	"id": 20,
	"regional_partners": "Edgar",
	"total_enrolment": "5405",
	"paid_users": 20,
	"unpaid_users": "44"
}, {
	"id": 21,
	"regional_partners": "Wilhelm",
	"total_enrolment": "72515",
	"paid_users": 21,
	"unpaid_users": "90591"
}, {
	"id": 22,
	"regional_partners": "Ward",
	"total_enrolment": "92122",
	"paid_users": 22,
	"unpaid_users": "9308"
}, {
	"id": 23,
	"regional_partners": "Krisha",
	"total_enrolment": "3",
	"paid_users": 23,
	"unpaid_users": "5"
}, {
	"id": 24,
	"regional_partners": "Matelda",
	"total_enrolment": "14451",
	"paid_users": 24,
	"unpaid_users": "1"
}]

export const regionalpartnertableColumns = (editmodalToggle,handleDeletedata) => [
	{
		name: 'ID',
		selector: row => row['id'],
		sortable: true,
		center: false,
	},
	{
		name: 'REGIONAL PARTNERS',
		selector: row => `${row.name}`,
		sortable: true,
		center: false,
	},
	{
		name: 'TOTAL ENROLMENT',
		selector: row => `${row.enrollment_count}`,
		sortable: true,
		center: false,
	},
	// {
	// 	name: 'PAID USERS',
	// 	selector: row => `${row.paid_users}`,
	// 	sortable: true,
	// 	center: false,
	// },
	// {
	// 	name: 'UNPAID USERS',
	// 	selector: row => `${row.unpaid_users}`,
	// 	sortable: true,
	// 	center: false,
	// },
	{
		name: 'ACTION',
		selector: row => <div className="d-flex " >
			<Btn attrBtn={{ color: 'none', onClick: () => editmodalToggle(row) }}><i className="fa fa-edit edit-btn" style={{ fontSize: '18px' }}></i></Btn>
			<Btn attrBtn={{ color: 'none', onClick: () => handleDeletedata(row) }}><i className="icofont icofont-ui-delete" style={{ fontSize: '18px' }}></i></Btn>
		</div>,
		sortable: true,
		center: true,
		minWidth: '150px',
		maxWidth: '200px',
	}
];


// ----------------------------Event/shows category----------------------------------

export const eventcategorytabledata = [{
	"id": 1,
	"category": "model"
},
{
	"id": 2,
	"category": "model"
},
{
	"id": 3,
	"category": "model"
},
{
	"id": 4,
	"category": "model"
},
{
	"id": 5,
	"category": "model"
}]

export const eventcategorytableColumns = (editmodalToggle,handleDeletedata) => [
	{
		name: 'ID',
		selector: row => row['id'],
		sortable: true,
		center: false,
	},
	{
		name: 'CATEGORY',
		selector: row => `${row.name}`,
		sortable: true,
		center: false,
	},
	{
		name: 'ACTION',
		selector: row => <div className="d-flex " >
			<Btn attrBtn={{ color: 'none', onClick: () => editmodalToggle(row) }}><i className="fa fa-edit edit-btn" style={{ fontSize: '18px' }}></i></Btn>
			<Btn attrBtn={{ color: 'none', onClick: () => handleDeletedata(row) }}><i className="icofont icofont-ui-delete" style={{ fontSize: '18px' }}></i></Btn>
		</div>,
		sortable: true,
		center: true,
		minWidth: '150px'
	}
];

//---------------------------- EventOpenings--------------------------------------------

export const jobopeningstabledata = [{
	"id": 1,
	"username": "Eddi",
	"title": "Gyppes",
	"image": "http://dummyimage.com/100x100.png/cc0000/ffffff",
	"category": "Physical Therapy Assistant",
	"type": "Female",
	"featured": false,
	"applications": 1,
	"deadline": "11/25/2023"
}, {
	"id": 2,
	"username": "Archibold",
	"title": "Schneidau",
	"image": "http://dummyimage.com/100x100.png/cc0000/ffffff",
	"category": "Marketing Assistant",
	"type": "Male",
	"featured": false,
	"applications": 2,
	"deadline": "11/9/2023"
}, {
	"id": 3,
	"username": "Leoline",
	"title": "Cleyne",
	"image": "http://dummyimage.com/100x100.png/dddddd/000000",
	"category": "Structural Analysis Engineer",
	"type": "Female",
	"featured": true,
	"applications": 3,
	"deadline": "12/21/2023"
}, {
	"id": 4,
	"username": "Tiphany",
	"title": "Kendle",
	"image": "http://dummyimage.com/100x100.png/cc0000/ffffff",
	"category": "Senior Cost Accountant",
	"type": "Female",
	"featured": true,
	"applications": 4,
	"deadline": "9/25/2023"
}, {
	"id": 5,
	"username": "Biddie",
	"title": "Simoneton",
	"image": "http://dummyimage.com/100x100.png/ff4444/ffffff",
	"category": "Speech Pathologist",
	"type": "Female",
	"featured": false,
	"applications": 5,
	"deadline": "3/13/2024"
}, {
	"id": 6,
	"username": "Jeane",
	"title": "Leythley",
	"image": "http://dummyimage.com/100x100.png/cc0000/ffffff",
	"category": "Clinical Specialist",
	"type": "Female",
	"featured": true,
	"applications": 6,
	"deadline": "5/21/2024"
}, {
	"id": 7,
	"username": "Lin",
	"title": "Gay",
	"image": "http://dummyimage.com/100x100.png/5fa2dd/ffffff",
	"category": "Information Systems Manager",
	"type": "Male",
	"featured": true,
	"applications": 7,
	"deadline": "9/21/2023"
}, {
	"id": 8,
	"username": "Asa",
	"title": "Struthers",
	"image": "http://dummyimage.com/100x100.png/5fa2dd/ffffff",
	"category": "Compensation Analyst",
	"type": "Male",
	"featured": false,
	"applications": 8,
	"deadline": "2/6/2024"
}, {
	"id": 9,
	"username": "Brody",
	"title": "Frome",
	"image": "http://dummyimage.com/100x100.png/cc0000/ffffff",
	"category": "Chief Design Engineer",
	"type": "Male",
	"featured": false,
	"applications": 9,
	"deadline": "8/3/2023"
}, {
	"id": 10,
	"username": "Bess",
	"title": "Mabbutt",
	"image": "http://dummyimage.com/100x100.png/dddddd/000000",
	"category": "Geological Engineer",
	"type": "Female",
	"featured": false,
	"applications": 10,
	"deadline": "4/27/2024"
}, {
	"id": 11,
	"username": "Terry",
	"title": "Thunders",
	"image": "http://dummyimage.com/100x100.png/dddddd/000000",
	"category": "Legal Assistant",
	"type": "Non-binary",
	"featured": false,
	"applications": 11,
	"deadline": "10/25/2023"
}, {
	"id": 12,
	"username": "Hadley",
	"title": "Westbury",
	"image": "http://dummyimage.com/100x100.png/5fa2dd/ffffff",
	"category": "Staff Accountant I",
	"type": "Agender",
	"featured": false,
	"applications": 12,
	"deadline": "2/15/2024"
}, {
	"id": 13,
	"username": "Tabina",
	"title": "Kesteven",
	"image": "http://dummyimage.com/100x100.png/5fa2dd/ffffff",
	"category": "Senior Cost Accountant",
	"type": "Female",
	"featured": true,
	"applications": 13,
	"deadline": "10/5/2023"
}, {
	"id": 14,
	"username": "Marven",
	"title": "Iglesia",
	"image": "http://dummyimage.com/100x100.png/5fa2dd/ffffff",
	"category": "Senior Cost Accountant",
	"type": "Male",
	"featured": true,
	"applications": 14,
	"deadline": "12/26/2023"
}, {
	"id": 15,
	"username": "Annnora",
	"title": "Killner",
	"image": "http://dummyimage.com/100x100.png/5fa2dd/ffffff",
	"category": "Structural Engineer",
	"type": "Female",
	"featured": true,
	"applications": 15,
	"deadline": "12/9/2023"
}, {
	"id": 16,
	"username": "Timoteo",
	"title": "Wratten",
	"image": "http://dummyimage.com/100x100.png/dddddd/000000",
	"category": "Administrative Officer",
	"type": "Male",
	"featured": true,
	"applications": 16,
	"deadline": "3/10/2024"
}, {
	"id": 17,
	"username": "Murielle",
	"title": "Emanson",
	"image": "http://dummyimage.com/100x100.png/cc0000/ffffff",
	"category": "Assistant Professor",
	"type": "Female",
	"featured": false,
	"applications": 17,
	"deadline": "12/24/2023"
}, {
	"id": 18,
	"username": "Chiquita",
	"title": "Soane",
	"image": "http://dummyimage.com/100x100.png/ff4444/ffffff",
	"category": "Nurse Practicioner",
	"type": "Female",
	"featured": true,
	"applications": 18,
	"deadline": "5/10/2024"
}, {
	"id": 19,
	"username": "Link",
	"title": "Smithers",
	"image": "http://dummyimage.com/100x100.png/5fa2dd/ffffff",
	"category": "Staff Accountant III",
	"type": "Male",
	"featured": true,
	"applications": 19,
	"deadline": "1/23/2024"
}, {
	"id": 20,
	"username": "Cherida",
	"title": "Ambrozewicz",
	"image": "http://dummyimage.com/100x100.png/ff4444/ffffff",
	"category": "Environmental Tech",
	"type": "Agender",
	"featured": true,
	"applications": 20,
	"deadline": "8/9/2023"
}]


export const jobopeningstableColumns = (handleEditdata,handleDeletedata,eventCategory,eventTypes,states,countries,cities) => [
	
	{
		name: 'TITLE',
		selector: row => `${row.title}`,
		sortable: true,
		center: false,
		minWidth: '200px'
	},
	{
		name: 'COVER IMAGE',
		selector: row => (<div style={{ width: '100px', height: '100px', overflow: 'hidden', display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
			<img
				src={row.image}
				alt=""
				style={{
					width: '100%',
					height: '100%',
					objectFit: 'cover',
					border: '1px solid #ddd',
					borderRadius: '4px',
					boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
					padding: '5px',
					margin: '5px',
					transition: 'transform 0.2s',
				}}
				onMouseOver={(e) => e.currentTarget.style.transform = 'scale(1.2)'}
				onMouseOut={(e) => e.currentTarget.style.transform = 'scale(1.0)'}
			/>
		</div>
		),
		center: true,
		minWidth: '200px'
	},
	{
		name: 'EVENT TYPE',
		selector: row => eventTypes?.find(i=>i.id === row?.event_types)?.name,
		sortable: true,
		center: false,
		minWidth: '130px'
	},
	{
		name: 'USER NAME',
		selector: row => `${row.name}`,
		sortable: true,
		center: false,
		minWidth: '200px'
	},
	{
		name: 'CATEGORY',
		selector: row => eventCategory?.find(i=>i.id === row?.category)?.name,
		sortable: true,
		center: false,
		minWidth: '200px'
	},
	{
		name: 'REGISTRATION FEE',
		selector: row => `₹${row.registration_fee}`,
		center: false,
		minWidth: '150px'
	},
	// {
	// 	name: 'DESCRIPTION',
	// 	selector: row => `${row.description}`,
	// 	center: false,
	// 	minWidth: '200px'
	// },
	{
		name: 'APPLICATION COUNT',
		selector: row => `${row.applications_count}`,
		center: false,
		minWidth: '180px'
	},
	{
		name: 'ADDRESSLINE 1',
		selector: row => `${row.address_line_1}`,
		center: false,
		minWidth: '150px'
	},
	{
		name: 'ADDRESSLINE 2',
		selector: row => `${row.address_line_2}`,
		center: false,
		minWidth: '150px'
	},
	{
		name: 'CITY',
		selector: row => cities?.find(i=>i.id === row?.city)?.name,
		center: false,
		minWidth: '100px'
	},
	{
		name: 'STATE',
		selector: row => states?.find(i=>i.id === row?.state)?.name,
		center: false,
		minWidth: '100px'
	},
	{
		name: 'COUNTRY',
		selector: row => countries?.find(i=>i.id === row?.country)?.name,
		center: false,
		minWidth: '100px'
	},
	{
		name: 'PINCODE',
		selector: row => `${row.pincode}`,
		sortable: true,
		center: false,
		minWidth: '140px'
	},
	
	
	// {
	// 	name: 'FEATURED',
	// 	selector: row => <div className="form-check form-switch">
	// 		<input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" onChange={console.log} checked={row?.featured} />
	// 	</div>,
	// 	center: false,
	// 	minWidth: '130px'
	// },
	
	{
		name: 'DEADLINE',
		selector: row =>{
			const deadline = new Date(row.deadline);
			console.log(deadline);
			
			const today = new Date();
			// const isSameDay = deadline.getFullYear() <= today.getFullYear() &&
			// 				  deadline.getMonth() <= today.getMonth() &&
			// 				  deadline.getDate() <= today.getDate();
	
			
			const isValidDate = !isNaN(deadline.getTime());
			if (isValidDate && today > deadline) {
				return <span style={{ color: 'red', fontWeight: 'bold' }}>Expired</span>;
			}
			return row.deadline; 
		},
		sortable: true,
		center: false,
		minWidth: '140px'
	},
	{
		name: 'ACTION',
		selector: row => <div className="d-flex " >
			<Btn attrBtn={{ color: 'none', onClick: () => handleEditdata(row) }}><i className="fa fa-edit edit-btn" style={{ fontSize: '18px' }}></i></Btn>
			<Btn attrBtn={{ color: 'none', onClick: () => handleDeletedata(row) }}><i className="icofont icofont-ui-delete" style={{ fontSize: '18px' }}></i></Btn>
		</div>,
		sortable: true,
		center: true,
		minWidth: '150px',
		maxWidth: '200px',
	}
];





// ---------------------------Job Type------------------------------------
export const jobtypetabledata = [{
	"id": 1,
	"type": "designing"
}, {
	"id": 2,
	"type": "kids-girl"
}, {
	"id": 3,
	"type": "kids-boy"
}, {
	"id": 4,
	"type": "male"
}, {
	"id": 5,
	"type": "female"
}, {
	"id": 6,
	"type": "mehendi worshop"
}, {
	"id": 7,
	"type": "nail art workshop"
}, {
	"id": 8,
	"type": "beautician course"
}, {
	"id": 9,
	"type": "costume designing"
}, {
	"id": 10,
	"type": "fashion show"
}, {
	"id": 11,
	"type": "pageant show"
}, {
	"id": 12,
	"type": "designer show"
}]


export const jobtypetableColumns = (editmodalToggle,handleDeletedata) => [
	{
		name: 'ID',
		selector: row => row['id'],
		sortable: true,
		center: false,
	},
	{
		name: 'TYPE',
		selector: row => `${row.name}`,
		sortable: true,
		center: false,
	},
	{
		name: 'ACTION',
		selector: row => <div className="d-flex " >
			<Btn attrBtn={{ color: 'none', onClick: () => editmodalToggle(row) }}><i className="fa fa-edit edit-btn" style={{ fontSize: '18px' }}></i></Btn>
			<Btn attrBtn={{ color: 'none', onClick: () => handleDeletedata(row) }}><i className="icofont icofont-ui-delete" style={{ fontSize: '18px' }}></i></Btn>
		</div>,
		center: true,
		minWidth: '200px',
	}
];






//--------------------------- Applications------------------------------------------

export const applicationtabledata = [{
	"id": 1,
	"user": "Idell",
	"role": "Developer II",
	"state": "Jiulong",
	"place": "Budapest",
	"phone_number": "943-290-5839",
	"job_requirements": "MISS & MRS GLOBAL DAZZLING DIVA 2024 SEASON 2",
	"status": "Approved"
}, {
	"id": 2,
	"user": "Hartley",
	"role": "Assistant Media Planner",
	"state": "Curitiba",
	"place": "Takeo",
	"phone_number": "584-254-0215",
	"job_requirements": "MISS & MRS GLOBAL DAZZLING DIVA 2024 SEASON 2",
	"status": "Approved"
}, {
	"id": 3,
	"user": "Shirleen",
	"role": "Database Administrator II",
	"state": "Marseille",
	"place": "Lirang",
	"phone_number": "967-295-9086",
	"job_requirements": "̦Ethnic Runway",
	"status": "Pending"
}, {
	"id": 4,
	"user": "Skippy",
	"role": "Administrative Officer",
	"state": "Taourirt",
	"place": "Burgos",
	"phone_number": "345-116-9236",
	"job_requirements": "Miss/Mrs/Mr/kids international fashion fever",
	"status": "Approved"
}, {
	"id": 5,
	"user": "Anselm",
	"role": "Operator",
	"state": "Bunol",
	"place": "Pilcaniyeu",
	"phone_number": "583-855-4343",
	"job_requirements": "CASTING CALL",
	"status": "Pending"
}, {
	"id": 6,
	"user": "Agatha",
	"role": "VP Sales",
	"state": "Blobo",
	"place": "Fusagasuga",
	"phone_number": "878-563-5031",
	"job_requirements": "Fashion Fever Runway",
	"status": "Approved"
}, {
	"id": 7,
	"user": "Sumner",
	"role": "Chief Design Engineer",
	"state": "Canillo",
	"place": "Zagreb",
	"phone_number": "599-603-8517",
	"job_requirements": "Looking for female models for tv ads",
	"status": "Approved"
}, {
	"id": 8,
	"user": "Florencia",
	"role": "Cost Accountant",
	"state": "Épinal",
	"place": "Argotirto Krajan",
	"phone_number": "219-987-8500",
	"job_requirements": "WOMEN & MEN ASIA INDIA BUSINESS AWARD 2024",
	"status": "Pending"
}, {
	"id": 9,
	"user": "Lynna",
	"role": "Media Manager IV",
	"state": "Plovdiv",
	"place": "Le Port",
	"phone_number": "299-545-0909",
	"job_requirements": "Beard & Moustache king",
	"status": "Approved"
}, {
	"id": 10,
	"user": "Shepherd",
	"role": "Paralegal",
	"state": "Bieto",
	"place": "Thị Trấn Tam Sơn",
	"phone_number": "962-641-9231",
	"job_requirements": "̗̺͖̹̯͓INTERNATIONAL FASHION FEVER SEASON 4 (BEAUTY PAGEANT @ Goa)",
	"status": "Pending"
}, {
	"id": 11,
	"user": "Cybill",
	"role": "Sales Associate",
	"state": "Old City",
	"place": "Tando Jām",
	"phone_number": "258-120-6522",
	"job_requirements": "Teen,Mr,Miss Mrs India's Grand Divyang Fashion & Talent Show2024",
	"status": "Approved"
}, {
	"id": 12,
	"user": "Packston",
	"role": "Budget/Accounting Analyst I",
	"state": "An Naşşārīyah",
	"place": "Michurinskoye",
	"phone_number": "536-721-8436",
	"job_requirements": "BHARAT GAURAV SAMMAN",
	"status": "Approved"
}, {
	"id": 13,
	"user": "Nananne",
	"role": "Director of Sales",
	"state": "Aktau",
	"place": "Brańsk",
	"phone_number": "801-582-3629",
	"job_requirements": "Miss/Mrs/Mr/kids international fashion fever",
	"status": "Approved"
}, {
	"id": 14,
	"user": "Ravid",
	"role": "Human Resources Assistant IV",
	"state": "Capayán",
	"place": "Xiying",
	"phone_number": "475-777-7192",
	"job_requirements": "Looking for kerala models (females)",
	"status": "Approved"
}, {
	"id": 15,
	"user": "Britt",
	"role": "Office Assistant II",
	"state": "Caringin Lor",
	"place": "Siilinjärvi",
	"phone_number": "109-933-3470",
	"job_requirements": "INTERNATIONAL FASHION FEVER SEASON 4 (BEAUTY PAGEANT @ Goa)",
	"status": "Approved"
}, {
	"id": 16,
	"user": "Correy",
	"role": "Administrative Officer",
	"state": "Khon Buri",
	"place": "Lautoka",
	"phone_number": "597-969-6984",
	"job_requirements": "MSME BUSINESS ACHIEVERS AWARD",
	"status": "Pending"
}, {
	"id": 17,
	"user": "Rustie",
	"role": "Graphic Designer",
	"state": "Ierápetra",
	"place": "Sozopol",
	"phone_number": "104-776-8971",
	"job_requirements": "BHARAT GAURAV SAMMAN",
	"status": "Pending"
}, {
	"id": 18,
	"user": "Hamel",
	"role": "Staff Accountant IV",
	"state": "Paritjunus",
	"place": "Abangay",
	"phone_number": "751-409-3632",
	"job_requirements": "SUPER MOM SUPER WOMAN AWARD 2024 SEASON 3",
	"status": "Pending"
}, {
	"id": 19,
	"user": "Joel",
	"role": "Business Systems Development Analyst",
	"state": "Mansilingan",
	"place": "Denver",
	"phone_number": "210-279-3007",
	"job_requirements": "INDIAN EMINENCE AWARD 2024",
	"status": "Pending"
}, {
	"id": 20,
	"user": "Lester",
	"role": "Safety Technician III",
	"state": "Solna",
	"place": "Duwayr Raslān",
	"phone_number": "755-435-8795",
	"job_requirements": "Miss/Mrs/Mr/kids international fashion fever",
	"status": "Approved"
}, {
	"id": 21,
	"user": "Mortimer",
	"role": "Senior Editor",
	"state": "Lospalos",
	"place": "Longqiao",
	"phone_number": "554-512-7379",
	"job_requirements": "INDIA'S PRIDE AWARDS 2024",
	"status": "Approved"
}, {
	"id": 22,
	"user": "Gayel",
	"role": "Executive Secretary",
	"state": "Aihua",
	"place": "Bulianhe",
	"phone_number": "685-373-3279",
	"job_requirements": "Ethnic Runway",
	"status": "Approved"
}, {
	"id": 23,
	"user": "Blakelee",
	"role": "Paralegal",
	"state": "Lajeosa",
	"place": "Antsirabe",
	"phone_number": "881-856-9122",
	"job_requirements": "IMODA FASHION FEST 2024",
	"status": "Approved"
}, {
	"id": 24,
	"user": "Teddi",
	"role": "Web Developer II",
	"state": "Fatukanutu",
	"place": "Kaberamaido",
	"phone_number": "890-649-7610",
	"job_requirements": "codesquarry event",
	"status": "Approved"
}]



export const applicationtableColumns = (editmodalToggle,handleDeletedata,states) => [
	{
		name: 'ID',
		selector: row => row['id'],
		sortable: true,
		center: false,
	},
	{
		name: 'USER',
		selector: row => `${row.user_name}`,
		sortable: true,
		center: false,
	},
	{
		name: 'USER ROLE',
		selector: row => `${row.user_role_name}`,
		sortable: true,
		center: true,
		minWidth: '200px',
	},
	{
		name: 'USER WHATSAPP',
		selector: row => `${row.user_whatsapp_number}`,
		sortable: true,
		center: true,
		minWidth: '200px',
	},
	{
		name: 'EVENT TITLE',
		selector: row => `${row.event?.title}`,
		sortable: true,
		center: true,
		minWidth: '200px',
	},
	{
		name: 'EVENT CITY',
		selector: row => `${row.event?.city}`,
		sortable: true,
		center: true,
		minWidth: '150px',
	},
	{
		name: 'STATE',
		selector: row => states?.find(i=>i.id === row.event?.state)?.name,
		sortable: true,
		center: true,
		minWidth: '150px',
	},
	{
		name:'STATUS',
		selector:row=> `${row.status}`,
		sortable: true,
		center: true,
		minWidth: '150px',
	},
	{
		name: 'ACTION',
		selector: row => <div className="d-flex " >
			<Btn attrBtn={{ color: 'none', onClick: () => editmodalToggle(row) }}><i className="fa fa-edit edit-btn" style={{ fontSize: '18px' }}></i></Btn>
			<Btn attrBtn={{ color: 'none', onClick: () => handleDeletedata(row) }}><i className="icofont icofont-ui-delete" style={{ fontSize: '18px' }}></i></Btn>
		</div>,
		center: true,
		minWidth: '150px'
	}
];






//------------------------- Market Place---------------------------------------------------

export const dummytabledata = [{
	"id": 1,
	"user_name": "hdorken0",
	"title": "Bar Mix - Pina Colada, 355 Ml",
	"featured_image": "http://dummyimage.com/111x100.png/ff4444/ffffff",
	"category": "brand new product",
	"price": 915,
	"featured": false,
	"status": true,
}, {
	"id": 2,
	"user_name": "aaslam1",
	"title": "Sauce - Mint",
	"featured_image": "http://dummyimage.com/126x100.png/ff4444/ffffff",
	"category": "used product",
	"price": 720,
	"featured": false,
	"status": true,
}, {
	"id": 3,
	"user_name": "anevill2",
	"title": "Tuna - Canned, Flaked, Light",
	"featured_image": "http://dummyimage.com/154x100.png/ff4444/ffffff",
	"category": "brand new product",
	"price": 504,
	"featured": true,
	"status": true,
}, {
	"id": 4,
	"user_name": "dmerwood3",
	"title": "Breakfast Quesadillas",
	"featured_image": "http://dummyimage.com/119x100.png/cc0000/ffffff",
	"category": "used product",
	"price": 638,
	"featured": true,
	"status": true,
}, {
	"id": 5,
	"user_name": "ipancoust4",
	"title": "Puree - Mocha",
	"featured_image": "http://dummyimage.com/191x100.png/dddddd/000000",
	"category": "used product",
	"price": 609,
	"featured": false,
	"status": false,
}, {
	"id": 6,
	"user_name": "sarkil5",
	"title": "Bread - Wheat Baguette",
	"featured_image": "http://dummyimage.com/144x100.png/ff4444/ffffff",
	"category": "used product",
	"price": 678,
	"featured": true,
	"status": true,
}, {
	"id": 7,
	"user_name": "vdinapoli6",
	"title": "Wine - Magnotta - Cab Franc",
	"featured_image": "http://dummyimage.com/216x100.png/cc0000/ffffff",
	"category": "brand new product",
	"price": 817,
	"featured": false,
	"status": true,
}, {
	"id": 8,
	"user_name": "arosettini7",
	"title": "Squid - Tubes / Tenticles 10/20",
	"featured_image": "http://dummyimage.com/158x100.png/5fa2dd/ffffff",
	"category": "used product",
	"price": 381,
	"featured": true,
	"status": false,
}, {
	"id": 9,
	"user_name": "cleak8",
	"title": "Icecream Bar - Del Monte",
	"featured_image": "http://dummyimage.com/100x100.png/dddddd/000000",
	"category": "brand new product",
	"price": 686,
	"featured": false,
	"status": false,
}, {
	"id": 10,
	"user_name": "dbrosetti9",
	"title": "Beer - Alexander Kieths, Pale Ale",
	"featured_image": "http://dummyimage.com/129x100.png/ff4444/ffffff",
	"category": "used product",
	"price": 179,
	"featured": true,
	"status": true,
}, {
	"id": 11,
	"user_name": "smattosoffa",
	"title": "Steam Pan Full Lid",
	"featured_image": "http://dummyimage.com/228x100.png/5fa2dd/ffffff",
	"category": "brand new product",
	"price": 645,
	"featured": false,
	"status": false,
}, {
	"id": 12,
	"user_name": "fabendrothb",
	"title": "Sansho Powder",
	"featured_image": "http://dummyimage.com/116x100.png/dddddd/000000",
	"category": "brand new product",
	"price": 190,
	"featured": false,
	"status": false,
}, {
	"id": 13,
	"user_name": "dollanderc",
	"title": "Wine - Chianti Classico Riserva",
	"featured_image": "http://dummyimage.com/158x100.png/5fa2dd/ffffff",
	"category": "brand new product",
	"price": 108,
	"featured": true,
	"status": false,
}, {
	"id": 14,
	"user_name": "hberanekd",
	"title": "Appetizer - Mango Chevre",
	"featured_image": "http://dummyimage.com/165x100.png/5fa2dd/ffffff",
	"category": "used product",
	"price": 584,
	"featured": true,
	"status": false,
}, {
	"id": 15,
	"user_name": "dpuseye",
	"title": "Bread - Hamburger Buns",
	"featured_image": "http://dummyimage.com/172x100.png/ff4444/ffffff",
	"category": "brand new product",
	"price": 151,
	"featured": true,
	"status": true,
}, {
	"id": 16,
	"user_name": "dgepsonf",
	"title": "Wine - Rhine Riesling Wolf Blass",
	"featured_image": "http://dummyimage.com/177x100.png/dddddd/000000",
	"category": "used product",
	"price": 719,
	"featured": true,
	"status": false,
}, {
	"id": 17,
	"user_name": "etaping",
	"title": "Cup - 4oz Translucent",
	"featured_image": "http://dummyimage.com/195x100.png/dddddd/000000",
	"category": "brand new product",
	"price": 348,
	"featured": true,
	"status": false,
}, {
	"id": 18,
	"user_name": "sfritcheh",
	"title": "Jello - Assorted",
	"featured_image": "http://dummyimage.com/175x100.png/5fa2dd/ffffff",
	"category": "used product",
	"price": 589,
	"featured": false,
	"status": false,
}, {
	"id": 19,
	"user_name": "oswansboroughi",
	"title": "Appetizer - Mango Chevre",
	"featured_image": "http://dummyimage.com/188x100.png/ff4444/ffffff",
	"category": "brand new product",
	"price": 599,
	"featured": true,
	"status": true,
}, {
	"id": 20,
	"user_name": "mfrowenj",
	"title": "Trout - Hot Smkd, Dbl Fillet",
	"featured_image": "http://dummyimage.com/139x100.png/ff4444/ffffff",
	"category": "used product",
	"price": 145,
	"featured": true,
	"status": true,
}]



export const tableColumns = (handleEditdata,handleDeletedata) => [
	{
		name: 'ID',
		selector: row => `${row.id}`,
		sortable: true,
		center: true,
		minWidth: '150px'
	},
	{
		name: 'PRODUCT TITLE',
		selector: row => `${row.title}`,
		sortable: true,
		center: true,
		minWidth: '200px'
	},
	{
		name: 'USER NAME',
		selector: row => `${row.user_name}`,
		sortable: true,
		center: true,
		minWidth: '150px'
	},
	{
		name: 'PROFILE IMAGE',
		selector: row => (<div style={{ width: '100px', height: '100px', overflow: 'hidden', display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
			<img
				src={row.profile_image}
				alt=""
				style={{
					width: '100%',
					height: '100%',
					objectFit: 'cover',
					border: '1px solid #ddd',
					borderRadius: '4px',
					boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
					padding: '5px',
					margin: '5px',
					transition: 'transform 0.2s',
				}}
				onMouseOver={(e) => e.currentTarget.style.transform = 'scale(1.2)'}
				onMouseOut={(e) => e.currentTarget.style.transform = 'scale(1.0)'}
			/>
		</div>
		),
		center: true,
		minWidth: '150px'
	},
	{
		name: 'COVER IMAGE',
		selector: row => (<div style={{ width: '100px', height: '100px', overflow: 'hidden', display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
			<img
				src={row.cover_image}
				alt=""
				style={{
					width: '100%',
					height: '100%',
					objectFit: 'cover',
					border: '1px solid #ddd',
					borderRadius: '4px',
					boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
					padding: '5px',
					margin: '5px',
					transition: 'transform 0.2s',
				}}
				onMouseOver={(e) => e.currentTarget.style.transform = 'scale(1.2)'}
				onMouseOut={(e) => e.currentTarget.style.transform = 'scale(1.0)'}
			/>
		</div>
		),
		center: true,
		minWidth: '150px'
	},
	{
		name: 'CATEGORY',
		selector: row => row['category_name'],
		sortable: true,
		center: true,
		minWidth: '250px'
	},
	// {
	// 	name: 'SIZE',
	// 	selector: row => row['size_value'],
	// 	sortable: true,
	// 	center: true,
	// 	minWidth: '100px'
	// },
	{
		name: 'STOCK QUANTITY',
		selector: row => row['stock_quantity'],
		sortable: true,
		center: true,
		minWidth: '200px'
	},
	{
		name: 'PRICE',
		selector: row => `${row.price}`,
		sortable: true,
		center: true,
	},
	{
		name: 'SALE PRICE',
		selector: row => `${row.sale_price}`,
		sortable: true,
		center: true,
		minWidth: '200px'
	},
	{
		name: 'DESCRIPTION',
		selector: row => row['description'],
		sortable: true,
		center: true,
		minWidth: '250px'
	},
	{
		name: 'FEATURED',
		selector: row => <div className="form-check form-switch">
			<input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" onChange={console.log} checked={row?.is_featured} />
		</div>,
		sortable: true,
		center: true,
	},
	// {
	// 	name: 'STATUS',
	// 	selector: row => <Input type="select" name="select" className="form-select form-control">
	// 		{row['status'] ? (
	// 			<>
	// 				<option value="1" selected>{'Available'}</option>
	// 				<option value="2">{'Unavailable'}</option>
	// 			</>
	// 		) : (
	// 			<>
	// 				<option value="1">{'Available'}</option>
	// 				<option value="2" selected>{'Unavailable'}</option>
	// 			</>
	// 		)}
	// 	</Input>,
	// 	sortable: true,
	// 	center: true,
	// },
	{
		name: 'status',
		selector: row => `${row.status}`,
		sortable: true,
		center: true,
	},
	{
		name: 'ACTIVE',
		selector: row => <div className="form-check form-switch">
			<input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" onChange={console.log} checked={row?.active} />
		</div>,
		sortable: true,
		center: true,
	},
	{
		name: 'ACTION',
		selector: row => <div className="d-flex " >
			<Btn attrBtn={{ color: 'none', onClick: () => handleEditdata(row) }}><i className="fa fa-edit edit-btn" style={{ fontSize: '18px' }}></i></Btn>
			<Btn attrBtn={{ color: 'none', onClick: () => handleDeletedata(row) }}><i className="icofont icofont-ui-delete" style={{ fontSize: '18px' }}></i></Btn>
		</div>,
		center: true,
		minWidth: '150px',
		maxWidth: '200px',
	}
];




//................ MarketPlaceCategory...........................


export const Categorydummydata = [{
	"id": 1,
	"category": "Brand New Products"
}, {
	"id": 2,
	"category": "Used Products"
}]



export const CategorytableColumns = (editmodalToggle,handleDeletedata) => [
	{
		name: 'ID',
		selector: row => row['id'],
		sortable: true,
		center: false,
	},
	{
		name: 'CATEGORY',
		selector: row => `${row.name}`,
		sortable: true,
		center: true,
	},
	{
		name: 'ACTION',
		selector: row => <div className="d-flex " >
			<Btn attrBtn={{ color: 'none', onClick: () => editmodalToggle(row) }}><i className="fa fa-edit edit-btn" style={{ fontSize: '18px' }}></i></Btn>
			<Btn attrBtn={{ color: 'none', onClick: () => handleDeletedata(row) }}><i className="icofont icofont-ui-delete" style={{ fontSize: '18px' }}></i></Btn>
		</div>,
		sortable: true,
		center: true,
		minWidth: '150px',
		maxWidth: '200px',
	}
];





//................ sizeChart...........................


export const SizeChartdummydata = [{
	"id": 1,
	"size": "XXS"
}, {
	"id": 2,
	"size": "XS"
}, {
	"id": 3,
	"size": "S"
}, {
	"id": 4,
	"size": "M"
}, {
	"id": 5,
	"size": "L"
}
	, {
	"id": 6,
	"size": "XL"
}
	, {
	"id": 7,
	"size": "2XL"
}
	, {
	"id": 8,
	"size": "3XL"
}, {
	"id": 9,
	"size": "4XL"
}
]



export const SizeCharttableColumns = (editmodalToggle,handleDeletedata) => [
	{
		name: 'ID',
		selector: row => row['id'],
		sortable: true,
		center: false,
	},
	{
		name: 'SIZE',
		selector: row => `${row.size}`,
		sortable: true,
		center: true,
	},
	// {
	// 	name: 'ORDER',
	// 	selector: row => `${row.order_number}`,
	// 	sortable: true,
	// 	center: true,
	// },
	{
		name: 'ACTION',
		selector: row => <div className="d-flex " >
			<Btn attrBtn={{ color: 'none', onClick: () => editmodalToggle(row) }}><i className="fa fa-edit edit-btn" style={{ fontSize: '18px' }}></i></Btn>
			<Btn attrBtn={{ color: 'none', onClick: () => handleDeletedata(row) }}><i className="icofont icofont-ui-delete" style={{ fontSize: '18px' }}></i></Btn>
		</div>,
		sortable: true,
		center: true,
		minWidth: '150px',
		maxWidth: '200px',
	}
];






//------------------------- Business-order-------------------------------


export const dummybusinessdata = [{
	"id": 1,
	"order_id": "abc#0000008",
	"order_date": "3/4/2024",
	"delivery_date": "12/10/2023",
	"seller": "wwwwwwwwwwww",
	"customer": "Noreen",
	"total": 29,
	"payment_status": "Paid",
	"order_status": "Pending"
}, {
	"id": 2,
	"order_id": "#0000014",
	"order_date": "9/8/2023",
	"delivery_date": "3/23/2024",
	"seller": "Hector",
	"customer": "Kerwin",
	"total": 32,
	"payment_status": "Paid",
	"order_status": "Shipped"
}, {
	"id": 3,
	"order_id": "#0000015",
	"order_date": "9/6/2024",
	"delivery_date": "3/21/2024",
	"seller": "Margette",
	"customer": "Tonie",
	"total": 99,
	"payment_status": "Unpaid",
	"order_status": "Pending"
}, {
	"id": 4,
	"order_id": "#0000013",
	"order_date": "10/8/2023",
	"delivery_date": "8/3/2023",
	"seller": "Patrick",
	"customer": "Niall",
	"total": 49,
	"payment_status": "Paid",
	"order_status": "Delivered"
}, {
	"id": 5,
	"order_id": "#0000011",
	"order_date": "2/5/2024",
	"delivery_date": "3/18/2024",
	"seller": "Ann-marie",
	"customer": "Leisha",
	"total": 46,
	"payment_status": "Unpaid",
	"order_status": "Delivered"
}, {
	"id": 6,
	"order_id": "#0000012",
	"order_date": "30/10/2023",
	"delivery_date": "11/21/2023",
	"seller": "Justis",
	"customer": "Verla",
	"total": 82,
	"payment_status": "Paid",
	"order_status": "Pending"
}, {
	"id": 7,
	"order_id": "#0000010",
	"order_date": "8/9/2023",
	"delivery_date": "5/24/2024",
	"seller": "Claudius",
	"customer": "Carlina",
	"total": 6,
	"payment_status": "Unpaid",
	"order_status": "Pending"
}, {
	"id": 8,
	"order_id": "#0000016",
	"order_date": "3/1/2024",
	"delivery_date": "10/23/2023",
	"seller": "Mella",
	"customer": "Carlynne",
	"total": 53,
	"payment_status": "Unpaid",
	"order_status": "Shipped"
}, {
	"id": 9,
	"order_id": "#0000014",
	"order_date": "25/2/2024",
	"delivery_date": "7/30/2023",
	"seller": "Sutton",
	"customer": "Vivienne",
	"total": 89,
	"payment_status": "Paid",
	"order_status": "Pending"
}, {
	"id": 10,
	"order_id": "#0000017",
	"order_date": "28/4/2024",
	"delivery_date": "7/13/2024",
	"seller": "Katharyn",
	"customer": "Rochette",
	"total": 61,
	"payment_status": "Paid",
	"order_status": "Pending"
}, {
	"id": 11,
	"order_id": "#0000018",
	"order_date": "16/7/2024",
	"delivery_date": "5/21/2024",
	"seller": "Chas",
	"customer": "Thadeus",
	"total": 100,
	"payment_status": "Unpaid",
	"order_status": "Shipped"
}, {
	"id": 12,
	"order_id": "#0000020",
	"order_date": "14/3/2024",
	"delivery_date": "1/17/2024",
	"seller": "Therese",
	"customer": "Ophelia",
	"total": 84,
	"payment_status": "Unpaid",
	"order_status": "Delivered"
}, {
	"id": 13,
	"order_id": "#0000019",
	"order_date": "15/6/2024",
	"delivery_date": "2/28/2024",
	"seller": "Henrik",
	"customer": "Victoria",
	"total": 50,
	"payment_status": "Paid",
	"order_status": "Delivered"
}, {
	"id": 14,
	"order_id": "#0000001",
	"order_date": "8/12/2023",
	"delivery_date": "12/19/2023",
	"seller": "Niall",
	"customer": "Bobbie",
	"total": 48,
	"payment_status": "Unpaid",
	"order_status": "Shipped"
}, {
	"id": 15,
	"order_id": "#0000002",
	"order_date": "26/12/2023",
	"delivery_date": "5/16/2024",
	"seller": "Ailina",
	"customer": "Gale",
	"total": 73,
	"payment_status": "Unpaid",
	"order_status": "Pending"
}, {
	"id": 16,
	"order_id": "#0000006",
	"order_date": "17/8/2023",
	"delivery_date": "11/29/2023",
	"seller": "Myrtle",
	"customer": "Karlik",
	"total": 24,
	"payment_status": "Paid",
	"order_status": "Pending"
}, {
	"id": 17,
	"order_id": "#0000003",
	"order_date": "26/10/2023",
	"delivery_date": "9/1/2023",
	"seller": "Elane",
	"customer": "Kalle",
	"total": 94,
	"payment_status": "Paid",
	"order_status": "Pending"
}, {
	"id": 18,
	"order_id": "#0000005",
	"order_date": "29/5/2024",
	"delivery_date": "12/10/2023",
	"seller": "Katalin",
	"customer": "Kordula",
	"total": 10,
	"payment_status": "Unpaid",
	"order_status": "Delivered"
}, {
	"id": 19,
	"order_id": "#0000004",
	"order_date": "22/1/2024",
	"delivery_date": "5/27/2024",
	"seller": "Darya",
	"customer": "Rosy",
	"total": 45,
	"payment_status": "Unpaid",
	"order_status": "Delivered"
}, {
	"id": 20,
	"order_id": "#0000007",
	"order_date": "25/1/2024",
	"delivery_date": "4/26/2024",
	"seller": "Bess",
	"customer": "Lorianna",
	"total": 44,
	"payment_status": "Paid",
	"order_status": "Shipped"
}]




export const businesstableColumns = (handleEditdata,handleDeletedata) => [
	{
		name: 'ID',
		selector: row => row['id'],
		sortable: true,
		center: false,
	},
	{
		name: 'ORDER ID',
		selector: row => row['razorpay_order_id'],
		sortable: true,
		center: false,
		minWidth: '250px',
	},
	{
		name: 'ORDER DATE',
		selector: row => `${row.order_date}`,
		sortable: true,
		center: true,
		minWidth: '150px',
	},
	{
		name: 'DELIVERY DATE',
		selector: row => `${row.delivery_date}`,
		sortable: true,
		center: true,
		minWidth: '150px',
	},
	{
		name: 'SELLER',
		selector: row => row["seller_name"],
		sortable: true,
		center: true,
		minWidth: '180px',
	},
	{
		name: 'CUSTOMER',
		selector: row => row["customer_name"],
		sortable: true,
		center: true,
		minWidth: '150px',
	},
	{
		name: 'TOTAL',
		selector: row => `${row.total_amount}`,
		sortable: true,
		center: true,
	},
	{
		name: 'PAYMENT STATUS',
		selector: row => `${row.payment_status}`,
		sortable: true,
		center: true,
		minWidth: '180px',
	},
	{
		name: 'ORDER STATUS',
		selector: row => `${row.delivery_status}`,
		sortable: true,
		center: true,
		minWidth: '180px',
	},
	{
		name: 'ACTION',
		selector: row => <div className="d-flex " >
			<Btn attrBtn={{ color: 'none', onClick: () => handleEditdata(row) }}><i className="fa fa-edit edit-btn" style={{ fontSize: '18px' }}></i></Btn>
			<Btn attrBtn={{ color: 'none', onClick: () => handleDeletedata(row) }}><i className="icofont icofont-ui-delete" style={{ fontSize: '18px' }}></i></Btn>
		</div>,
		sortable: true,
		center: true,
		minWidth: '150px'
	}
];









//------------------------- Id Card-------------------------------


export const dummyiddata = [{
	"sl_no": 1,
	"id": "6380",
	"validity": "10/13/2023",
	"profile_img": "http://dummyimage.com/100x100.png/cc0000/ffffff",
	"name": "Bjorn",
	"user_role": "Sales Representative",
	"phone": "210-293-1569",
	"whatsapp": "827-742-2272",
	"regional_partner": "Charles williams",
	"adhar_front_image": "Hurleigh",
	"adhar_back_image": "Ursala",
	"status": "Approved",
	"payment_status": "Paid",
	"state": "Texas"
}, {
	"sl_no": 2,
	"id": "68",
	"validity": "3/21/2024",
	"profile_img": "http://dummyimage.com/100x100.png/dddddd/000000",
	"name": "Raoul",
	"user_role": "Account Coordinator",
	"phone": "872-135-8192",
	"whatsapp": "130-568-3349",
	"regional_partner": "Soumya singh",
	"adhar_front_image": "Jess",
	"adhar_back_image": "Sigrid",
	"status": "Rejected",
	"payment_status": "Unpaid",
	"state": null
}, {
	"sl_no": 3,
	"id": "44517",
	"validity": "6/25/2024",
	"profile_img": "http://dummyimage.com/100x100.png/5fa2dd/ffffff",
	"name": "Seumas",
	"user_role": "Technical Writer",
	"phone": "343-417-1485",
	"whatsapp": "599-136-8060",
	"regional_partner": "Charles williams",
	"adhar_front_image": "Ranee",
	"adhar_back_image": "Walt",
	"status": "Approved",
	"payment_status": "Unpaid",
	"state": "Aveiro"
}, {
	"sl_no": 4,
	"id": "1",
	"validity": "5/27/2024",
	"profile_img": "http://dummyimage.com/100x100.png/5fa2dd/ffffff",
	"name": "Donny",
	"user_role": "Administrative Assistant II",
	"phone": "948-903-3616",
	"whatsapp": "932-104-6650",
	"regional_partner": "Soumya singh",
	"adhar_front_image": "Sandi",
	"adhar_back_image": "Lethia",
	"status": "Applied",
	"payment_status": "Paid",
	"state": null
}, {
	"sl_no": 5,
	"id": "3",
	"validity": "6/16/2024",
	"profile_img": "http://dummyimage.com/100x100.png/ff4444/ffffff",
	"name": "Celeste",
	"user_role": "Environmental Specialist",
	"phone": "449-185-6423",
	"whatsapp": "893-936-0862",
	"regional_partner": "Charles williams",
	"adhar_front_image": "Frazier",
	"adhar_back_image": "Archer",
	"status": "Approved",
	"payment_status": "Unpaid",
	"state": null
}, {
	"sl_no": 6,
	"id": "71435",
	"validity": "3/26/2024",
	"profile_img": "http://dummyimage.com/100x100.png/ff4444/ffffff",
	"name": "Johnna",
	"user_role": "Associate Professor",
	"phone": "370-802-7890",
	"whatsapp": "601-384-5206",
	"regional_partner": "Soumya singh",
	"adhar_front_image": "Eleen",
	"adhar_back_image": "Rahel",
	"status": "Approved",
	"payment_status": "Paid",
	"state": null
}, {
	"sl_no": 7,
	"id": "5",
	"validity": "5/29/2024",
	"profile_img": "http://dummyimage.com/100x100.png/5fa2dd/ffffff",
	"name": "Mitchell",
	"user_role": "Recruiter",
	"phone": "610-210-1645",
	"whatsapp": "109-931-6301",
	"regional_partner": "Charles williams",
	"adhar_front_image": "Haydon",
	"adhar_back_image": "Jenine",
	"status": "Applied",
	"payment_status": "Paid",
	"state": null
}, {
	"sl_no": 8,
	"id": "1",
	"validity": "6/11/2024",
	"profile_img": "http://dummyimage.com/100x100.png/5fa2dd/ffffff",
	"name": "Duffy",
	"user_role": "Senior Sales Associate",
	"phone": "800-590-5824",
	"whatsapp": "541-783-8181",
	"regional_partner": "Soumya singh",
	"adhar_front_image": "Jennie",
	"adhar_back_image": "Latrina",
	"status": "Rejected",
	"payment_status": "Paid",
	"state": null
}, {
	"sl_no": 9,
	"id": "0140",
	"validity": "10/19/2023",
	"profile_img": "http://dummyimage.com/100x100.png/ff4444/ffffff",
	"name": "Kandace",
	"user_role": "Office Assistant IV",
	"phone": "742-665-3203",
	"whatsapp": "999-715-8628",
	"regional_partner": "Charles williams",
	"adhar_front_image": "Lauree",
	"adhar_back_image": "Roseann",
	"status": "Applied",
	"payment_status": "Paid",
	"state": null
}, {
	"sl_no": 10,
	"id": "459",
	"validity": "12/16/2023",
	"profile_img": "http://dummyimage.com/100x100.png/ff4444/ffffff",
	"name": "Charmian",
	"user_role": "Research Associate",
	"phone": "495-266-1246",
	"whatsapp": "570-267-9568",
	"regional_partner": "Soumya singh",
	"adhar_front_image": "Lorrie",
	"adhar_back_image": "Ikey",
	"status": "Rejected",
	"payment_status": "Unpaid",
	"state": null
}, {
	"sl_no": 11,
	"id": "476",
	"validity": "2/12/2024",
	"profile_img": "http://dummyimage.com/100x100.png/cc0000/ffffff",
	"name": "Jeramey",
	"user_role": "Engineer IV",
	"phone": "825-590-5620",
	"whatsapp": "690-199-8080",
	"regional_partner": "Charles williams",
	"adhar_front_image": "Tomi",
	"adhar_back_image": "Sandy",
	"status": "Applied",
	"payment_status": "Paid",
	"state": null
}, {
	"sl_no": 12,
	"id": "97",
	"validity": "3/10/2024",
	"profile_img": "http://dummyimage.com/100x100.png/ff4444/ffffff",
	"name": "Rosaleen",
	"user_role": "Design Engineer",
	"phone": "815-633-8843",
	"whatsapp": "166-670-4711",
	"regional_partner": "Soumya singh",
	"adhar_front_image": "Korella",
	"adhar_back_image": "Edita",
	"status": "Rejected",
	"payment_status": "Paid",
	"state": null
}, {
	"sl_no": 13,
	"id": "709",
	"validity": "8/11/2023",
	"profile_img": "http://dummyimage.com/100x100.png/cc0000/ffffff",
	"name": "Egor",
	"user_role": "VP Quality Control",
	"phone": "928-763-6561",
	"whatsapp": "471-353-4219",
	"regional_partner": "Charles williams",
	"adhar_front_image": "Cara",
	"adhar_back_image": "Elvera",
	"status": "Applied",
	"payment_status": "Unpaid",
	"state": null
}, {
	"sl_no": 14,
	"id": "23",
	"validity": "7/10/2024",
	"profile_img": "http://dummyimage.com/100x100.png/5fa2dd/ffffff",
	"name": "Lizabeth",
	"user_role": "Database Administrator IV",
	"phone": "948-980-7699",
	"whatsapp": "244-781-3017",
	"regional_partner": "Soumya singh",
	"adhar_front_image": "Christie",
	"adhar_back_image": "Eveleen",
	"status": "Approved",
	"payment_status": "Unpaid",
	"state": null
}, {
	"sl_no": 15,
	"id": "485",
	"validity": "11/11/2023",
	"profile_img": "http://dummyimage.com/100x100.png/ff4444/ffffff",
	"name": "Wendel",
	"user_role": "Civil Engineer",
	"phone": "784-398-0737",
	"whatsapp": "455-592-8222",
	"regional_partner": "Charles williams",
	"adhar_front_image": "Talia",
	"adhar_back_image": "Moore",
	"status": "Rejected",
	"payment_status": "Paid",
	"state": null
}, {
	"sl_no": 16,
	"id": "92490",
	"validity": "5/27/2024",
	"profile_img": "http://dummyimage.com/100x100.png/ff4444/ffffff",
	"name": "Fidelio",
	"user_role": "Chief Design Engineer",
	"phone": "334-415-0611",
	"whatsapp": "730-783-8738",
	"regional_partner": "Soumya singh",
	"adhar_front_image": "Alyosha",
	"adhar_back_image": "Celisse",
	"status": "Applied",
	"payment_status": "Paid",
	"state": null
}, {
	"sl_no": 17,
	"id": "25",
	"validity": "8/8/2023",
	"profile_img": "http://dummyimage.com/100x100.png/cc0000/ffffff",
	"name": "Benjie",
	"user_role": "Human Resources Manager",
	"phone": "179-341-5861",
	"whatsapp": "131-194-8384",
	"regional_partner": "Charles williams",
	"adhar_front_image": "Fairlie",
	"adhar_back_image": "Tammy",
	"status": "Approved",
	"payment_status": "Unpaid",
	"state": null
}, {
	"sl_no": 18,
	"id": "15909",
	"validity": "1/13/2024",
	"profile_img": "http://dummyimage.com/100x100.png/ff4444/ffffff",
	"name": "Eolanda",
	"user_role": "Product Engineer",
	"phone": "294-223-0171",
	"whatsapp": "662-412-3466",
	"regional_partner": "Soumya singh",
	"adhar_front_image": "Rab",
	"adhar_back_image": "Brunhilda",
	"status": "Approved",
	"payment_status": "Unpaid",
	"state": null
}, {
	"sl_no": 19,
	"id": "167",
	"validity": "7/30/2023",
	"profile_img": "http://dummyimage.com/100x100.png/5fa2dd/ffffff",
	"name": "Efren",
	"user_role": "Chemical Engineer",
	"phone": "660-582-1083",
	"whatsapp": "824-894-8661",
	"regional_partner": "Charles williams",
	"adhar_front_image": "Elnore",
	"adhar_back_image": "Andrea",
	"status": "Approved",
	"payment_status": "Unpaid",
	"state": null
}, {
	"sl_no": 20,
	"id": "42579",
	"validity": "2/13/2024",
	"profile_img": "http://dummyimage.com/100x100.png/ff4444/ffffff",
	"name": "Dewie",
	"user_role": "VP Marketing",
	"phone": "649-422-5203",
	"whatsapp": "554-615-2446",
	"regional_partner": "Soumya singh",
	"adhar_front_image": "Jacqui",
	"adhar_back_image": "Caprice",
	"status": "Rejected",
	"payment_status": "Paid",
	"state": null
}]

export const idcardtableColumns = (handleEditdata,handleDeletedata) => [
	
	{
		name: 'ID',
		selector: row => row['id'],
		sortable: true,
		center: false,
	},
	{
		name: 'ID CARD NO.',
		selector: row => row['id_card_number'],
		sortable: true,
		center: false,
		minWidth: '150px'
	},
	{
		name: 'NAME',
		selector: row => `${row.name}`,
		sortable: true,
		center: true,
		minWidth: '150px'
	},
	{
		name: 'IMAGE',
		selector: row => (<div style={{ width: '100px', height: '100px', overflow: 'hidden', display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
			<img
				src={row.image}
				alt=""
				style={{
					width: '100%',
					height: '100%',
					objectFit: 'cover',
					border: '1px solid #ddd',
					borderRadius: '4px',
					boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
					padding: '5px',
					margin: '5px',
					transition: 'transform 0.2s',
				}}
				onMouseOver={(e) => e.currentTarget.style.transform = 'scale(1.2)'}
				onMouseOut={(e) => e.currentTarget.style.transform = 'scale(1.0)'}
			/>
		</div>
		),
		center: true,
		minWidth: '150px'
	},
	{
		name: 'USER ROLE',
		selector: row => `${row.user_role_name}`,
		sortable: true,
		center: true,
		minWidth: '150px'
	},
	{
		name: 'REGIONAL PARTNER',
		selector: row => `${row.regional_partner_name}`,
		sortable: true,
		center: true,
		minWidth: '200px'
	},
	{
		name: 'VALIDITY',
		selector: row => `${row.validity_date}`,
		sortable: true,
		center: true,
	},
	{
		name: 'PAYMENT STATUS',
		selector: row => `${row.payment_status}`,
		sortable: true,
		center: true,
		minWidth: '200px'
	},
	{
		name: 'TOTAL',
		selector: row => `${row.total}`,
		sortable: true,
		center: true,
	},
	{
		name: 'PHONE NUMBER',
		selector: row => `${row.phone_number}`,
		sortable: true,
		center: true,
		minWidth: '200px'
	},
	{
		name: 'WHATSAPP',
		selector: row => `${row.whatsapp_number}`,
		sortable: true,
		center: true,
		minWidth: '200px'
	},
	{
		name: 'ADHAR FRONT IMG',
		selector: row => (<div style={{ width: '100px', height: '100px', overflow: 'hidden', display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
			<img
				src={row.aadhar_front_image}
				alt=""
				style={{
					width: '100%',
					height: '100%',
					objectFit: 'cover',
					border: '1px solid #ddd',
					borderRadius: '4px',
					boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
					padding: '5px',
					margin: '5px',
					transition: 'transform 0.2s',
				}}
				onMouseOver={(e) => e.currentTarget.style.transform = 'scale(1.2)'}
				onMouseOut={(e) => e.currentTarget.style.transform = 'scale(1.0)'}
			/>
		</div>
		),
		center: true,
		minWidth: '150px'
	},
	{
		name: 'ADHAR BACK IMG',
		selector: row => (<div style={{ width: '100px', height: '100px', overflow: 'hidden', display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
			<img
				src={row.aadhar_back_image}
				alt=""
				style={{
					width: '100%',
					height: '100%',
					objectFit: 'cover',
					border: '1px solid #ddd',
					borderRadius: '4px',
					boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
					padding: '5px',
					margin: '5px',
					transition: 'transform 0.2s',
				}}
				onMouseOver={(e) => e.currentTarget.style.transform = 'scale(1.2)'}
				onMouseOut={(e) => e.currentTarget.style.transform = 'scale(1.0)'}
			/>
		</div>
		),
		center: true,
		minWidth: '150px'
	},
	
	{
		name: 'STATUS',
		selector: row => <Input value={row['status']} onChange={(e) => handleEditdata(row,e)} type="select" name="select" className="form-select form-control">
			        <option value="Applied" >{'Applied'}</option>
					<option value="Approved">{'Approved'}</option>
					<option value="Rejected">{'Rejected'}</option>
		</Input>,
		sortable: true,
		center: true,
		minWidth: '150px'
	},
	{
		name: 'ACTION',
		selector: row => <div className="d-flex " >
			{/* <Btn attrBtn={{ color: 'none', onClick: () => handleEditdata(row) }}><i className="fa fa-edit edit-btn" style={{ fontSize: '18px' }}></i></Btn> */}
			<Btn attrBtn={{ color: 'none',onClick: () => handleDeletedata(row) }}><i className="icofont icofont-ui-delete" style={{ fontSize: '18px' }}></i></Btn>
		</div>,
		sortable: true,
		center: true,
		minWidth: '150px',
		maxWidth: '200px',
	}
];


//--------------------------------- All Attributes-------------------------------------------

export const attributesdummydata = [{
	"id": 1,
	"user_role": "Marketing Manager",
	"image": "http://dummyimage.com/100x100.png/ff4444/ffffff",
	"attribute": "Height in cm"
}, {
	"id": 2,
	"user_role": "Assistant Manager",
	"image": "http://dummyimage.com/100x100.png/ff4444/ffffff",
	"attribute": "Weight in kg"
}, {
	"id": 3,
	"user_role": "Data Coordinator",
	"image": "http://dummyimage.com/100x100.png/ff4444/ffffff",
	"attribute": "languages known"
}, {
	"id": 4,
	"user_role": "Account Executive",
	"image": "http://dummyimage.com/100x100.png/dddddd/000000",
	"attribute": "Bust in inches"
}, {
	"id": 5,
	"user_role": "Registered Nurse",
	"image": "http://dummyimage.com/100x100.png/5fa2dd/ffffff",
	"attribute": "category"
}, {
	"id": 6,
	"user_role": "Product Engineer",
	"image": "http://dummyimage.com/100x100.png/cc0000/ffffff",
	"attribute": "languages known"
}, {
	"id": 7,
	"user_role": "Payment Adjustment Coordinator",
	"image": "http://dummyimage.com/100x100.png/5fa2dd/ffffff",
	"attribute": "Waist in inches"
}, {
	"id": 8,
	"user_role": "Account Executive",
	"image": "http://dummyimage.com/100x100.png/dddddd/000000",
	"attribute": "Hips in inches"
}, {
	"id": 9,
	"user_role": "Senior Quality Engineer",
	"image": "http://dummyimage.com/100x100.png/5fa2dd/ffffff",
	"attribute": "Shoes in inches"
}, {
	"id": 10,
	"user_role": "Analyst Programmer",
	"image": "http://dummyimage.com/100x100.png/5fa2dd/ffffff",
	"attribute": "education"
}, {
	"id": 11,
	"user_role": "Systems Administrator I",
	"image": "http://dummyimage.com/100x100.png/5fa2dd/ffffff",
	"attribute": "Skin Tone"
}, {
	"id": 12,
	"user_role": "Senior Cost Accountant",
	"image": "http://dummyimage.com/100x100.png/5fa2dd/ffffff",
	"attribute": "experience"
}, {
	"id": 13,
	"user_role": "Software Test Engineer I",
	"image": "http://dummyimage.com/100x100.png/5fa2dd/ffffff",
	"attribute": "Eye Colour"
}, {
	"id": 14,
	"user_role": "Assistant Professor",
	"image": "http://dummyimage.com/100x100.png/5fa2dd/ffffff",
	"attribute": "Hair Colour"
}, {
	"id": 15,
	"user_role": "Senior Cost Accountant",
	"image": "http://dummyimage.com/100x100.png/5fa2dd/ffffff",
	"attribute": "Experienced"
}, {
	"id": 16,
	"user_role": "Marketing Manager",
	"image": "http://dummyimage.com/100x100.png/dddddd/000000",
	"attribute": "Languages Known"
}, {
	"id": 17,
	"user_role": "Internal Auditor",
	"image": "http://dummyimage.com/100x100.png/ff4444/ffffff",
	"attribute": "Present Profession"
}, {
	"id": 18,
	"user_role": "Recruiter",
	"image": "http://dummyimage.com/100x100.png/cc0000/ffffff",
	"attribute": "Passport"
}, {
	"id": 19,
	"user_role": "Biostatistician IV",
	"image": "http://dummyimage.com/100x100.png/cc0000/ffffff",
	"attribute": "Driving"
}, {
	"id": 20,
	"user_role": "Junior Executive",
	"image": "http://dummyimage.com/100x100.png/cc0000/ffffff",
	"attribute": "passport"
}]

export const attributestableColumns = (handleEditdata,handleDeletedata) => [
	{
		name: 'ID',
		selector: row => row['id'],
		sortable: true,
		center: false,
	},
	{
		name: 'USER ROLE',
		selector: row => `${row.user_role_name}`,
		sortable: true,
		center: true,
	},
		{
		name: 'IMAGE',
		selector: row => (<div style={{ width: '100px', height: '100px', overflow: 'hidden', display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
			<img
				src={row.image}
				alt=""
				style={{
					width: '100%',
					height: '100%',
					objectFit: 'cover',
					border: '1px solid #ddd',
					borderRadius: '4px',
					boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
					padding: '5px',
					margin: '5px',
					transition: 'transform 0.2s',
				}}
				onMouseOver={(e) => e.currentTarget.style.transform = 'scale(1.2)'}
				onMouseOut={(e) => e.currentTarget.style.transform = 'scale(1.0)'}
			/>
		</div>
		),
		center: true,
		minWidth: '150px'
	},
	{
		name: 'ATTRIBUTE',
		selector: row => row['attribute_name'],
		sortable: true,
		center: true,
	},
	{
		name: 'ACTION',
		selector: row => <div className="d-flex " >
			<Btn attrBtn={{ color: 'none', onClick: () => handleEditdata(row) }}><i className="fa fa-edit edit-btn" style={{ fontSize: '18px' }}></i></Btn>
			<Btn attrBtn={{ color: 'none', onClick: () => handleDeletedata(row) }}><i className="icofont icofont-ui-delete" style={{ fontSize: '18px' }}></i></Btn>
		</div>,
		sortable: true,
		center: true,
		minWidth: '150px'
	}
];


//-------------------------------  User Attributes---------------------------------------------

export const userattributesdummydata = [{
	"id": 1,
	"user": "Koralle",
	"attributes": "Height in cm",
	"value": "161"
}, {
	"id": 2,
	"user": "Jacquette",
	"attributes": "Weight in kg",
	"value": "55"
}, {
	"id": 3,
	"user": "Peyton",
	"attributes": "Bust in inches",
	"value": "32"
}, {
	"id": 4,
	"user": "Kaylyn",
	"attributes": "Waist in inches",
	"value": "28"
}, {
	"id": 5,
	"user": "Haleigh",
	"attributes": "Hips in inches",
	"value": "36"
}, {
	"id": 6,
	"user": "Wallie",
	"attributes": "Shoes in inches",
	"value": "9"
}, {
	"id": 7,
	"user": "Sunny",
	"attributes": "shoulder in inches",
	"value": "14"
}, {
	"id": 8,
	"user": "Melamie",
	"attributes": "Skin Tone",
	"value": "fair"
}, {
	"id": 9,
	"user": "Lu",
	"attributes": "Eye Colour",
	"value": "black"
}, {
	"id": 10,
	"user": "Magdaia",
	"attributes": "Hair Colour",
	"value": "black"
}, {
	"id": 11,
	"user": "Marti",
	"attributes": "Experienced",
	"value": "yes"
}, {
	"id": 12,
	"user": "Avie",
	"attributes": "Languages Known",
	"value": "Hindi, English, Marathi"
}, {
	"id": 13,
	"user": "Malory",
	"attributes": "Education",
	"value": "D.phram, B.Pharm"
}, {
	"id": 14,
	"user": "Pattin",
	"attributes": "Present Profession",
	"value": "Pharmacist"
}, {
	"id": 15,
	"user": "Jany",
	"attributes": "Passport",
	"value": "yes"
}, {
	"id": 16,
	"user": "Tami",
	"attributes": "Driving",
	"value": "yes"
}, {
	"id": 17,
	"user": "Yale",
	"attributes": "Eye Colour",
	"value": "Dark Brown"
}, {
	"id": 18,
	"user": "Ekaterina",
	"attributes": "Present Profession",
	"value": "Advocate"
}, {
	"id": 19,
	"user": "Freddi",
	"attributes": "education",
	"value": "B.com and Fashion Designing"
}, {
	"id": 20,
	"user": "Alyssa",
	"attributes": "Shoes in inches",
	"value": "7"
}]

export const userattributestableColumns = (editmodalToggle,handleDeletedata) => [
	{
		name: 'ID',
		selector: row => row['id'],
		sortable: true,
		center: false,
	},
	{
		name: 'USER',
		selector: row => `${row.user_name}`,
		sortable: true,
		center: true,
	},
	{
		name: 'ATTRIBUTE',
		selector: row => row['attribute_name'],
		sortable: true,
		center: true,
	},
	{
		name: 'VALUE',
		selector: row => row['value'],
		sortable: true,
		center: true,
	},
	{
		name: 'ACTION',
		selector: row => <div className="d-flex " >
			<Btn attrBtn={{ color: 'none', onClick: () => editmodalToggle(row) }}><i className="fa fa-edit edit-btn" style={{ fontSize: '18px' }}></i></Btn>
			<Btn attrBtn={{ color: 'none', onClick: () => handleDeletedata(row) }}><i className="icofont icofont-ui-delete" style={{ fontSize: '18px' }}></i></Btn>
		</div>,
		sortable: true,
		center: true,
		minWidth: '150px',
		maxWidth: '200px',
	}
];








//------------------------ All Preference----------------------------------


export const preferencesdummydata = [{
	"id": 1,
	"title": "Civil Engineer"
}, {
	"id": 2,
	"title": "Assistant Professor"
}, {
	"id": 3,
	"title": "Assistant Professor"
}, {
	"id": 4,
	"title": "Marketing Manager"
}, {
	"id": 5,
	"title": "Accountant IV"
}, {
	"id": 6,
	"title": "Desktop Support Technician"
}, {
	"id": 7,
	"title": "Recruiting Manager"
}, {
	"id": 8,
	"title": "Account Executive"
}, {
	"id": 9,
	"title": "Assistant Manager"
}, {
	"id": 10,
	"title": "Engineer II"
}, {
	"id": 11,
	"title": "Programmer Analyst II"
}, {
	"id": 12,
	"title": "Structural Analysis Engineer"
}, {
	"id": 13,
	"title": "Environmental Specialist"
}, {
	"id": 14,
	"title": "Occupational Therapist"
}, {
	"id": 15,
	"title": "Director of Sales"
}, {
	"id": 16,
	"title": "Engineer IV"
}, {
	"id": 17,
	"title": "Software Test Engineer II"
}, {
	"id": 18,
	"title": "Director of Sales"
}, {
	"id": 19,
	"title": "Computer Systems Analyst I"
}, {
	"id": 20,
	"title": "Programmer II"
}]



export const preferencestableColumns = (editmodalToggle,handleDeletedata) => [
	{
		name: 'ID',
		selector: row => row['id'],
		sortable: true,
		center: false,
	},
	{
		name: 'PREFERENCE',
		selector: row => `${row.name}`,
		sortable: true,
		center: true,
	},
	{
		name: 'ACTION',
		selector: row => <div className="d-flex " >
			<Btn attrBtn={{ color: 'none', onClick: () => editmodalToggle(row) }}><i className="fa fa-edit edit-btn" style={{ fontSize: '18px' }}></i></Btn>
			<Btn attrBtn={{ color: 'none', onClick: () => handleDeletedata(row) }}><i className="icofont icofont-ui-delete" style={{ fontSize: '18px' }}></i></Btn>
		</div>,
		sortable: true,
		center: true,
		minWidth: '150px',
		maxWidth: '200px',
	}
];








//---------------------------- User preferences---------------------------------


export const userpreferencesdummydata = [{
	"id": 1,
	"user_name": "Maryjo",
	"preference": "Tax Accountant"
}, {
	"id": 2,
	"user_name": "Marlon",
	"preference": "Human Resources Assistant I"
}, {
	"id": 3,
	"user_name": "Abbi",
	"preference": "Cost Accountant"
}, {
	"id": 4,
	"user_name": "Scarlet",
	"preference": "Assistant Professor"
}, {
	"id": 5,
	"user_name": "Frederica",
	"preference": "Quality Control Specialist"
}, {
	"id": 6,
	"user_name": "Hermina",
	"preference": "VP Quality Control"
}, {
	"id": 7,
	"user_name": "Kendra",
	"preference": "Food Chemist"
}, {
	"id": 8,
	"user_name": "Mair",
	"preference": "Recruiter"
}, {
	"id": 9,
	"user_name": "Kayle",
	"preference": "Teacher"
}, {
	"id": 10,
	"user_name": "Cordelie",
	"preference": "Recruiter"
}, {
	"id": 11,
	"user_name": "Alister",
	"preference": "Graphic Designer"
}, {
	"id": 12,
	"user_name": "Wood",
	"preference": "Account Representative I"
}, {
	"id": 13,
	"user_name": "Allianora",
	"preference": "Software Consultant"
}, {
	"id": 14,
	"user_name": "Laurianne",
	"preference": "Human Resources Manager"
}, {
	"id": 15,
	"user_name": "Caldwell",
	"preference": "Developer IV"
}, {
	"id": 16,
	"user_name": "Hailey",
	"preference": "Nurse Practicioner"
}, {
	"id": 17,
	"user_name": "Lolita",
	"preference": "Information Systems Manager"
}, {
	"id": 18,
	"user_name": "Nappie",
	"preference": "VP Marketing"
}, {
	"id": 19,
	"user_name": "Thom",
	"preference": "Sales Representative"
}, {
	"id": 20,
	"user_name": "Lindsy",
	"preference": "Dental Hygienist"
}];

export const userpreferencestableColumns = (editmodalToggle,handleDeletedata) => [
	{
		name: 'ID',
		selector: row => row['id'],
		sortable: true,
		center: false,
	},
	{
		name: 'USERNAME',
		selector: row => `${row.user_name}`,
		sortable: true,
		center: true,
	},
	{
		name: 'PREFERENCE',
		selector: row => `${row.preference_name}`,
		sortable: true,
		center: true,
	},
	{
		name: 'ACTION',
		selector: row => <div className="d-flex " >
			<Btn attrBtn={{ color: 'none', onClick: () => editmodalToggle(row) }}><i className="fa fa-edit edit-btn" style={{ fontSize: '18px' }}></i></Btn>
			<Btn attrBtn={{ color: 'none', onClick: () => handleDeletedata(row) }}><i className="icofont icofont-ui-delete" style={{ fontSize: '18px' }}></i></Btn>
		</div>,
		sortable: true,
		center: true,
		minWidth: '150px',
		maxWidth: '200px',
	}
];


import React, { useCallback, useMemo, useRef, useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { Tooltip, Button } from "@mui/material";
import "react-quill/dist/quill.snow.css";
import "./Editor.css"; 
import { Btn } from "../../../../AbstractElements";
import AnchorDialog from "./Anchordialog";
import SpecialCharacterDialog from "./SpecialCharacterDialog";
import SourceDialog from "./SourceDialog";

var icons = ReactQuill.Quill.import("ui/icons");

icons["cut"] = `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M760-120 480-400l-94 94q8 15 11 32t3 34q0 66-47 113T240-80q-66 0-113-47T80-240q0-66 47-113t113-47q17 0 34 3t32 11l94-94-94-94q-15 8-32 11t-34 3q-66 0-113-47T80-720q0-66 47-113t113-47q66 0 113 47t47 113q0 17-3 34t-11 32l494 494v40H760ZM600-520l-80-80 240-240h120v40L600-520ZM240-640q33 0 56.5-23.5T320-720q0-33-23.5-56.5T240-800q-33 0-56.5 23.5T160-720q0 33 23.5 56.5T240-640Zm240 180q8 0 14-6t6-14q0-8-6-14t-14-6q-8 0-14 6t-6 14q0 8 6 14t14 6ZM240-160q33 0 56.5-23.5T320-240q0-33-23.5-56.5T240-320q-33 0-56.5 23.5T160-240q0 33 23.5 56.5T240-160Z"/></svg>`; 

icons["copy"] = `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M360-240q-33 0-56.5-23.5T280-320v-480q0-33 23.5-56.5T360-880h360q33 0 56.5 23.5T800-800v480q0 33-23.5 56.5T720-240H360Zm0-80h360v-480H360v480ZM200-80q-33 0-56.5-23.5T120-160v-560h80v560h440v80H200Zm160-240v-480 480Z"/></svg>`

icons["paste"] = `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h167q11-35 43-57.5t70-22.5q40 0 71.5 22.5T594-840h166q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560h-80v120H280v-120h-80v560Zm280-560q17 0 28.5-11.5T520-800q0-17-11.5-28.5T480-840q-17 0-28.5 11.5T440-800q0 17 11.5 28.5T480-760Z"/></svg>`

icons["pasteplain"] = `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M280-280h280v-80H280v80Zm0-160h400v-80H280v80Zm0-160h400v-80H280v80Zm-80 480q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560H200v560Zm0-560v560-560Z"/></svg>`

icons["pasteword"] = `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M320-240h320v-80H320v80Zm0-160h320v-80H320v80ZM240-80q-33 0-56.5-23.5T160-160v-640q0-33 23.5-56.5T240-880h320l240 240v480q0 33-23.5 56.5T720-80H240Zm280-520v-200H240v640h480v-440H520ZM240-800v200-200 640-640Z"/></svg>`

icons["undo"] = `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M280-200v-80h284q63 0 109.5-40T720-420q0-60-46.5-100T564-560H312l104 104-56 56-200-200 200-200 56 56-104 104h252q97 0 166.5 63T800-420q0 94-69.5 157T564-200H280Z"/></svg>`;

icons["redo"] = `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M396-200q-97 0-166.5-63T160-420q0-94 69.5-157T396-640h252L544-744l56-56 200 200-200 200-56-56 104-104H396q-63 0-109.5 40T240-420q0 60 46.5 100T396-280h284v80H396Z"/></svg>`

icons["unlink"] = `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="m770-302-60-62q40-11 65-42.5t25-73.5q0-50-35-85t-85-35H520v-80h160q83 0 141.5 58.5T880-480q0 57-29.5 105T770-302ZM634-440l-80-80h86v80h-6ZM792-56 56-792l56-56 736 736-56 56ZM440-280H280q-83 0-141.5-58.5T80-480q0-69 42-123t108-71l74 74h-24q-50 0-85 35t-35 85q0 50 35 85t85 35h160v80ZM320-440v-80h65l79 80H320Z"/></svg>`

icons["anchor"] = `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M480-80q-61 0-125-22t-116-60q-52-38-85.5-89T120-360v-120l160 120-62 62q29 51 92 88t130 47v-357H320v-80h120v-47q-35-13-57.5-43.5T360-760q0-50 35-85t85-35q50 0 85 35t35 85q0 39-22.5 69.5T520-647v47h120v80H520v357q67-10 130-47t92-88l-62-62 160-120v120q0 58-33.5 109T721-162q-52 38-116 60T480-80Zm0-640q17 0 28.5-11.5T520-760q0-17-11.5-28.5T480-800q-17 0-28.5 11.5T440-760q0 17 11.5 28.5T480-720Z"/></svg>`

icons["horizontal-line"] = `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M120-200v-240h720v240H120Zm0-320v-80h720v80H120Zm0-160v-80h720v80H120Z"/></svg>`

icons["special-character"] = `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M120-120v-120h200q-84-45-132-125t-48-175q0-142 99-241t241-99q142 0 241 99t99 241q0 95-48 175T640-240h200v120H520v-204q78-14 129-75t51-141q0-92-64-156t-156-64q-92 0-156 64t-64 156q0 80 51 141t129 75v204H120Z"/></svg>`

icons["source"] = `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M344-336 200-480l144-144 56 57-87 87 87 87-56 57Zm272 0-56-57 87-87-87-87 56-57 144 144-144 144ZM200-120q-33 0-56.5-23.5T120-200v-160h80v160h160v80H200Zm400 0v-80h160v-160h80v160q0 33-23.5 56.5T760-120H600ZM120-600v-160q0-33 23.5-56.5T200-840h160v80H200v160h-80Zm640 0v-160H600v-80h160q33 0 56.5 23.5T840-760v160h-80Z"/></svg>`

icons["strike-mode"] = `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M486-160q-76 0-135-45t-85-123l88-38q14 48 48.5 79t85.5 31q42 0 76-20t34-64q0-18-7-33t-19-27h112q5 14 7.5 28.5T694-340q0 86-61.5 133T486-160ZM80-480v-80h800v80H80Zm402-326q66 0 115.5 32.5T674-674l-88 39q-9-29-33.5-52T484-710q-41 0-68 18.5T386-640h-96q2-69 54.5-117.5T482-806Z"/></svg>`

icons["help"] = `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M478-240q21 0 35.5-14.5T528-290q0-21-14.5-35.5T478-340q-21 0-35.5 14.5T428-290q0 21 14.5 35.5T478-240Zm-36-154h74q0-33 7.5-52t42.5-52q26-26 41-49.5t15-56.5q0-56-41-86t-97-30q-57 0-92.5 30T342-618l66 26q5-18 22.5-39t53.5-21q32 0 48 17.5t16 38.5q0 20-12 37.5T506-526q-44 39-54 59t-10 73Zm38 314q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>`

icons["maximise"] = `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M800-600v-120H680v-80h120q33 0 56.5 23.5T880-720v120h-80Zm-720 0v-120q0-33 23.5-56.5T160-800h120v80H160v120H80Zm600 440v-80h120v-120h80v120q0 33-23.5 56.5T800-160H680Zm-520 0q-33 0-56.5-23.5T80-240v-120h80v120h120v80H160Zm80-160v-320h480v320H240Zm80-80h320v-160H320v160Zm0 0v-160 160Z"/></svg>`

icons["spell-checker"] = `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M564-80 394-250l56-56 114 114 226-226 56 56L564-80ZM120-320l194-520h94l194 520h-92l-46-132H254l-46 132h-88Zm162-208h156l-76-216h-4l-76 216Z"/></svg>`




const Editor = ({ name, value, onChange = () => {}, tooltipText = "Info", onSave = () => {} }) => {
  const quill = useRef();
  const [anchorDialogOpen, setAnchorDialogOpen] = useState(false);
  const [specialCharDialogOpen, setSpecialCharDialogOpen] = useState(false);
  const [sourceDialogOpen, setSourceDialogOpen] = useState(false);
  const [isMaximized, setIsMaximized] = useState(false);

  const imageHandler = useCallback(() => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = () => {
      const file = input.files[0];
      const reader = new FileReader();

      reader.onload = () => {
        const imageUrl = reader.result;
        const quillEditor = quill.current.getEditor();
        const range = quillEditor.getSelection(true);
        quillEditor.insertEmbed(range.index, "image", imageUrl, "user");
      };

      reader.readAsDataURL(file);
    };
  }, []);

  const handlePastePlainText = async () => {
    const text = await navigator.clipboard.readText();
    const quillEditor = quill.current.getEditor();
    quillEditor.clipboard.dangerouslyPasteHTML('<p>' + text + '</p>');
  };

  const handleAnchorSave = (anchorName) => {
    const quillEditor = quill.current.getEditor();
    const range = quillEditor.getSelection(true);
    quillEditor.insertEmbed(range.index, "anchor", anchorName);
    setAnchorDialogOpen(false);
  };

  const handleSpecialCharSelect = (char) => {
    const quillEditor = quill.current.getEditor();
    const range = quillEditor.getSelection(true);
    quillEditor.insertText(range.index, char);
    setSpecialCharDialogOpen(false);
  };

  const handleSpellCheck = () => {
    const quillEditor = quill.current.getEditor();
    const text = quillEditor.getText();
    // Implement spell-checking logic here, e.g., using an external spell-checker API.
    console.log("Spell-checking text:", text);
    // Highlight spelling errors in the editor.
  };

 

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ font: [] }],
          ["bold", "italic", "underline","strike", "blockquote"],
          [{ color: [] },{ background: [] }],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          ["link","unlink", "image", "clean"],
          ["cut", "copy", "paste", "pasteplain", "pasteword", "undo", "redo","anchor","horizontal-line", "special-character"],
          ["source", "spell-checker"]
        ],
        handlers: {
          image: imageHandler,
          anchor: () => setAnchorDialogOpen(true),
          cut: async () => {
            const quillEditor = quill.current.getEditor();
            const range = quillEditor.getSelection();
            if (range) {
              const text = quillEditor.getText(range.index, range.length);
              await navigator.clipboard.writeText(text);
              quillEditor.deleteText(range.index, range.length);
            }
          },
          copy: async () => {
            const quillEditor = quill.current.getEditor();
            const range = quillEditor.getSelection();
            if (range) {
              const text = quillEditor.getText(range.index, range.length);
              await navigator.clipboard.writeText(text);
            }
          },
          paste: async () => {
            const text = await navigator.clipboard.readText();
            const quillEditor = quill.current.getEditor();
            const range = quillEditor.getSelection();
            quillEditor.insertText(range.index, text);
          },
          pasteplain: handlePastePlainText,
          pasteword: async () => {
            const text = await navigator.clipboard.readText();
            const quillEditor = quill.current.getEditor();
            quillEditor.clipboard.dangerouslyPasteHTML('<p>' + text + '</p>');
          },
          undo: () => {
            const quillEditor = quill.current.getEditor();
            quillEditor.history.undo();
          },
          redo: () => {
            const quillEditor = quill.current.getEditor();
            quillEditor.history.redo();
          },
          unlink: () => {
            const quillEditor = quill.current.getEditor();
            const range = quillEditor.getSelection();
            if (range) {
              quillEditor.formatText(range.index, range.length, 'link', false);
            }
          },
          "spell-checker": handleSpellCheck,
          "special-character": () => setSpecialCharDialogOpen(true) ,
          source: () => setSourceDialogOpen(true),
          
        },
      
      },
      clipboard: {
        matchVisual: true,
      },
    }),
    [imageHandler]
  );

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "unlink",
    "image",
    "color",
    "background",
    "clean",
    "anchor"
  ];

  const quillValue = value?.startsWith("<") ? value : `<p>${value}</p>`;

  const editorClass = isMaximized ? 'editor-maximized' : 'editor-normal';

  const updateHeaderStyles = (html) => {
    let updatedHtml = html.replace(/<h1|<h2|<h3|<h4|<h5|<h6/g, (match) => {
      switch (match) {
        case "<h1":
          return `${match} style="font-size: 70px; font-weight: 800"`;
        case "<h2":
          return `${match} style="font-size: 60px; font-weight: 800"`;
        case "<h3":
          return `${match} style="font-size: 50px; font-weight: Normal"`;
        case "<h4":
          return `${match} style="font-size: 40px; font-weight: Normal"`;
        case "<h5":
          return `${match} style="font-size: 30px; font-weight: Normal"`;
        case "<h6":
          return `${match} style="font-size: 20px; font-weight: Normal"`;
        default:
          return match;
      }
    });
    return updatedHtml;
  };

  const handleQuillChange = (content, delta, source, editor) => {
    const updatedContent = updateHeaderStyles(content);
    onChange(updatedContent);
  };

  const handleSave = () => {
    const quillEditor = quill.current.getEditor();
    const content = quillEditor.root.innerHTML;
    onSave(content);
  };

  const handleSourceSave = (sourceCode) => {
    const quillEditor = quill.current.getEditor();
    quillEditor.root.innerHTML = sourceCode;
    onChange(sourceCode);
  };

  useEffect(() => {
    const applyResponsiveStyles = () => {
      const quillEditor = quill.current.getEditor();
      let doc = quillEditor.root.innerHTML;
      doc = updateHeaderStyles(doc);
      quillEditor.root.innerHTML = doc;
    };

    applyResponsiveStyles();
    window.addEventListener("resize", applyResponsiveStyles);

    return () => window.removeEventListener("resize", applyResponsiveStyles);
  }, []);

  return (
    <div className={`editor-container ${editorClass}`}>
      <Tooltip title={tooltipText} className="editor-tooltip">
        <div className="quill-guid">
          
        </div>
      </Tooltip>
     
      <div className="editor-content">
        <ReactQuill
          ref={(el) => (quill.current = el)}
          theme="snow"
          name={name}
          value={quillValue}
          onChange={handleQuillChange}
          formats={formats}
          modules={modules}
        />
        
      </div>
      <AnchorDialog open={anchorDialogOpen} onClose={() => setAnchorDialogOpen(false)} onSave={handleAnchorSave} />
      <SpecialCharacterDialog
        open={specialCharDialogOpen}
        onClose={() => setSpecialCharDialogOpen(false)}
        onSelect={handleSpecialCharSelect}
      />
       <SourceDialog
        open={sourceDialogOpen}
        onClose={() => setSourceDialogOpen(false)}
        content={quill.current?.getEditor().root.innerHTML || ""}
        onSave={handleSourceSave}
      />
    </div>
  );
};

export default Editor;

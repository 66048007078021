import React, { useState } from 'react';
import { FormGroup, Label, Input } from 'reactstrap';

const FilterForm = ({ userRole,userRoles,setUserRole, gender, setGender }) => {

    return (
        <>
            <FormGroup>
                <Label for="category">Role/Category</Label>
                <Input 
                    type="select" 
                    name="category" 
                    id="category" 
                    className="form-control digits" 
                    value={userRole}
                    onChange={(e) => setUserRole(e.target.value)} 
                    required
                >
                    <option value="" selected  disabled>Select Role/Category</option>
                    {userRoles && userRoles.map((item,index)=>(
                        <option key={index}>{item.role}</option>))}
                    
                </Input>
            </FormGroup>
            <FormGroup>
                <Label for="gender">Gender</Label>
                <Input 
                    type="select" 
                    name="gender" 
                    id='gender'  
                    className="form-control digits" 
                    value={gender}
                    onChange={(e) => setGender(e.target.value)} 
                    required
                >
                    <option value="" selected  disabled >Select Gender</option>
                    <option >Female</option>
                    <option >Male</option>
                    <option >Other</option>
                </Input>
            </FormGroup>
        </>
    );
};

export default FilterForm;

import React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';

const IdCardFilter = ({ regionalPartner, setRegionalPartner, paymentStatus, setPaymentStatus }) => {
    return (
        <>
            <FormGroup>
                <Label for="orderStatus">Regional Partners</Label>
                <Input 
                    type="select" 
                    name="regionalPartner" 
                    id="regionalPartner" 
                    className="form-control digits" 
                    value={regionalPartner}
                    onChange={(e) => setRegionalPartner(e.target.value)} 
                    required
                >
                    <option value="" selected disabled >Select Regional Partners</option>
                    <option >Charles williams</option>
                    <option >Soumya singh</option>
                   
                   
                </Input>
            </FormGroup>
            <FormGroup>
                <Label for="paymentStatus">Payment Status</Label>
                <Input 
                    type="select" 
                    name="paymentStatus" 
                    id='paymentStatus'  
                    className="form-control digits" 
                    value={paymentStatus}
                    onChange={(e) => setPaymentStatus(e.target.value)} 
                    required
                >
                    <option value="" selected disabled >Select payment Status</option>
                    <option  value="Paid" >Paid</option>
                    <option  value="Unpaid" >Unpaid</option>
                   
                </Input>
            </FormGroup>
        </>
    );
};

export default IdCardFilter;

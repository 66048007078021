import React, { useState, useRef } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField } from '@mui/material';

const SourceDialog = ({ open, onClose, content, onSave }) => {
  const [sourceCode, setSourceCode] = useState(content);
  const sourceInputRef = useRef();

  const handleSave = () => {
    onSave(sourceCode);
    onClose();
  };

  const handleChange = (event) => {
    setSourceCode(event.target.value);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle>Source Code</DialogTitle>
      <DialogContent>
        <TextField
          multiline
          rows={10}
          fullWidth
          variant="outlined"
          value={sourceCode}
          onChange={handleChange}
          inputRef={sourceInputRef}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} color="primary">Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default SourceDialog;

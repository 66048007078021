import React, { useCallback, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { Categorydummydata, CategorytableColumns } from '../common/mockData';
import { Btn, H4 } from '../../../../AbstractElements';
import AddMarketCategory from './AddMarketCategory';
import { API_URL } from '../config/api_urls';
import { DELETE, get, patch, post } from '../config';
import { toast } from 'react-toastify';

function MarketCategory() {
  const [data, setData] = useState([]);
  const [toggleDelet, setToggleDelet] = useState(false);
  const [marketCategoryModal,setMarketCategoryModal] = useState(false);
  const [editMarketCategory,setEditMarketCategory] = useState();


  useEffect(() => {
    fetchMarketCategory();
  }, [])
  
  const fetchMarketCategory = useCallback(() => {
    get(API_URL.MARKET_CATEGORY.GET_MARKET_CATEGORY)
      .then((response) => {
        if (response?.data.length >= 0)
          setData(response.data)
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);



  const marketCategoryToggle =()=>{
    setMarketCategoryModal(!marketCategoryModal);
  }

  const editmodalToggle =(row)=>{
    setMarketCategoryModal(!marketCategoryModal);
    setEditMarketCategory(row);
  }

       
  const handleSave = async (formData) => {
		if (formData?.id) {
			patch(API_URL.MARKET_CATEGORY.PATCH_MARKET_CATEGORY(formData?.id), formData)
				.then((response) => {
					toast.success("Updated successfully");
          setMarketCategoryModal(!marketCategoryModal);
          setEditMarketCategory(null);
          fetchMarketCategory();
				})
				.catch((error) => {
					console.error("Error updating data:", error);
					toast.error("Oops! something went wrong");
				});
		}else{
      try {
        await post(API_URL.MARKET_CATEGORY.POST_MARKET_CATEGORY, formData);
        toast.success("Market Category successfully");
        setMarketCategoryModal(!marketCategoryModal);
        setEditMarketCategory(null);
        fetchMarketCategory();
      } catch (error) {
        console.error("Error saving data:", error);
        toast.error("Oops! something went wrong");
      }
    }
		
	};




  
  const handleCancel = () => {
    setMarketCategoryModal(!marketCategoryModal);
    setEditMarketCategory(null);
  };



  

  const handleDeletedata = (row)=>{
    if(row?.id){
     DELETE(API_URL.MARKET_CATEGORY.DELETE_MARKET_CATEGORY(row?.id))
     .then((response) => {
       toast.success("Deleted successfully");
       fetchMarketCategory();
     })
     .catch((error) => {
       toast.error("Oops! something went wrong");
     });
    }
   }


  return (
    <>
       <div className={`d-flex align-items-center justify-content-between  p-4`}>
                    <H4 attrH4={{ className: 'text-muted m-0' }}>MarketPlace Category</H4>
                    <Btn attrBtn={{ color: 'primary', onClick: marketCategoryToggle}}>+ Add New Category</Btn>
                    
                      <AddMarketCategory
                      marketCategoryData={editMarketCategory}
                      isOpen={marketCategoryModal}
                      toggle={marketCategoryToggle}
                      title="Market Category"
                      onSave={handleSave}
                      onCancel={handleCancel}
                      />
                   
                </div>
    <div>
       <DataTable
                data={data}
                columns={CategorytableColumns(editmodalToggle,handleDeletedata)}
                striped={true}
                center={true}
                pagination
                selectableRows={false}
                clearSelectedRows={toggleDelet}
            />
    </div>
    </>
   
  )
}

export default MarketCategory
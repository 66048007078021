import React, { useCallback, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { userattributesdummydata, userattributestableColumns } from '../common/mockData';
import { Btn, H4 } from '../../../../AbstractElements';
import NewUserAttribute from './NewUserAttribute';
import { DELETE, get, patch, post } from '../config';
import { API_URL } from '../config/api_urls';
import { toast } from 'react-toastify';
import { constructFormData } from '../config/imageHandle';

function UserAttributes() {
  const [data, setData] = useState([]);
  const [users,setUsers] = useState([])
 console.log("users ", users);
  const [toggleDelet, setToggleDelet] = useState(false);
  const [userAttributeModal,setUserAttributeModal] = useState(false);
  const [editUserAttribute, setEditUserAttribute] = useState();
  

  useEffect(()=>{
    fetchUserAttributes();
    fetchUsers();
  },[])


  const fetchUserAttributes = useCallback(() => { 
    get(API_URL.USER_ATTRIBUTES.GET_USER_ATTRIBUTES)
      .then((response) => {
        if (response?.data.length > 0) {
         setData(response?.data)
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);
  
  const fetchUsers = useCallback(() => { 
    get(API_URL.USERS.GET_USERS)
      .then((response) => {
        if (response?.data.length > 0) {
         setUsers(response?.data)
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);


 const userattributeToggle = ()=>{
  setUserAttributeModal(!userAttributeModal);
 }

 const editmodalToggle = (row)=>{
  setEditUserAttribute(row)
  setUserAttributeModal(!userAttributeModal);
 }
 
const handleCancel = () => {
  setUserAttributeModal(!userAttributeModal);
  setEditUserAttribute(null)
};

const handleSave = async (formData) => {
  const values = {
    id: formData?.id || '',
    user_uuid: formData?.user_name?.value,
    attribute_id: formData?.attribute_name?.value,
    value : formData?.value
  }
 
  if (formData?.id) {
    patch(API_URL.USER_ATTRIBUTES.PATCH_USER_ATTRIBUTES(formData?.id), values)
      .then((response) => {
        toast.success("Updated successfully");
        setUserAttributeModal(!userAttributeModal);
        setEditUserAttribute(null)
        fetchUserAttributes();
      })
      .catch((error) => {
        console.error("Error updating data:", error);
        toast.error("Oops! something went wrong");
      });
  }else{
    try {
      await post(API_URL.USER_ATTRIBUTES.POST_USER_ATTRIBUTES, values);
      toast.success("User Attribute created successfully");
      setUserAttributeModal(!userAttributeModal);
      setEditUserAttribute(null)
      fetchUserAttributes();
    } catch (error) {
      console.error("Error saving data:", error);
      toast.error("Oops! something went wrong");
    }
  }
  
};




const handleDeletedata = (row) => {
  if (row?.id) {
    DELETE(API_URL.USER_ATTRIBUTES.DELETE_USER_ATTRIBUTES(row?.id))
      .then((response) => {
        toast.success("Deleted successfully");
        fetchUserAttributes();
      })
      .catch((error) => {
        toast.error("Oops! something went wrong");
      });
  }
}




  return (
    <>
       <div className={`d-flex align-items-center justify-content-between  p-4`}>
                    <H4 attrH4={{ className: 'text-muted m-0' }}>All User Attributes </H4>
                    <Btn attrBtn={{ color: 'primary', onClick:userattributeToggle}}>+ New User Attribute</Btn>
                 
                      <NewUserAttribute
                      editUserAttributeData={editUserAttribute}
                      isOpen={userAttributeModal}
                      toggle={userattributeToggle}
                      title="User Attributes"
                      onSave={handleSave}
                      onCancel={handleCancel}
                      />
                    
                </div>
    <div>
       <DataTable
                data={data}
                columns={userattributestableColumns(editmodalToggle,handleDeletedata)}
                striped={true}
                center={true}
                pagination
                selectableRows={false}
                clearSelectedRows={toggleDelet}
            />
    </div>
    </>
   
  )
}

export default UserAttributes
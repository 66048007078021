import React, { useCallback, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { userroledummytabledata, userroletableColumns } from '../common/mockData';
import { Btn, H4 } from '../../../../AbstractElements';
import AddUserRole from './AddUserRole';
import { DELETE, get, patch, post } from '../config';
import { API_URL } from '../config/api_urls';
import { toast } from 'react-toastify';
import { constructFormData } from '../config/imageHandle';

function UserRole() { 
	const [data, setData] = useState([]);
	const [selectedRows, setSelectedRows] = useState([]);
	const [toggleDelet, setToggleDelet] = useState(false);
	const [roleModal, setRoleModal] = useState(false);
	const [editUserRoleData, setEditUserRoleData] = useState();

	const handleRowSelected = useCallback(state => {
		setSelectedRows(state.selectedRows);
	}, []);

	useEffect(() => {
		fetchUserRoles();
	}, [])

	const fetchUserRoles = useCallback(() => {
		get(API_URL.USER_ROLE.USER_ROLE_GET)
			.then((response) => {
				if (response?.data.length >= 0)
					setData(response.data)
			})
			.catch((error) => {
				console.error("Error:", error);
			});
	}, []);

	const roleToggle = () => {
		setRoleModal(!roleModal);
	};

	const editmodalToggle = (row) => {
		setRoleModal(!roleModal);
		setEditUserRoleData(row);
	};

	const handleCancel = () => {
		setEditUserRoleData(null);
		setRoleModal(!roleModal);
	};

	
	const handleSave = async (formData) => {
		const values = {
			id: formData?.id || '',
			role: formData?.role,
			// privileges_ids: formData?.privileges?.map(i => i.value)
		}
		const data = constructFormData(values);
		if (formData?.id) {
			patch(API_URL.USER_ROLE.USER_ROLE_PATCH(formData?.id), data)
				.then((response) => {
					toast.success("Updated successfully");
					setEditUserRoleData(null);
					setRoleModal(!roleModal);
					fetchUserRoles();
				})
				.catch((error) => {
					console.error("Error updating data:", error);
					toast.error("Oops! something went wrong");
				});
		}else{
			try {
				await post(API_URL.USER_ROLE.USER_ROLE_POST, data);
				toast.success("User Role created successfully");
				setEditUserRoleData(null);
				setRoleModal(!roleModal);
				fetchUserRoles();
			} catch (error) {
				console.error("Error saving data:", error);
				toast.error("Oops! something went wrong");
			}
		}
		
	};


	const handleDeletedata = (row) => {
		if (row?.id) {
			DELETE(API_URL.USER_ROLE.USER_ROLE_DELETE(row?.id))
				.then((response) => {
					toast.success("Deleted successfully");
					fetchUserRoles();
				})
				.catch((error) => {
					toast.error("Oops! something went wrong");
				});
		}
	}



	

	return (
		<>
			<div className={`d-flex align-items-center justify-content-between  p-4`}>
				<H4 attrH4={{ className: 'text-muted m-0' }}>User Role</H4>
				<Btn attrBtn={{ color: 'primary', onClick: roleToggle }}>+ Add New Role</Btn>

				<AddUserRole
					isOpen={roleModal}
					toggle={roleToggle}
					title="User Role"
					editroleData={editUserRoleData}
					onSave={handleSave}
					onCancel={handleCancel}
				/>

			</div>
			<div>
				<DataTable
					data={data}
					columns={userroletableColumns(editmodalToggle, handleDeletedata)}
					striped={true}
					center={true}
					pagination
					selectableRows={false}
					onSelectedRowsChange={handleRowSelected}
					clearSelectedRows={toggleDelet}
				/>
			</div>
		</>
	)
}

export default UserRole;

import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { useLocation, useNavigate } from 'react-router';
import FormCommon from '../common/FormCommon';
import { get, patch, post } from '../config';
import { API_URL } from '../config/api_urls';
import { toast } from 'react-toastify';
import { constructFormData } from '../config/imageHandle';

const Newidcard = () => {
    const navigate = useNavigate();
    const [users,setUsers] = useState([]);
    const [userRoles,setUserRoles] = useState([]);
    const [formDataConfig, setFormDataConfig] = useState({
        initialState: {
          id: null,
          user_uuid :"",
          name:"",
          image:"",
          user_role_id:"",
          email:"",
          dob:"",
          phone: "",
          payment_status :"",
        },
        fields: [
          {
            name: "user_uuid",
            label: "User",
            type: "select",
            options:[],
            required: true,
          },
          {
            name: "name",
            label: "FullName",
            type: "text",
            placeholder: "Enter FullName",
            required: true,
          },
            {
                name: "image",
                label: "Image",
                type: "file",
                required: true,
            },
          
          {
            name: "user_role_id",
            label: "user Role",
            type: "select",
            options:[],
            required: true,
          },
          {
            name: "email",
            label: "Email",
            type: "email",
            placeholder: "Enter Email ID",
            required: true,
          },
          {
            name: "dob",
            label: "DOB",
            type: "date",
            required: true,
          },
          {
            name: "phone",
            label: "Phone Number",
            type: "tel",
            placeholder:"Enter Phone Number",
            required: true,
          },
          {
            name: "payment_status",
            label: "Payment status",
            type: "text",
            placeholder: "status",
            required: true,
          }
        ],
      });
    


      useEffect(()=>{
        fetchUserRoles();
        fetchUsers();
      },[])


      //users dropdown
      const fetchUsers = useCallback(async () => {
        try {
          const response = await get(API_URL.USERS.GET_USERS);
          const users_ids = response?.data?.map((i) => ({
            label: i.name,
            value: i.uuid
          }));
          users_ids.unshift({ label: "Select User", value: "" });
          if (users_ids.length > 0) {
            setUsers(users_ids)
            setFormDataConfig((prevConfig) => ({
              ...prevConfig,
              fields: prevConfig.fields.map((field) =>
                field.name === "user_uuid" ? { ...field, options: users_ids } : field
              ),
            }));
          }
    
        } catch (error) {
          console.error("Error:", error);
        }
      }, []);



      //user role dropdown
      const fetchUserRoles = useCallback(async () => {
        try {
          const response = await get(API_URL.USER_ROLE.USER_ROLE_GET);
          const userRole_ids = response?.data?.map((i) => ({
            label: i.role,
            value: i.id
          }));
          userRole_ids.unshift({ label: "Select User Role", value: "" });
          if (userRole_ids.length > 0) {
            setUserRoles(userRole_ids)
            setFormDataConfig((prevConfig) => ({
              ...prevConfig,
              fields: prevConfig.fields.map((field) =>
                field.name === "user_role_id" ? { ...field, options: userRole_ids } : field
              ),
            }));
          }
    
        } catch (error) {
          console.error("Error:", error);
        }
      }, []);


      const handleNavigateToIdCards = () => {
        navigate(`${process.env.PUBLIC_URL}/id-card/:layout`)
      }
      
      const onSave = (formData) => {
        const values = {
          
          image : formData?.image,
          name : formData?.name,
          user_uuid : formData?.user_uuid,
          user_role_id : formData?.user_role_id,
          email : formData?.email,
          dob : formData?.dob,
          phone : formData?.phone,
          payment_status : formData?.payment_status
         
        }
        const data = constructFormData(values, ['image']);

          post(API_URL.IDCARDS.POST_IDCARD, data)
            .then((response) => {
              toast.success("Membership created successfully");
              handleNavigateToIdCards();
            })
            .catch((error) => {
              console.error("Error saving data:", error);
              toast.error("Oops! something went wrong, try again");
            });
       
      };

    return (
        <div>
        <FormCommon
        title={"Add Membership"}
        formDataConfig={formDataConfig}
        onSave={onSave}
        
      />
    </div>
       
    );
};

export default Newidcard;
import React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';

const ApplicationFilter = ({ state, setState}) => {
    return (
        <>
            <FormGroup>
                <Label for="state">select state</Label>
                <Input 
                    type="select" 
                    name="state" 
                    id="state" 
                    className="form-control digits" 
                    value={state}
                    onChange={(e) => setState(e.target.value)} 
                    required
                >
                    <option value="" disabled>Select State</option>
                    <option>Jiulong</option>
                    <option>Curitiba</option>
                    <option>Marseille</option>
                    <option>Blobo</option>
                    <option>Bunol</option>
                    <option>Canillo</option>
                   
                </Input>
            </FormGroup>
           
        </>
    );
};

export default ApplicationFilter;
export const API_URL = {
    PRIVILEGES:{
        PRIVILEGES_GET :"/api/privileges/",
        PRIVILEGES_POST : "/api/privileges/"
    },
    USER_ROLE:{
        USER_ROLE_GET :"/api/user-roles/",
        USER_ROLE_POST :"/api/user-roles/",
        USER_ROLE_PATCH:id=>`/api/user-roles/${id}/`,
        USER_ROLE_DELETE:id=>`/api/user-roles/${id}/`
    },
    USER_CATEGORY:{
        USER_CATEGORY_GET:"/api/user/category/",
        USER_CATEGORY_POST:"/api/user/category/",
        USER_CATEGORY_PATCH:id=>`/api/user/category/${id}/`,
        USER_CATEGORY_DELETE:id=>`/api/user/category/${id}/`
    },
    COUNTRIES:{
        COUNTRIES_GET:"/api/countries/",
        COUNTRIES_POST:"/api/countries/"
    },
    STATES:{
        STATES_GET:"/api/states/",
        STATES_POST:"/api/states/"
    },
    CITIES:{
        GET_CITIES :"/api/cities/",
    },
    HEAR_ABOUT_US:{
        HEAR_ABOUT_US_GET:"/api/user/hearaboutus/",
        HEAR_ABOUT_US_POST:"/api/user/hearaboutus/",
        HEAR_ABOUT_US_PATCH:id=>`/api/user/hearaboutus/${id}/`,
        HEAR_ABOUT_US_DELETE:id=>`/api/user/hearaboutus/${id}/`
    },
    USERS: {
        GET_USERS: "/api/users/",
        USERS_PUT: "/api/users/",
        USERS_PATCH:uuid=>`/api/users/${uuid}/`,
        USERS_POST:"/api/users/",
        USERS_DELETE:uuid=>`/api/users/${uuid}/`
    },
    REGIONAL_PARTNERS:{
        GET_REGIONALS :"/api/regionals/",
        POST_REGIONALS :"/api/regionals/",
        PATCH_REGIONALS :id=>`/api/regional/${id}/`,
        DELETE_REGIONALS :id=>`/api/regional/${id}/`
    },



    //EVENTS------------------------------
    EVENTS:{
        GET_EVENTS:"/api/events/",
        POST_EVENT:"/api/events/",
        PATCH_EVENT:id=>`/api/events/${id}/`,
        DELETE_EVENT:id=>`/api/events/${id}/`
    },
    EVENT_CATEGORY:{
        GET_EVENT_CATEGORY:"/api/category/",
        POST_EVENT_CATEGORY:"/api/category/",
        PATCH_EVENT_CATEGORY:id=>`/api/category/${id}/`,
        DELETE_EVENT_CATEGORY:id=>`/api/category/${id}/`
    },
    EVENT_TYPES:{
        GET_EVENT_TYPES : "/api/types/",
        POST_EVENT_TYPES:"/api/types/",
        PATCH_EVENT_TYPES:id=>`/api/types/${id}/`,
        DELETE_EVENT_TYPES:id=>`/api/types/${id}/`,
    },
    EVENT_APPLICATION :{
        GET_EVENT_APPLICATION : "/api/job/applications/",
        POST_EVENT_APPLICATION: "/api/job/applications/",
        PATCH_EVENT_APPLICATION :id=>`/api/job/applications/${id}/`,
        DELETE_EVENT_APPLICATION:id=>`/api/job/applications/${id}/`
    },


//  MARKET/PRODUCT ------------------------------------

    MARKET_CATEGORY:{
        GET_MARKET_CATEGORY:"/api/product_categories/",
        POST_MARKET_CATEGORY:"/api/product_categories/",
        PATCH_MARKET_CATEGORY:id=>`/api/product_categories/${id}/`,
        DELETE_MARKET_CATEGORY:id=>`/api/product_categories/${id}/`,
    },
    MARKET_SIZES:{
        GET_MARKET_SIZES:"/api/product_types/",
        POST_MARKET_SIZE:"/api/product_types/",
        PATCH_MARKET_SIZE:id=>`/api/product_types/${id}/`,
        DELETE_MARKET_SIZE:id=>`/api/product_types/${id}/`,
    },
    MARKET_PRPDUCTS:{
        GET_MARKET_PRPDUCTS :"/api/productform/",
        POST_MARKET_PRPDUCTS :"/api/productform/create/",
        PATCH_MARKET_PRPDUCTS : id=>`/api/productform/${id}/`,
        DELETE_MARKET_PRPDUCTS: id=>`/api/productform/${id}/`,
    },

   
    // BUSINESS SECTION/ ORDERS---------------------------
    ORDERS:{
        GET_ORDERS :"/api/orders/",
        POST_ORDER : "/api/create-order/",
        PATCH_ORDER :id=> `/api/orders/${id}/`,
        DELETE_ORDER : id=> `/api/orders/${id}/`
    },




    // ID CARDS--------------------------------
    IDCARDS:{
        GET_IDCARDS :"/api/idcards/",
        POST_IDCARD :"/api/membership/",
        PATCH_IDCARD :id=>`/api/idcards/${id}/`,
        DELETE_IDCARD :id=>`/api/idcards/${id}/`
    },
   


    // ATTRIBUTES --------------------------
    ATTRIBUTES:{
        GET_ATTRIBUTES : "/api/attributes/get/",
        POST_ATTRIBUTES : "/api/attributes/",
        PATCH_ATTRIBUTES :id=>`/api/attributes/${id}/`,
        DELETE_ATTRIBUTES :id=>`/api/attributes/${id}/`
    },
    USER_ATTRIBUTES: {
        GET_USER_ATTRIBUTES : "/api/profile-attributes/",
        POST_USER_ATTRIBUTES :"/api/profile-attributes/",
        PATCH_USER_ATTRIBUTES :id=>`/api/profile-attributes/${id}/`,
        DELETE_USER_ATTRIBUTES :id=>`/api/profile-attributes/${id}/`,
    },


    // PREFERENCES---------------------------------
    PREFERENCES:{
        GET_PREFERENCES:"/api/preferences/",
        POST_PREFERENCES:"/api/preferences/",
        PATCH_PREFERENCES:id=>`/api/preferences/${id}/`,
        DELETE_PREFERENCES:id=>`/api/preferences/${id}/`
    },
    USER_PREFERENCES:{
        GET_USER_PREFERENCES: "/api/profile-preferences/",
        POST_USER_PREFERENCES: "/api/profile-preferences/",
        PATCH_USER_PREFERENCES:id=>`/api/profile-preferences/${id}/`,
        DELETE_USER_PREFERENCES:id=>`/api/profile-preferences/${id}/`
    },




    //SETTINGS--------------------------------
    APP_VERSION :{
        GET_APP_VERSION : "/api/appversion/",
        POST_APP_VERSION : "/api/appversion/",
        PATCH_APP_VERSION : id=>`/api/appversion/${id}/`,
        DELETE_APP_VERSION : id=>`/api/appversion/${id}/`
    },

    PAYMENT_TERMS:{
        GET_PAYMENT_TERMS : "/api/payment-terms/",
        POST_PAYMENT_TERMS : "/api/payment-terms/",
        PATCH_PAYMENT_TERMS :id=>`/api/payment-terms/${id}/`,
        DELETE_PAYMENT_TERMS:id=>`/api/payment-terms/${id}/`
    },

    PAYMENT_SETTINGS:{
        GET_PAYMENT_SETTINGS : "/api/payment-settings/",
        POST_PAYMENT_SETTINGS : "/api/payment-settings/",
        PATCH_PAYMENT_SETTINGS :id=>`/api/payment-settings/${id}/`,
        DELETE_PAYMENT_SETTINGS :id=>`/api/payment-settings/${id}/`
    },

    TERMS_CONDITIONS:{
        GET_TERMS_CONDITIONS : "/api/terms-and-conditions/",
        POST_TERMS_CONDITIONS : "/api/terms-and-conditions/",
        PATCH_TERMS_CONDITIONS :id=>`/api/terms-and-conditions/${id}/`,
        DELETE_TERMS_CONDITIONS:id=>`/api/terms-and-conditions/${id}/`
    },


    IDCARD_SETTINGS:{
        GET_IDCARD_SETTINGS : "/api/idcard-settings/",
        POST_IDCARD_SETTINGS : "/api/idcard-settings/",
        PATCH_IDCARD_SETTINGS :id=>`/api/idcard-settings/${id}/`,
        DELETE_IDCARD_SETTINGS :id=>`/api/idcard-settings/${id}/`
    },

    DASHBOARD_SETTINGS:{
        GET_DASHBOARD_SETTINGS : "/api/dashboard-settings/",
        POST_DASHBOARD_SETTINGS : "/api/dashboard-settings/",
        PATCH_DASHBOARD_SETTINGS :id=>`/api/dashboard-settings/${id}/`,
        DELETE_DASHBOARD_SETTINGS :id=>`/api/dashboard-settings/${id}/`
    },

    CONTACT_US :{
        GET_CONTACT_US :"/api/contact-us/",
        POST_CONTACT_US : "/api/contact-us/",
        PATCH_CONTACT_US : id=>`/api/contact-us/${id}/`,
        DELETE_CONTACT_US : id=>`/api/contact-us/${id}/`
    },

    ABOUT_US :{
        GET_ABOUT_US :"/api/about-us/",
        POST_ABOUT_US : "/api/about-us/",
        PATCH_ABOUT_US : id=>`/api/about-us/${id}/`,
        DELETE_ABOUT_US : id=>`/api/about-us/${id}/`
    },

    USERS_SETTINGS :{
        GET_USERS_SETTINGS:"/api/intro-sliders/",
        POST_USERS_SETTINGS:"/api/intro-sliders/",
        PATCH_USERS_SETTINGS:id=>`/api/intro-sliders/${id}/`,
        DELETE_USERS_SETTINGS:id=>`/api/intro-sliders/${id}/`
    },

   WHATSAPP_SETTINGS :{
    GET_WHATSAPP_SETTINGS:"/api/whatsapp-texts/",
    POST_WHATSAPP_SETTINGS:"/api/whatsapp-texts/",
    PATCH_WHATSAPP_SETTINGS:id=>`/api/whatsapp-texts/${id}/`,
    DELETE_WHATSAPP_SETTINGS:id=>`/api/whatsapp-texts/${id}/`
   }
}
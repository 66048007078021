import React, { useCallback, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { regionalpartnertabledata, regionalpartnertableColumns } from '../common/mockData';
import { Btn, H4 } from '../../../../AbstractElements';
import EditCommonModal from '../common/EditCommonModal';
import AddRegionalPartners from './AddRegionalPartners';
import { CSVLink } from 'react-csv';
import { API_URL } from '../config/api_urls';
import { DELETE, get, patch, post } from '../config';
import { toast } from 'react-toastify';

function RegionalPartners() {
  const [data, setData] = useState([]);
  const [toggleDelet, setToggleDelet] = useState(false);
  const [regionalModal,setRegionalModal] = useState(false);
  const [csvData,setCsvData] = useState([]);
  const [editRegionalPartnerData,setEditRegionalPartnerData] = useState();


  useEffect(() => {
    setCsvData(data);
  }, [data]);


  useEffect(() => {
		fetchRegionalPartners();
	}, [])

	const fetchRegionalPartners = useCallback(() => {
		get(API_URL.REGIONAL_PARTNERS.GET_REGIONALS)
			.then((response) => {
				if (response?.data.length >= 0)
					setData(response.data)
			})
			.catch((error) => {
				console.error("Error:", error);
			});
	}, []);



 const regionalPartnerToggle = ()=>{
  setRegionalModal(!regionalModal);
 }

 const editmodalToggle =(row)=>{
  setRegionalModal(!regionalModal);
  setEditRegionalPartnerData(row)
 }
 

const handleSave = async (formData) => {
  
  if (formData?.id) {
    patch(API_URL.REGIONAL_PARTNERS.PATCH_REGIONALS(formData?.id), formData)
      .then((response) => {
        toast.success("Updated successfully");
        setRegionalModal(!regionalModal);
        setEditRegionalPartnerData(null);
        fetchRegionalPartners();
      })
      .catch((error) => {
        console.error("Error updating data:", error);
        toast.error("Oops! something went wrong");
      });
  }else{
    try {
      await post(API_URL.REGIONAL_PARTNERS.POST_REGIONALS, formData);
      toast.success("Regional Partner created successfully");
      setRegionalModal(!regionalModal);
      setEditRegionalPartnerData(null);
      fetchRegionalPartners();
    } catch (error) {
      console.error("Error saving data:", error);
      toast.error("Oops! something went wrong");
    }
  }
  
};


const handleDeletedata = (row) => {
  if (row?.id) {
    DELETE(API_URL.REGIONAL_PARTNERS.DELETE_REGIONALS(row?.id))
      .then((response) => {
        toast.success("Deleted successfully");
        fetchRegionalPartners();
      })
      .catch((error) => {
        toast.error("Oops! something went wrong");
      });
  }
}




const handleCancel = () => {
  setRegionalModal(!regionalModal);
  setEditRegionalPartnerData(null);
};

const handleExport = ()=>{
  setCsvData(data);
}



  return (
    <>
       <div className={`d-flex align-items-center justify-content-between  p-4`}>
                    <H4 attrH4={{ className: 'text-muted m-0' }}>All Regional Partners</H4>
                    <div className=' d-flex' style={{width:'380px',justifyContent:'space-between'}}>
                    <Btn attrBtn={{ color: 'primary',onClick:regionalPartnerToggle}}>+ Add New Regional Partner </Btn>
                       <AddRegionalPartners
                       isOpen={regionalModal}
                       toggle={regionalPartnerToggle}
                       title="Regional Partner"
                       regionalPartnerData={editRegionalPartnerData}
                       onSave={handleSave}
                       onCancel={handleCancel}
                       />
                    <CSVLink data={csvData} filename="RegionalPartners-data.csv" className="btn btn-primary" onClick={handleExport}>
            Export
          </CSVLink>
                    </div>
                   
                </div>
    <div>
       <DataTable
                data={data}
                columns={regionalpartnertableColumns(editmodalToggle,handleDeletedata)}
                striped={true}
                center={true}
                pagination
                selectableRows={false}
                clearSelectedRows={toggleDelet}
            />
    </div>
    </>
   
  )
}

export default RegionalPartners

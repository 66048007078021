export const constructFormData = (formData, imageFields = []) => {
	const data = new FormData();
	Object.keys(formData).forEach((key) => {
		if (imageFields.includes(key) && formData[key] instanceof File) {
			data.append(key, formData[key]);
		} else if(!imageFields.includes(key) && formData[key] instanceof Array) {
			formData[key]?.forEach((_,i) => data.append(key, formData[key][i]));
		} else if(!imageFields.includes(key) && formData[key] !== null && formData[key] !== undefined) {
			data.append(key, formData[key]);
		}
	});
	return data;
};




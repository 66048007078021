import React, { useCallback, useEffect, useState } from "react";
import Editor from "../common/Editor";
import { get, patch, post } from "../config";
import { API_URL } from "../config/api_urls";
import { toast } from "react-toastify";
import { Btn } from "../../../../AbstractElements";

const PaymentTerms = () => {
  const [text,setText] = useState("");
  const [id,setId] = useState("");
  
  useEffect(() => {
    fetchPaymentTerms();
  }, [])
  
  const fetchPaymentTerms = useCallback(() => {
    get(API_URL.PAYMENT_TERMS.GET_PAYMENT_TERMS)
      .then((response) => {
        if (response?.data.length > 0){
          setText(response.data[0].text);
          setId(response.data[0].id);
        }
          
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const handleSave = async () => {

		if (id) {
			patch(API_URL.PAYMENT_TERMS.PATCH_PAYMENT_TERMS(id), {
        id,text 
      })
				.then((response) => {
					toast.success("Updated successfully");
          fetchPaymentTerms();
				})
				.catch((error) => {
					console.error("Error updating data:", error);
					toast.error("Oops! something went wrong");
				});
		}else{
      try {
        await post(API_URL.PAYMENT_TERMS.POST_PAYMENT_TERMS, {text});
        toast.success("Payment terms & conditions saved successfully");
        fetchPaymentTerms();
      } catch (error) {
        console.error("Error saving data:", error);
        toast.error("Oops! something went wrong");
      }
    }
		
	};



  return (
    <div>
      <h3 className="title">Payment Terms & Conditions</h3>
      <Editor
        name="exampleEditor"
        value={text}
        onChange={setText}
        tooltipText="Custom Tooltip Text"
      />
      
      <div className="d-flex justify-content-end">
          <Btn attrBtn={{color:'primary' ,onClick:handleSave}}>
            Save Changes
          </Btn>
        </div>
     
    </div>
  );
};

export default PaymentTerms;
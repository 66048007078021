import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, Form, FormGroup, Label, Input } from 'reactstrap';
import { ColorPicker, DateAndTime, MaximumLength, Month, Password, Placeholder, SimpleInput, StaticText, Telephone, Textarea, Time, Week } from '../../../../Constant';
import HeaderCard from '../../../Common/Component/HeaderCard';
import FooterCard from '../../../Forms/FormControl/Common/FooterCard';
import { useLocation, useNavigate } from 'react-router';
import FormCommon from '../common/FormCommon';
import { get, patch, post } from '../config';
import { API_URL } from '../config/api_urls';
import { constructFormData } from '../config/imageHandle';
import { toast } from 'react-toastify';
import moment from 'moment';

function NewJobOpening(){ 

    const location = useLocation();
    const jobOpenings = location.state?.jobOpeningsData;
    console.log(location.state);
    

    const navigate = useNavigate();
    const [isEditing, setIsEditing] = useState(false);
    const [formDataConfig, setFormDataConfig] = useState({
        initialState: {
          uuid: null,
          user_uuid: "",
          title:"",
          image: "",
          event_types: "",
          category: "",
          description:"",
          address_line_1: "",
          address_line_2: "",
          pincode: "",
          city: "",
          state: "",
          country:"",
          registration_fee: "",
          deadline: "",
        },
        fields: [
            {
                name: "user_uuid",
                label: "Users",
                type: "select",
                options:[],
                required: true,
           },
           {
            name: "title",
            label: "Title",
            type: "text",
            placeholder: "Enter Title",
            required: true,
          },
           {
            name: "image",
            label: "Image",
            type: "file",
            required: true,
          },
          {
            name: "event_types",
            label: "Event Type",
            type: "select",
            options:[],
            required: true,
          },
          {
            name: "category",
            label: "Category",
            type: "select",
            options:[],
            required: true,
          },
          {
            name: "description",
            label: "Description",
            type: "textarea",
            placeholder: "Enter Description",
            required: true,
          },
          {
            name: "address_line_1",
            label: "Addressline1",
            type: "text",
            placeholder: "Enter Addressline1",
            required: true,
          },
          {
            name: "address_line_2",
            label: "Addressline2",
            type: "text",
            placeholder: "Enter Addressline2",
            required: true,
          },
          {
            name: "pincode",
            label: "Pincode",
            type: "number",
            placeholder: "Enter Pincode",
            required: true,
          },
          {
            name: "city",
            label: "City",
            type: "select",
            options:[],
            required: true,
          },
          {
            name: "state",
            label: "State",
            type: "select",
            options:[],
            required: true,
          },
          {
            name: "country",
            label: "Country",
            type: "select",
            options:[],
            required: true,
          },
          {
            name: "registration_fee",
            label: "Registration Fee",
            type: "number",
            placeholder: "Enter Registration Fee",
            required: true,
          },
          {
            name: "deadline",
            label: "Deadline",
            type: "datetime-local",
            placeholder: "Select Deadline",
            required: true,
          }
          
        ],
      });

      



      useEffect(() => {
        if (jobOpenings) {
          console.log(jobOpenings);
          
          setIsEditing(true);
          setFormDataConfig(value => ({
            ...value,
            initialState: {
              ...jobOpenings,
              user_uuid:jobOpenings?.user_uuid,
              category:jobOpenings?.category,
              event_types:jobOpenings?.event_types,
              state:jobOpenings?.state,
              country:jobOpenings?.country
              
            }
          }));
        } else {
          setIsEditing(false);
          setFormDataConfig(value => ({
            ...value,
            initialState: {
              uuid: null,
              user_uuid: "",
              title:"",
              image: "",
              event_types: "",
              category: "",
              description:"",
              address_line_1: "",
              address_line_2: "",
              pincode: "",
              city: "",
              state: "",
              country:"",
              registration_fee: "",
              deadline: "",
            },
          }));
        }
      }, [jobOpenings]);


      

      useEffect(() => {
        fetchUsers();
        fetchEventCategory();
        fetchEventType();
        fetchStates();
        fetchCountries();
        fetchCities();
      }, [])



      const handleNavigateToEvents = () => {
        navigate(`${process.env.PUBLIC_URL}/events/openings/:layout`)
      }

    
      //users dropdown
      const fetchUsers = useCallback(async () => {
        try {
          const response = await get(API_URL.USERS.GET_USERS);
          const users_names = response?.data?.map((i) => ({
            label: i.name,
            value: i.uuid
          }));
          users_names.unshift({ label: "Select User", value: "" });
          if (users_names.length > 0) {
            setFormDataConfig((prevConfig) => ({
              ...prevConfig,
              fields: prevConfig.fields.map((field) =>
                field.name === "user_uuid" ? { ...field, options: users_names } : field
              ),
            }));
          }
    
        } catch (error) {
          console.error("Error:", error);
        }
      }, []);

      //category dropdown
      const fetchEventCategory = useCallback(async () => {
        try {
          const response = await get(API_URL.EVENT_CATEGORY.GET_EVENT_CATEGORY);
          const event_categories = response?.data?.map((i) => ({
            label: i.name,
            value: i.id
          }));
          event_categories.unshift({ label: "Select Event Category", value: "" });
          if (event_categories.length > 0) {
            setFormDataConfig((prevConfig) => ({
              ...prevConfig,
              fields: prevConfig.fields.map((field) =>
                field.name === "category" ? { ...field, options: event_categories } : field
              ),
            }));
          }
    
        } catch (error) {
          console.error("Error:", error);
        }
      }, []);

      //event type dropdown
      const fetchEventType = useCallback(async () => {
        try {
          const response = await get(API_URL.EVENT_TYPES.GET_EVENT_TYPES);
          const events_types = response?.data?.map((i) => ({
            label: i.name,
            value: i.id
          }));
          events_types.unshift({ label: "Select Event Types", value: "" });
          if (events_types.length > 0) {
            setFormDataConfig((prevConfig) => ({
              ...prevConfig,
              fields: prevConfig.fields.map((field) =>
                field.name === "event_types" ? { ...field, options: events_types } : field
              ),
            }));
          }
    
        } catch (error) {
          console.error("Error:", error);
        }
      }, []);

       //states dropdown
       const fetchStates = useCallback(async () => {
        try {
          const response = await get(API_URL.STATES.STATES_GET);
          const states = response?.data?.map((i) => ({
            label: i.name,
            value: i.id
          }));
          states.unshift({ label: "Select State", value: "" });
          if (states.length > 0) {
            setFormDataConfig((prevConfig) => ({
              ...prevConfig,
              fields: prevConfig.fields.map((field) =>
                field.name === "state" ? { ...field, options: states } : field
              ),
            }));
          }
    
        } catch (error) {
          console.error("Error:", error);
        }
      }, []);


       //country dropdown
       const fetchCountries = useCallback(async () => {
        try {
          const response = await get(API_URL.COUNTRIES.COUNTRIES_GET);
          const countries = response?.data?.map((i) => ({
            label: i.name,
            value: i.id
          }));
          countries.unshift({ label: "Select Country", value: "" });
          if (countries.length > 0) {
            setFormDataConfig((prevConfig) => ({
              ...prevConfig,
              fields: prevConfig.fields.map((field) =>
                field.name === "country" ? { ...field, options: countries } : field
              ),
            }));
          }
    
        } catch (error) {
          console.error("Error:", error);
        }
      }, []);

      //cities dropdown
      const fetchCities = useCallback(async () => {
        try {
          const response = await get(API_URL.CITIES.GET_CITIES);
          const cities = response?.data?.map((i) => ({
            label: i.name,
            value: i.id
          }));
          cities.unshift({ label: "Select City", value: "" });
          if (cities.length > 0) {
            setFormDataConfig((prevConfig) => ({
              ...prevConfig,
              fields: prevConfig.fields.map((field) =>
                field.name === "city" ? { ...field, options: cities } : field
              ),
            }));
          }
    
        } catch (error) {
          console.error("Error:", error);
        }
      }, []);




      
	const onSave = (formData) => {
    const formattedDeadline = moment(formData.deadline).format('YYYY-MM-DD hh:mm A')

    console.log(formData);

		const data = constructFormData({...formData,deadline: formattedDeadline }, ['image']);

    console.log(data);
    
		
		if (formData?.uuid) {
			patch(API_URL.EVENTS.PATCH_EVENT(formData?.uuid), data)
				.then((response) => {
          console.log(response);
          
					toast.success("Updated successfully");
          handleNavigateToEvents();
				})
				.catch((error) => {
					console.error("Error updating data:", error);
					toast.error("Oops! something went wrong");
				});
		} else {
			post(API_URL.EVENTS.POST_EVENT, data)
				.then((response) => {
          console.log(response);
          
					toast.success("Event created successfully");
          handleNavigateToEvents();
				})
				.catch((error) => {
					console.error("Error saving data:", error);
					toast.error("Oops! something went wrong");
				});
		}
	};




    return (
        <div>
        <FormCommon
        title={`${jobOpenings ? 'Edit' : 'Add'} Event Opening`}
        formDataConfig={formDataConfig}
        onSave={onSave}
        // editMode={!!formDataConfig?.initialState?.id}
      />
    </div>
    );
};

export default NewJobOpening;
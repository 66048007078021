import React, { useCallback, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { SizeChartdummydata, SizeCharttableColumns } from '../common/mockData';
import { Btn, H4 } from '../../../../AbstractElements';
import Newsize from './Newsize';
import { DELETE, get, patch, post } from '../config';
import { API_URL } from '../config/api_urls';
import { toast } from 'react-toastify';

function SizeChart() {
  const [data, setData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleDelet, setToggleDelet] = useState(false);
  const [sizeChartModal,setSizeChartModal] = useState(false);
  const [editSizechart,setEditSizechart] = useState();

  const handleRowSelected = useCallback(state => {
    setSelectedRows(state.selectedRows);
}, []);


useEffect(() => {
  fetchMarketSizes();
}, [])

const fetchMarketSizes = useCallback(() => {
  get(API_URL.MARKET_SIZES.GET_MARKET_SIZES)
    .then((response) => {
      if (response?.data.length >= 0){
        const sortedResponse = response?.data?.sort((a, b) => {
          return a.order_number - b.order_number;
        })
        setData(sortedResponse);
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}, []);




 const sizechartToggle = ()=>{
  setSizeChartModal(!sizeChartModal);
 }

 const editmodalToggle =(row)=>{
  setSizeChartModal(!sizeChartModal);
  setEditSizechart(row)
 }


const handleSave = async (formData) => {
  if (formData?.id) {
    patch(API_URL.MARKET_SIZES.PATCH_MARKET_SIZE(formData?.id), formData)
      .then((response) => {
        toast.success("Updated successfully");
        setEditSizechart(null)
        setSizeChartModal(!sizeChartModal);
        fetchMarketSizes();
      })
      .catch((error) => {
        console.error("Error updating data:", error);
        toast.error("Oops! something went wrong");
      });
  }else{
    try {
      await post(API_URL.MARKET_SIZES.POST_MARKET_SIZE, formData);
      toast.success("Size created successfully");
      setEditSizechart(null)
      setSizeChartModal(!sizeChartModal);
      fetchMarketSizes();
    } catch (error) {
      console.error("Error saving data:", error);
      toast.error("Oops! something went wrong");
    }
  }
  
};


const handleCancel = () => {
  setEditSizechart(null)
  setSizeChartModal(!sizeChartModal);
};


const handleDeletedata = (row)=>{
  if(row?.id){
   DELETE(API_URL.MARKET_SIZES.DELETE_MARKET_SIZE(row?.id))
   .then((response) => {
     toast.success("Deleted successfully");
     fetchMarketSizes();
   })
   .catch((error) => {
     toast.error("Oops! something went wrong");
   });
  }
 }

  return (
    <>
       <div className={`d-flex align-items-center justify-content-between  p-4`}>
                    <H4 attrH4={{ className: 'text-muted m-0' }}>MarketPlace Size Chart</H4>
                    <Btn attrBtn={{ color: 'primary', onClick:sizechartToggle }}>+ Add New Size</Btn>
                    
                      <Newsize
                      editSizeChartData = {editSizechart}
                      isOpen={sizeChartModal}
                      toggle={sizechartToggle}
                      title="Size"
                      onSave={handleSave}
                      onCancel={handleCancel}
                      />
                    
                </div>
    <div>
       <DataTable
                data={data}
                columns={SizeCharttableColumns(editmodalToggle,handleDeletedata)}
                striped={true}
                center={true}
                pagination
                selectableRows={false}
                onSelectedRowsChange={handleRowSelected}
                clearSelectedRows={toggleDelet}
            />
    </div>
    </>
   
  )
}

export default SizeChart
import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button } from '@mui/material';

const AnchorDialog = ({ open, onClose, onSave }) => {
  const [anchorName, setAnchorName] = useState('');

  const handleSave = () => {
    onSave(anchorName);
    setAnchorName('');
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Insert Anchor</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Anchor Name"
          type="text"
          fullWidth
          value={anchorName}
          onChange={(e) => setAnchorName(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AnchorDialog;

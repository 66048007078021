import React from 'react';
import { TrendingDown, TrendingUp } from 'react-feather';
import { Card, CardBody, CardHeader, Table } from 'reactstrap';
import { H5 } from '../../../AbstractElements';
import { AllCampaignsTitle, DailyDropdown } from '../../../Constant';
import { AllCampaignsTable } from '../../../Data/Social';
import DropdownCommon from '../../Common/Dropdown';

const AllCampaigns = () => {
  return (
    <Card>
    </Card>
  );
};

export default AllCampaigns;

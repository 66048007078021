import React, { Fragment, useCallback, useEffect, useState } from 'react';
import FormCommon from '../common/FormCommon';
import { get, patch, post } from '../config';
import { API_URL } from '../config/api_urls';
import { toast } from 'react-toastify';


const DashboardSettings = () => {
 
    const [data,setData] = useState([]);
    const [formDataConfig, setFormDataConfig] = useState({
        initialState: {
          id: null,
          imoda_title: "",
          imoda_description:"",
          work_title:"",
          work_description:""
        },
        fields: [
          {
            name: "imoda_title",
            label: "Imoda Title",
            type: "text",
            placeholder: "Enter Imoda Title",
            required: true,
          },
          {
            name: "imoda_description",
            label: "Imoda Description",
            type: "text",
            placeholder: "Enter Imoda Description",
            required: true,
          },
          {
            name: "work_title",
            label: "Work Title",
            type: "text",
            placeholder: "Enter Work Title",
            required: true,
          },
          {
            name: "work_description",
            label: "Work Description",
            type: "text",
            placeholder: "Enter Work Description",
            required: true,
          }
        ]
      });

 
      useEffect(() => {
        fetchDashboardSettings();
      }, [])
      
      const fetchDashboardSettings = useCallback(() => {
        get(API_URL.DASHBOARD_SETTINGS.GET_DASHBOARD_SETTINGS)
          .then((response) => {
            if (response?.data.length > 0)
              setData(response.data);
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }, []);


      useEffect(()=>{
        if(data && data.length > 0){
          setFormDataConfig(prevValue => ({
            ...prevValue,
            initialState: data[0]
          }));
        }
        },[data])

              const handleSave = async (formData) => {
                if (formData?.id) {
                  patch(API_URL.DASHBOARD_SETTINGS.PATCH_DASHBOARD_SETTINGS(formData?.id), formData)
                    .then((response) => {
                      toast.success("Updated successfully");
                      fetchDashboardSettings();
                    })
                    .catch((error) => {
                      console.error("Error updating data:", error);
                      toast.error("Oops! something went wrong");
                    });
                }else{
                  try {
                    await post(API_URL.DASHBOARD_SETTINGS.POST_DASHBOARD_SETTINGS, formData);
                    toast.success("Dashboard Settings Created successfully");
                    fetchDashboardSettings();
                  } catch (error) {
                    console.error("Error saving data:", error);
                    toast.error("Oops! something went wrong");
                  }
                }
                
              };




    return (
        <div>
        <FormCommon
        isModal={false}
        title={`Dashboard Settings`}
        formDataConfig={formDataConfig}
        onSave={handleSave}
        
      />
    </div>
    );
};

export default DashboardSettings;
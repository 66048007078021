import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { H5, UL, LI, H4 } from '../../../AbstractElements';
import { Cancelled,  Delivered, LastMonth, profit, profit2, RecentOrdersTitle, WeeklyMonDropdown } from '../../../Constant';
import { RecentOrderChart } from '../../../Data/DefaultDashboard/Chart';
import DropdownCommon from '../../Common/Dropdown';

const RecentOrders = () => {
  return (
    <Col xxl='4' xl='7' md='6' sm='5' className='box-col-6'>
      
    </Col>
  );
};

export default RecentOrders;

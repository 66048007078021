import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import FormCommon from '../common/FormCommon';
import { get, patch, post } from '../config';
import { API_URL } from '../config/api_urls';
import { toast } from 'react-toastify';
import { constructFormData } from '../config/imageHandle';


const AddMarketPlace = () => {

    const location = useLocation();
    const marketPlaceData = location.state?.marketplace;

    const navigate = useNavigate();

    const [isEditing, setIsEditing] = useState(false);
    const [productSize, setProductSize] = useState([]);
 console.log("productSize ", productSize);
    const [productCategory,setProductCategory] = useState([]);

    const [formDataConfig, setFormDataConfig] = useState({
        initialState: {
          uuid: null,
          user_uuid: '',
          profile_image:'',
          cover_image:'',
          title: '',
          size_value: '',
          category_name: '',
          price: '',
          sale_price: '',
          stock_quantity:'',
          status: '',
          active: false,
          description: '',
          is_featured: false
        },
        fields: [
            {
                name: "user_uuid",
                label: "User",
                type: "select",
                options:[],
                required: true,
              },
              {
                name: "profile_image",
                label: "Featured Image",
                type: "file",
                required: true,
              },
              {
                name: "cover_image",
                label: "Images",
                type: "file",
                required: true,
              },
          {
            name: "title",
            label: "Title",
            type: "text",
            placeholder: "Enter Title",
            required: true,
          },
          {
            name: "size_value",
            label: "Size",
            type: "multiselect",
            options:[],
            required: true,
          },
          {
            name: "category_name",
            label: "Category",
            type: "select",
            options:[],
            required: true,
          },
          {
            name: "price",
            label: "Price",
            type: "number",
            placeholder: "Enter Price",
            required: false,
          },
          {
            name: "sale_price",
            label: "SalePrice",
            type: "number",
            placeholder: "Enter SalePrice",
            required: false,
          },
          {
            name: "stock_quantity",
            label: "Stock",
            type: "number",
            placeholder: "Enter Stock quantity",
            required: false,
          },
          {
            name: "status",
            label: "status",
            type: "select",
            options:[
              {label:"select status",disabled: true},
              {value:'available',label:"available"},
              {value:'unavailable',label:"unavailable"},],
            required: false,
          },
          {
            name: "description",
            label: "Description",
            type: "text",
            placeholder: "Enter Description",
            required: true,
          },
          {
            name: "is_featured",
            label: "is_featured",
            type: "checkbox",
            required: false,
          },
          {
            name: "active",
            label: "active",
            type: "checkbox",
            required: false,
          }
             ],
        imageField: "image",
      });


      useEffect(() => {
        if (marketPlaceData) {
 console.log("marketPlaceData ", marketPlaceData);
          setIsEditing(true);
          setFormDataConfig(value => ({
            ...value,
            initialState: {
              ...marketPlaceData,
              size_value : productSize.filter(i=>marketPlaceData.size_value.includes(i.label)).map(i=>i.value)
              
            }
          }));
        } else {
          setIsEditing(false);
          setFormDataConfig(value => ({
            ...value,
            initialState: {
              uuid: null,
              user_uuid: '',
              profile_image:'',
              cover_image:'',
              title: '',
              size_value: '',
              category_name: '',
              price: '',
              sale_price: '',
              stock_quantity:'',
              status: '',
              active: false,
              description: '',
              is_featured: false
            },
          }));
        }
      }, [marketPlaceData]);


    useEffect(()=>{
      fetchUsers();
      fetchMarketcategory();
      fetchSize();
    },[])  

const handleNavigateToMarketPlace = ()=>{
  navigate(`${process.env.PUBLIC_URL}/market-place/:layout`)
}


  //users dropdown
	const fetchUsers = useCallback(async () => {
		try {
			const response = await get(API_URL.USERS.GET_USERS);
			const user_ids = response?.data?.map((i) => ({
				label: i.name,
				value: i.uuid,
			}));
			user_ids.unshift({ label: "Select user", value: "" });
			if (user_ids.length > 0) {
				setFormDataConfig((prevConfig) => ({
					...prevConfig,
					fields: prevConfig.fields.map((field) =>
						field.name === "user_uuid" ? { ...field, options: user_ids } : field
					),
				}));
			}

		} catch (error) {
			console.error("Error:", error);
		}
	}, []);

	// market category dropdown
	const fetchMarketcategory = useCallback(async () => {
		try {
			const response = await get(API_URL.MARKET_CATEGORY.GET_MARKET_CATEGORY);
			const market_category = response?.data?.map((i) => ({
				label: i.name,
				value: i.id,
			}));
			market_category.unshift({ label: "Select product category", value: "" });
			if (market_category.length > 0) {
				setFormDataConfig((prevConfig) => ({
					...prevConfig,
					fields: prevConfig.fields.map((field) =>
						field.name === "category_name" ? { ...field, options: market_category } : field
					),
				}));
			}
		} catch (error) {
			console.error("Error:", error);
		}
	}, []);

	// size dropdown
	const fetchSize = useCallback(async () => {
		try {
			const response = await get(API_URL.MARKET_SIZES.GET_MARKET_SIZES);
			const size_ids = response?.data?.map((i) => ({
				label: i.size,
				value: i.id,
			}));
      setProductSize(size_ids)
			size_ids.unshift({ label: "Select size", value: "" });
			if (size_ids.length > 0) {
				setFormDataConfig((prevConfig) => ({
					...prevConfig,
					fields: prevConfig.fields.map((field) =>
						field.name === "size_value" ? { ...field, options: size_ids } : field
					),
				}));
			}
		} catch (error) {
			console.error("Error:", error);
		}
	}, []);
    
  const onSave = (formData) => {
 console.log("formData ", formData);
    const values = {
			uuid: formData?.uuid || '',
			user_uuid: formData?.user_uuid,
			size_id: formData?.size_value ,
      category_id:formData?.category_name,
      cover_image:formData?.cover_image,
      profile_image:formData?.profile_image,
      description:formData?.description,
      price:formData?.price,
      sale_price:formData?.sale_price,
      stock_quantity:formData?.stock_quantity,
      title:formData?.title
		}
    console.log("values ", values);

    const data = constructFormData(values, ['cover_image', 'profile_image']);

		if (formData?.uuid) {
			patch(API_URL.MARKET_PRPDUCTS.PATCH_MARKET_PRPDUCTS(formData?.uuid), data)
				.then((response) => {
					toast.success("Updated successfully");
					handleNavigateToMarketPlace();
				})
				.catch((error) => {
					console.error("Error updating data:", error);
					toast.error("Oops! something went wrong");
				});
		} else {
			post(API_URL.MARKET_PRPDUCTS.POST_MARKET_PRPDUCTS, data)
				.then((response) => {
					toast.success("Product added successfully");
					handleNavigateToMarketPlace();
				})
				.catch((error) => {
					console.error("Error saving data:", error);
					toast.error("Oops! something went wrong");
				});
		}
	};





    return (
        <div>
        <FormCommon
        title={`${marketPlaceData ? 'Edit' : 'Add'} MarketPlace`}
        formDataConfig={formDataConfig}
        onSave={onSave}
        // editMode={!!formDataConfig?.initialState?.id}
      />
    </div>
    );
};

export default AddMarketPlace;
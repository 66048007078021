import React, { useEffect, useState } from 'react'
import FormCommon from '../common/FormCommon';
import EditCommonModal from '../common/EditCommonModal';
import { FormGroup, Input, Label } from 'reactstrap';

function NewPreference({editPreferenceData,isOpen,toggle,title,onSave,onCancel}) {

    const [isEditing, setIsEditing] = useState(false);
    const [formDataConfig, setFormDataConfig] = useState({
            initialState: {
              id: null,
              name: ""
            },
            fields: [
              {
                name: "name",
                label: "Preference",
                type: "select",
                options:[],
                required: true,
              }
            ],
          });



          useEffect(() => {
            if (editPreferenceData) {
              setIsEditing(true);
              setFormDataConfig(value => ({
                ...value,
                initialState: editPreferenceData,
              }));
            } else {
              setIsEditing(false);
              setFormDataConfig(value => ({
                ...value,
                initialState: {
                  id: null,
                  name: '',
                },
              }));
            }
          }, [editPreferenceData]);
        
        
            const handleSave = () => {
              onSave(formDataConfig.initialState);
            };
                  
            const handleCancel = () => {
              onCancel();   
            };         

const setPreference =(e)=>{
  setFormDataConfig({
    ...formDataConfig,
    initialState: {
      ...formDataConfig.initialState,
      name: e.target.value
    }
  })
}

  return (
    <EditCommonModal
    isOpen={isOpen}
    toggle={toggle}
    title={`${isEditing ? 'Edit' : 'Add'} ${title}`}
    onSave={handleSave}
    onCancel={handleCancel}
    > 
       <div className='mb-4'>
       <FormGroup>
					<Label className="col-form-label pt-0" >Preference</Label>
					<Input value={formDataConfig.initialState.name} onChange={setPreference} className="form-control" type="text" placeholder="Enter Preference" />

				</FormGroup>
			</div>

    </EditCommonModal>
    
  )
}

export default NewPreference
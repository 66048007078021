import React, { useCallback, useEffect, useState } from "react";
import Editor from "../common/Editor";
import { API_URL } from "../config/api_urls";
import { get, patch, post } from "../config";
import { toast } from "react-toastify";
import { Btn } from "../../../../AbstractElements";
import { MarginTop } from "../../../../Constant";

const TermsCondition = () => {
  const [content,setContent] = useState("");
  const [id,setId] = useState("");

  useEffect(() => {
    fetchTermsandConditions();
  }, [])
  
  const fetchTermsandConditions = useCallback(() => {
    get(API_URL.TERMS_CONDITIONS.GET_TERMS_CONDITIONS)
      .then((response) => {
        if (response?.data){
          setContent(response.data.content);
          setId(response.data.id);
        }
          
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);




  const handleSave = async () => {

		if (id) {
			patch(API_URL.TERMS_CONDITIONS.PATCH_TERMS_CONDITIONS(id), {
        id,content 
      })
				.then((response) => {
					toast.success("Updated successfully");
          fetchTermsandConditions();
				})
				.catch((error) => {
					console.error("Error updating data:", error);
					toast.error("Oops! something went wrong");
				});
		}else{
      try {
        await post(API_URL.TERMS_CONDITIONS.POST_TERMS_CONDITIONS, {content});
        toast.success("Terms & conditions saved successfully");
        fetchTermsandConditions();
      } catch (error) {
        console.error("Error saving data:", error);
        toast.error("Oops! something went wrong");
      }
    }
		
	};




  return (
    <div>
      <h3 className="title"> Terms & Conditions</h3>
      <Editor
        name="exampleEditor"
        value={content}
        onChange={setContent}
        tooltipText="Custom Tooltip Text"
      />
      
      <div className="d-flex justify-content-end mt-5">
          <Btn attrBtn={{color:'primary',onClick:handleSave}}>
            Save Changes
          </Btn>
        </div>
    </div>
  );
};

export default TermsCondition;
import React from 'react';
import { Card, CardBody, CardHeader, Col } from 'reactstrap';

const TimelineCard = () => {
  return (
    <Col xxl='4' md='6' className='appointment-sec box-col-6'>
      <div className='appointment'>
      </div>
    </Col>
  );
};

export default TimelineCard;

import { Btn, H6 } from '../../../../AbstractElements';
import { PersonalDetails, FullName, Email, Password, RepeatPassword, BirthDate, PhoneNumber } from '../../../../Constant';
import { Typeahead } from 'react-bootstrap-typeahead';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { useLocation, useNavigate } from 'react-router';
import FormCommon from '../common/FormCommon';
import { get, patch, post } from '../config';
import { API_URL } from '../config/api_urls';
import { constructFormData } from '../config/imageHandle';
import { toast } from 'react-toastify';

const NewAttribute = () => {
    // eslint-disable-next-line
    const [multiple, setMultiple] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const editAttributeData = location.state?.attributeData
    
    const [userRoles,setUserRoles] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [formDataConfig, setFormDataConfig] = useState({
        initialState: {
          id: null,
          user_role_name: "",
          attribute_name: "",
          image: "",
          
        },
        fields: [
            {
                name: "user_role_name",
                label: "user Role",
                type: "select",
                options:[],
                required: true,
              },
          {
            name: "attribute_name",
            label: "Attribute",
            type: "text",
            placeholder: "Enter Attribute",
            required: true,
          },
          {
            label: "range",
            type: "group", 
            fields: [
              {
                name: "enter_startpoint",
                placeholder: "Enter startpoint",
                type: "number",
              },
              {
                name: "enter_endpoint",
                placeholder: "Enter endpoint",
                type: "number",
              },
              {
                name: "enter_unit",
                placeholder: "Enter unit",
                type: "text",
              }
            ],
            required: false,
          },
          {
            name: "order_number",
            label: "Order number",
            type: "number",
            placeholder: "Enter Order number",
            required: false,
          },
          {
            name: "image",
            label: "Image",
            type: "file",
            required: false,
          }
          
        ],
        imageField: "image",
      });
    
      useEffect(() => {
        if (editAttributeData) {
          setIsEditing(true);
          setFormDataConfig(value => ({
            ...value,
            initialState: {
              ...editAttributeData,
              user_role_name : userRoles.find(i=>i.label === editAttributeData?.user_role_name)?.value 
            }
            
          }));
        } else {
          setIsEditing(false);
          setFormDataConfig(value => ({
            ...value,
            initialState: {
              id: null,
              image: "",
              user_role_name: "",
              attribute_name:""
            },
          }));
        }
      }, [editAttributeData]);

      console.log(userRoles);
      
    
      
      useEffect(()=>{
        fetchUserRoles();
      },[])

      const handleNavigateToAttributes =()=>{
        navigate(`${process.env.PUBLIC_URL}/attributes/:layout`)
      }

        //user role dropdown
        const fetchUserRoles = useCallback(async () => {
          try {
            const response = await get(API_URL.USER_ROLE.USER_ROLE_GET);
            const userRole_ids = response?.data?.map((i) => ({
              label: i.role,
              value: i.id
            }));
            userRole_ids.unshift({ label: "Select User Role", value: "" });
            if (userRole_ids.length > 0) {
              setUserRoles(userRole_ids);
              setFormDataConfig((prevConfig) => ({
                ...prevConfig,
                fields: prevConfig.fields.map((field) =>
                  field.name === "user_role_name" ? { ...field, options: userRole_ids } : field
                ),
              }));
            }
      
          } catch (error) {
            console.error("Error:", error);
          }
        }, []);



        
	const onSave = (formData) => {
    const values = {
			id: formData?.id || '',
      user_role: formData?.user_role_name,
      attribute_name: formData?.attribute_name

		}
		
		const data = constructFormData(values, ['image']);
		
		if (formData?.id) {
			patch(API_URL.ATTRIBUTES.PATCH_ATTRIBUTES(formData?.id), data)
				.then((response) => {
          console.log(response);
          
					toast.success("Updated successfully");
					handleNavigateToAttributes();
				})
				.catch((error) => {
					console.error("Error updating data:", error);
					toast.error("Oops! something went wrong");
				});
		} else {
			post(API_URL.ATTRIBUTES.POST_ATTRIBUTES, data)
				.then((response) => {
					toast.success("Attribute created successfully");
					handleNavigateToAttributes();
				})
				.catch((error) => {
					console.error("Error saving data:", error);
					toast.error("Oops! something went wrong");
				});
		}
	};




    return (
       
        <div>
        <FormCommon
        title={`${editAttributeData ? 'Edit' : 'Add'} Attribute`}
        formDataConfig={formDataConfig}
        onSave={onSave}
        // editMode={!!formDataConfig?.initialState?.id}
      />
    </div>
    );
};

export default NewAttribute;
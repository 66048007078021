import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardBody, Col, Form, FormGroup, Label, Row } from 'reactstrap';
import { Btn } from '../../../../AbstractElements';
import './FormCommon.css';
import { toast } from 'react-toastify';
import Select from 'react-select';

function FormCommon({isModal, formDataConfig, onSave, title }) {
    const [formData, setFormData] = useState(formDataConfig.initialState || {});
    const [inputsEntered, setInputsEntered] = useState(false);

    useEffect(() => {
        setFormData(formDataConfig.initialState || {});
    }, [formDataConfig]);

    const handleInputChange = (e) => {
        const { name, value, files, type, checked } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: type === 'file' ? files[0] : type === 'checkbox' ? checked : value,
        }));
        setInputsEntered(true);
    };

    const handleMultiSelectChange = (selectedOptions, fieldName) => {
        setFormData(prevData => ({
            ...prevData,
            [fieldName]: selectedOptions ? selectedOptions.map(option => option.value) : [],
        }));
        setInputsEntered(true);
    };


    const handleSave = () => {
		if (!inputsEntered) {
			toast.warning("Please enter the inputs!");
			return;
		}

		if (onSave) onSave(formData);
		
	};


    const renderField = (field) => {
        if (!field) return null;
        const fieldValue = formData[field.name] || [];
        switch (field.type) {
            case "file":
                const renderImage = typeof fieldValue === 'string' ? <img src={`${fieldValue}`} className="dummy-image" alt='IMAGE' />: null ;
                return (
                    <div className="file-field-container input-field d-flex">
                        <input
                            type="file"
                            id={field.name}
                            className="styled-input"
                            name={field.name}
                            onChange={handleInputChange}
                            required={field.required}
                        />
                       <div className='p-3'>{renderImage}</div> 
                    </div>
                );

            case "checkbox":
                return (
                    <label className=" d-flex ">
                        <input
                            type="checkbox"
                            value={field.name}
                            id={field.name}
                            name={field.name}
                            checked={!!formData[field.name]}
                            onChange={handleInputChange}
                            required={field.required}
                        />
                      
                    </label>
                );
            case "select":
                return (
                    <select
                        name={field.name}
                        value={formData[field.name] || ""}
                        onChange={handleInputChange}
                        className="styled-input"
                        required={field.required}
                    >
                        {Array.isArray(field.options) ? (
                        field.options.map(option => (
                       <option key={option.value} value={option.value} disabled={option.disabled}>
                        {option.label}
                      </option>
                ))
            ) : (
                <option disabled>No options available</option>
            )}
        </select>
                );
                case "multiselect":
                return (
                    <Select
                        isMulti
                        name={field.name}
                        options={field.options}
                        value={field.options.filter(option => fieldValue.includes(option.value))}
                        onChange={(selectedOptions) => handleMultiSelectChange(selectedOptions, field.name)}
                        className="basic-multi-select"
                        classNamePrefix="select"
                    />
                );
        
            case "group":
                return (
                    <div className="range-group">
                        <Row>
                            {field.fields.map(subField => (
                                <Col key={subField.name}>
                                    <input
                                        type={subField.type}
                                        id={subField.name}
                                        className="styled-input"
                                        name={subField.name}
                                        placeholder={subField.placeholder}
                                        onChange={handleInputChange}
                                        value={formData[subField.name] || ""}
                                        required={field.required}
                                    />
                                </Col>
                            ))}
                        </Row>
                    </div>
                );
            default:
                return (
                    <input
                        type={field.type}
                        id={field.name}
                        className="styled-input"
                        name={field.name}
                        placeholder={field.placeholder}
                        onChange={handleInputChange}
                        value={formData[field.name] || ""}
                        required={field.required}
                    />
                );
        }
    };

    return (
        <Fragment>
            <Card >
                <Form className="form theme-form p-3" onSubmit={(e) => e.preventDefault()}>
                    <h4 className='ms-3 mt-3'>{title}</h4>
                    <CardBody>
                        {Array.isArray(formDataConfig.fields) && formDataConfig.fields.map((field) => (
                            <Row key={field.name}>
                                <Col>
                                    <FormGroup>
                                        <Label htmlFor={field.name}>{field.label}</Label>
                                        {renderField(field)}
                                    </FormGroup>
                                </Col>
                            </Row>
                        ))}
                    </CardBody>
                    {!isModal && <div className='w-100 d-flex justify-content-end'>
                        <Btn attrBtn={{ color: 'primary', type: 'submit', onClick:handleSave }}>Save Changes</Btn>
                    </div>}
                </Form>
            </Card>
        </Fragment>
    );
}

export default FormCommon;

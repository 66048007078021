import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import {
  applicationtabledata,
  applicationtableColumns,
} from "../common/mockData";
import { Btn, H4 } from "../../../../AbstractElements";
import EditCommonModal from "../common/EditCommonModal";
import AddApplication from "./AddApplication";
import ApplicationFilter from "./ApplicationFilter";
import { CSVLink } from "react-csv";
import { API_URL } from "../config/api_urls";
import { DELETE, get, patch, post } from "../config";
import { toast } from "react-toastify";
import { constructFormData } from "../config/imageHandle";

function EventApplication() {
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [toggleDelet, setToggleDelet] = useState(false);
  const [applicationModal, setApplicationModal] = useState(false);
  const [filterModal, setFilterModal] = useState(false);
  const [statedata, setStatedata] = useState("");
  const [states, setStates] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [editApplicationData, setEditApplicationData] = useState();

  useEffect(() => {
    setCsvData(data);
  }, [data]);

  console.log(csvData);
  

  useEffect(() => {
    fetchEventApplication();
    fetchStates();
  }, []);

  const fetchEventApplication = useCallback(() => {
    get(API_URL.EVENT_APPLICATION.GET_EVENT_APPLICATION)
      .then((response) => {
        console.log(response);

        if (response?.data.length >= 0) setData(response.data);
        setOriginalData(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  console.log("data", data, "originaldata", originalData);

  const fetchStates = useCallback(() => {
    get(API_URL.STATES.STATES_GET)
      .then((response) => {
        if (response?.data.length > 0) setStates(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const applicationToggle = () => {
    setApplicationModal(!applicationModal);
  };  

  const editmodalToggle = (row) => {
    console.log(row);
    
    setEditApplicationData(row);
    setApplicationModal(!applicationModal);
  };

  const handleCancel = () => {
    setApplicationModal(!applicationModal);
  };

  const handleSave = async (formData) => {
    console.log(formData);

    const values = {
      id: formData?.id || "",
      user: formData?.user_name?.value,
      user_role: formData?.user_role_name?.value,
      event_uuid: formData?.event?.value,
      status:formData?.status,
    };

    console.log("VALUES", values);

    if (formData?.id) {
      console.log(formData);

      patch(
        API_URL.EVENT_APPLICATION.PATCH_EVENT_APPLICATION(formData?.id),
        values
      )
        .then((response) => {
          console.log(response);
          
          toast.success("Updated successfully");
          setApplicationModal(!applicationModal);
          setEditApplicationData(null);
          fetchEventApplication();
        })
        .catch((error) => {
          console.error("Error updating data:", error);
          toast.error("Oops! something went wrong");
        });
    } else {
      try {
        await post(API_URL.EVENT_APPLICATION.POST_EVENT_APPLICATION, values);
        console.log(values);

        toast.success("Application Submited successfully");
        setApplicationModal(!applicationModal);
        setEditApplicationData(null);
        fetchEventApplication();
      } catch (error) {
        console.error("Error saving data:", error);
        toast.error("Oops! something went wrong");
      }
    }
  };

  const handleDeletedata = (row) => {
    if (row?.id) {
      DELETE(API_URL.EVENT_APPLICATION.DELETE_EVENT_APPLICATION(row?.id))
        .then((response) => {
          toast.success("Deleted successfully");
          fetchEventApplication();
        })
        .catch((error) => {
          toast.error("Oops! something went wrong");
        });
    }
  };

  const handleSaveFilter = () => {
    filterToggle();
    const filteredData = data.filter((item) => item.state === statedata);
    setData(filteredData);
  };
  const handleCancelFilter = () => {
    filterToggle();
    setData(originalData);
  };

  const filterToggle = () => {
    setFilterModal(!filterModal);
  };

  const handleExport = () => {
    setCsvData(data);
  };

  return (
    <>
      <div className={`d-flex align-items-center justify-content-between  p-4`}>
        <H4 attrH4={{ className: "text-muted m-0" }}>Intrested Applications</H4>
        <div
          className="d-flex"
          style={{ width: "450px", justifyContent: "space-around" }}
        >
          <Btn attrBtn={{ color: "primary", onClick: applicationToggle }}>
            + New Application{" "}
          </Btn>

          <AddApplication
            isOpen={applicationModal}
            toggle={applicationToggle}
            title="Application"
            applicationData={editApplicationData}
            onSave={handleSave}
            onCancel={handleCancel}
          />

          <Btn attrBtn={{ color: "primary", onClick: filterToggle }}>
            Filters{" "}
          </Btn>
          <EditCommonModal
            isOpen={filterModal}
            toggle={filterToggle}
            title="Filter"
            onSave={handleSaveFilter}
            onCancel={handleCancelFilter}
          >
            <ApplicationFilter state={statedata} setState={setStatedata} />
          </EditCommonModal>
          <CSVLink
            data={csvData}
            filename="Applications-data.csv"
            className="btn btn-primary"
            onClick={handleExport}
          >
            Export
          </CSVLink>
        </div>
      </div>
      <div>
        <DataTable
          data={data}
          columns={applicationtableColumns(
            editmodalToggle,
            handleDeletedata,
            states,
          )}
          striped={true}
          center={true}
          pagination
          selectableRows={false}
          clearSelectedRows={toggleDelet}
        />
      </div>
    </>
  );
}

export default EventApplication;

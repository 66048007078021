import React, { Fragment, useCallback, useEffect, useState } from 'react';
import FormCommon from '../common/FormCommon';
import { API_URL } from '../config/api_urls';
import { get, patch, post } from '../config';
import { toast } from 'react-toastify';


const PaymentSettings = () => {
      
    const [data,setData] = useState([]); 
 console.log("data ", data);
    const [formDataConfig, setFormDataConfig] = useState({
        initialState: {
          id: null,
          razorpay_key_id: "",
          razorpay_secret: "",
          gst_percentage:""
        },
        fields: [
          {
            name: "razorpay_key_id",
            label: "Razorpay key",
            type: "text",
            placeholder: "Enter Razorpay key ID",
            required: true,
          },
          {
            name: "razorpay_secret",
            label: "Razorpay secret",
            type: "text",
            placeholder: "Enter Razorpay secret key",
            required: true,
          },
          {
            name: "gst_percentage",
            label: "GST(%)",
            type: "text",
            placeholder: "Enter GST(%)",
            required: true,
          }
        ],
      });


      useEffect(() => {
        fetchPaymentSettings();
      }, [])
      
      const fetchPaymentSettings = useCallback(() => {
        get(API_URL.PAYMENT_SETTINGS.GET_PAYMENT_SETTINGS)
          .then((response) => {
            if (response?.data.length > 0)
              setData(response.data);
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }, []);


      useEffect(()=>{
        if(data && data.length > 0){
          setFormDataConfig(prevValue => ({
            ...prevValue,
            initialState: data[0]
          }));
        }
        },[data])

              const handleSave = async (formData) => {
                if (formData?.id) {
                  patch(API_URL.PAYMENT_SETTINGS.PATCH_PAYMENT_SETTINGS(formData?.id), formData)
                    .then((response) => {
                      toast.success("Updated successfully");
                      fetchPaymentSettings();
                    })
                    .catch((error) => {
                      toast.error(error.response.data.gst_percentage[0]);
                    });
                }else{
                  try {
                    await post(API_URL.PAYMENT_SETTINGS.POST_PAYMENT_SETTINGS, formData);
                    toast.success("Payment Settings Created successfully");
                    fetchPaymentSettings();
                  } catch (error) {
                    console.error("Error saving data:", error);
                    toast.error("Oops! something went wrong");
                  }
                }
                
              };


    return (
        <div>
        <FormCommon
        isModal={false}
        title={`Payment Settings`}
        formDataConfig={formDataConfig}
        onSave={handleSave}
        // editMode={!!formDataConfig?.initialState?.id}
      />
    </div>
    );
};

export default PaymentSettings;
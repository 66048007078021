import React from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { H5, UL, LI } from '../../../AbstractElements';
import { Earning, Expense, OverallBalanceTitle } from '../../../Constant';
import LightCard from './LitghtCard';
import ReactApexChart from 'react-apexcharts';
import { CurrencyChartData } from '../../../Data/DefaultDashboard/Chart';
import { LightCardData } from '../../../Data/DefaultDashboard';
const OverallBalance = () => {
  return (
    <Col xxl='8' lg='12' className='box-col-12'>
      <Card>
      </Card>
    </Col>
  );
};

export default OverallBalance;

import React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';

const OrderFilter = ({ orderStatus, setOrderStatus, paymentStatus, setPaymentStatus }) => {
    return (
        <>
            <FormGroup>
                <Label for="orderStatus">Order Status</Label>
                <Input 
                    type="select" 
                    name="orderStatus" 
                    id="orderStatus" 
                    className="form-control digits" 
                    value={orderStatus}
                    onChange={(e) => setOrderStatus(e.target.value)} 
                    required
                >
                    <option value="" selected disabled >Select Order Status</option>
                    <option value="delivered" >Delivered</option>
                    {/* <option value="Shipped" >Shipped</option> */}
                    <option value="pending" >Pending</option>
                    {/* <option value="Cancelled" >Cancelled</option> */}
                   
                </Input>
            </FormGroup>
            <FormGroup>
                <Label for="paymentStatus">Payment Status</Label>
                <Input 
                    type="select" 
                    name="paymentStatus" 
                    id='paymentStatus'  
                    className="form-control digits" 
                    value={paymentStatus}
                    onChange={(e) => setPaymentStatus(e.target.value)} 
                    required
                >
                    <option value="" selected disabled >Select payment Status</option>
                    <option  value="paid" >Paid</option>
                    <option  value="unpaid" >Unpaid</option>
                   
                </Input>
            </FormGroup>
        </>
    );
};

export default OrderFilter;

import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardHeader, Table } from 'reactstrap';
import { H5, Image } from '../../../AbstractElements';
import { DailyDropdown, ValuableCustomerTitle } from '../../../Constant';
import { ValuableCustomerData } from '../../../Data/Ecommerce';
import DropdownCommon from '../../Common/Dropdown';

const ValuableCustomer = () => {
  return (
    <div className='appointment'>
    </div>
  );
};

export default ValuableCustomer;

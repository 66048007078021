import React, { useCallback, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { dummytabledata, tableColumns } from '../common/mockData';
import { useNavigate } from 'react-router';
import { Btn, H4 } from '../../../../AbstractElements';
import { DELETE, get } from '../config';
import { API_URL } from '../config/api_urls';
import { toast } from 'react-toastify';

function MarketPlace() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
 console.log("data ", data);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleDelet, setToggleDelet] = useState(false);
  const [editMarketPlace,setEditMarketPlace] = useState();

useEffect(()=>{
 if(editMarketPlace){
  navigate(`${process.env.PUBLIC_URL}/market-place/add-new/:layout`,{ state: { marketplace: editMarketPlace } })
 }
},[editMarketPlace,navigate])


  const handleRowSelected = useCallback(state => {
    setSelectedRows(state.selectedRows);
}, []);


useEffect(()=>{
  fetchMarketPlaces();
},[])


const fetchMarketPlaces = useCallback(() => {
  get(API_URL.MARKET_PRPDUCTS.GET_MARKET_PRPDUCTS)
    .then((response) => {
      if (response?.data.length >= 0) {
        setData(response.data);
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}, []);


 const handleNavigate = ()=>{
  navigate(`${process.env.PUBLIC_URL}/market-place/add-new/:layout`)
 }
 const handleEditdata =(row)=>{
  setEditMarketPlace(row)
 }

 const handleDeletedata = (row) => {
  if (row?.uuid) {
    DELETE(API_URL.MARKET_PRPDUCTS.DELETE_MARKET_PRPDUCTS(row?.uuid))
      .then((response) => {
        toast.success("Deleted successfully");
        fetchMarketPlaces();
      })
      .catch((error) => {
        toast.error("Oops! something went wrong");
      });
  }
}


  return (
    <>
       <div className={`d-flex align-items-center justify-content-between  p-4`}>
                    <H4 attrH4={{ className: 'text-muted m-0' }}>All MarketPlace Details</H4>
                    <Btn attrBtn={{ color: 'primary', onClick: () => handleNavigate() }}>+ Add New Market Place</Btn>
                </div>
    <div>
       <DataTable
                data={data}
                columns={tableColumns(handleEditdata,handleDeletedata)}
                striped={true}
                center={true}
                pagination
                selectableRows={false}
                onSelectedRowsChange={handleRowSelected}
                clearSelectedRows={toggleDelet}
            />
    </div>
    </>
   
  )
}

export default MarketPlace
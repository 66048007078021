import React from 'react';
import { Card, CardBody, CardHeader, Col } from 'reactstrap';
import { H5, H6, UL, LI, P } from '../../../AbstractElements';
import { Activity, DailyDropdown } from '../../../Constant';
import DropdownCommon from '../../Common/Dropdown';

const ActivityCard = () => {
  return (
    <Col xxl='4' xl='5' md='6' sm='7' className='notification box-col-6'>
      <Card className='height-equal'>
      </Card>
    </Col>
  );
};

export default ActivityCard;

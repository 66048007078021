import React, { useCallback, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { usercategorytabledata, usercategorytableColumns } from '../common/mockData';
import { Btn, H4 } from '../../../../AbstractElements';
import EditCommonModal from '../common/EditCommonModal';
import NewUserCategory from './NewUserCategory';
import { API_URL } from '../config/api_urls';
import { DELETE, get, patch, post } from '../config';
import { toast } from 'react-toastify';
import { constructFormData } from '../config/imageHandle';

function UserCategory() {
  const [data, setData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleDelet, setToggleDelet] = useState(false);
  const [userCategorymodal,setUserCategorymodal] = useState(false);
  const [editUsersCategoryData,setEditUsersCategoryData] = useState();

  const handleRowSelected = useCallback(state => {
    setSelectedRows(state.selectedRows);
}, []);

useEffect(() => {
  fetchUserCategory();
}, [])

const fetchUserCategory = useCallback(() => {
  get(API_URL.USER_CATEGORY.USER_CATEGORY_GET)
    .then((response) => {
      if (response?.data.length >= 0)
        setData(response.data)
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}, []);


  const userCategoryToggle =()=>{
    setUserCategorymodal(!userCategorymodal);
    setEditUsersCategoryData(null);
  }

  const editmodalToggle = (row) => {
    setUserCategorymodal(!userCategorymodal);
    setEditUsersCategoryData(row);
  };

  
 

  const handleSave = async (formData) => {
		if (formData?.id) {
			patch(API_URL.USER_CATEGORY.USER_CATEGORY_PATCH(formData?.id), formData)
				.then((response) => {
					toast.success("Updated successfully");
          setUserCategorymodal(!userCategorymodal);
          setEditUsersCategoryData(null);
          fetchUserCategory();
				})
				.catch((error) => {
					console.error("Error updating data:", error);
					toast.error("Oops! something went wrong");
				});
		}else{
      try {
        await post(API_URL.USER_CATEGORY.USER_CATEGORY_POST, formData);
        toast.success("User created successfully");
        setUserCategorymodal(!userCategorymodal);
        setEditUsersCategoryData(null);
        fetchUserCategory();
      } catch (error) {
        console.error("Error saving data:", error);
        toast.error("Oops! something went wrong");
      }
    }
		
	};


  const handleDeletedata = (row)=>{
    if(row?.id){
     DELETE(API_URL.USER_CATEGORY.USER_CATEGORY_DELETE(row?.id))
     .then((response) => {
       toast.success("Deleted successfully");
       fetchUserCategory();
     })
     .catch((error) => {
       toast.error("Oops! something went wrong");
     });
    }
   }

  

  
  const handleCancel = () => {
    setUserCategorymodal(!userCategorymodal);
    setEditUsersCategoryData(null);
  };

  



  return (
    <>
       <div className={`d-flex align-items-center justify-content-between  p-4`}>
                    <H4 attrH4={{ className: 'text-muted m-0' }}>User Category</H4>
                    <Btn attrBtn={{ color: 'primary',onClick: userCategoryToggle}}>+ Add New Category</Btn>
                   
                      <NewUserCategory 
                        isOpen={userCategorymodal}
                        toggle={userCategoryToggle}
                        title="User Category"
                        userCategoryData={editUsersCategoryData}
                        onSave={handleSave}
                        onCancel={handleCancel}
                      />
                </div>
    <div>
       <DataTable
                data={data}
                columns={usercategorytableColumns(editmodalToggle,handleDeletedata)}
                striped={true}
                center={true}
                pagination
                selectableRows={false}
                onSelectedRowsChange={handleRowSelected}
                clearSelectedRows={toggleDelet}
            />
    </div>
    </>
   
  )
}

export default UserCategory;



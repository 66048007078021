import React from 'react';
import { Dialog, DialogTitle, DialogContent, Button, Grid } from '@mui/material';

const specialCharacters = [
  "©", "®", "™", "°", "±", "÷", "×", "≈", "√", "∞","~","!","#","$","%","^","&","*",
  // Add more special characters as needed
];

const SpecialCharacterDialog = ({ open, onClose, onSelect }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Select Special Character</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {specialCharacters.map((char, index) => (
            <Grid item xs={4} key={index}>
              <Button
                variant="outlined"
                fullWidth
                onClick={() => onSelect(char)}
              >
                {char}
              </Button>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default SpecialCharacterDialog;

import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import FormCommon from '../common/FormCommon';
import { API_URL } from '../config/api_urls';
import { constructFormData } from '../config/imageHandle';
import { get, patch, post, DELETE } from "../config/index";
import { toast } from 'react-toastify';

const NewUser = () => {
	const location = useLocation();
	const editUserData = location.state?.userData;
	const navigate = useNavigate();
   
	const [isEditing, setIsEditing] = useState(false);
	const [formDataConfig, setFormDataConfig] = useState({
		initialState: {
			uuid: null,
			profile_image: "",
			cover_image: "",
			name: "",
			email: "",
			whatsapp_number: "",
			date_of_birth: "",
			gender: "",
			user_role_id: "",
			category_id: "",
			state_id: "",
			country_id: "",
			hearaboutus_id: "",
			age: "",
			is_featured: "",
			is_blocked: ""
		},
		fields: [
			{
				name: "profile_image",
				label: "Profile Image",
				type: "file",
				required: false,
			},
			{
				name: "cover_image",
				label: "Cover Image",
				type: "file",
				required: false,
			},
			{
				name: "name",
				label: "Name",
				type: "text",
				placeholder: "Enter FullName",
				required: true,
			},
			{
				name: "email",
				label: "Email",
				type: "email",
				placeholder: "Enter Email Id",
				required: true,
			},
			{
				name: "whatsapp_number",
				label: "Whatsapp Number",
				type: "tel",
				placeholder: "Enter Whatsapp Number",
				required: true,
			},
			{
				name: "date_of_birth",
				label: "DOB",
				type: "date",
				placeholder: "Select DOB",
				required: true,
			},
			{
				name: "gender",
				label: "Gender",
				type: "select",
				options: [
					{ disabled: true, selected: "true", value: "", label: "select gender" },
					{ value: "Male", label: "Male" },
					{ value: "Female", label: "Female" },
					{ value: "Other", label: "Other" }
				],
				required: true,
			},
			{
				name: "user_role_id",
				label: "User Role",
				type: "select",
				options: [],
				required: true,
			},
			{
				name: "category_id",
				label: "User Category",
				type: "select",
				options: [],
				required: true,
			},
			{
				name: "state_id",
				label: "State",
				type: "select",
				options: [],
				required: true,
			},
			{
				name: "country_id",
				label: "Country",
				type: "select",
				options: [],
				required: true,
			},
			{
				name: "hearaboutus_id",
				label: "Hear Aboutus",
				type: "select",
				options: [],
				required: true,
			},
			{
				name: "age",
				label: "Age",
				type: "number",
				placeholder: "Enter Age",
				required: true,
			},
			{
				name: "is_featured",
				label: "is_featured",
				type: "checkbox",
				required: false,
			},
			{
				name: "is_blocked",
				label: "is_blocked",
				type: "checkbox",
				required: false,
			}
		] || [],
		imageField: "image",
	});

	
	useEffect(() => {
		if (editUserData) {
			setIsEditing(true);
			setFormDataConfig(value => ({
				...value,
				initialState: {
					...editUserData,
					country_id: editUserData?.country_id,
					state_id: editUserData?.state_id,
					user_role_id: editUserData?.user_role_id,
					category_id: editUserData?.category_id,
					hearaboutus_id: editUserData?.hearaboutus_id,
					profile_image: editUserData?.profile_image,
					cover_image: editUserData?.cover_image
				}
			}));
		} else {
			setIsEditing(false);
			setFormDataConfig(value => ({
				...value,
				initialState: {
					uuid: null,
					profile_image: "",
					cover_image: "",
					name: "",
					email: "",
					whatsapp_number: "",
					date_of_birth: "",
					gender: "",
					user_role_id: "",
					category_id: "",
					state_id: "",
					country_id: "",
					hearaboutus_id: "",
					age: "",
					is_featured: "",
					is_blocked: ""
				},
			}));
		}
	}, [editUserData]);


	useEffect(() => {
		fetchuserroles();
		fetchusercategory();
		fetchstates();
		fetchcountries();
		fetchhearaboutus();
	}, [])


	const handleNavigateToUsers = () => {
		navigate(`${process.env.PUBLIC_URL}/users/list/:layout`)
	}

	//user role dropdown

	const fetchuserroles = useCallback(async () => {
		try {
			const response = await get(API_URL.USER_ROLE.USER_ROLE_GET);
			const user_role = response?.data?.map((i) => ({
				label: i.role,
				value: i.id,
			}));
			user_role.unshift({ label: "Select user role", value: "" });
			if (user_role.length > 0) {
				setFormDataConfig((prevConfig) => ({
					...prevConfig,
					fields: prevConfig.fields.map((field) =>
						field.name === "user_role_id" ? { ...field, options: user_role } : field
					),
				}));
			}

		} catch (error) {
			console.error("Error:", error);
		}
	}, []);

	// user category dropdown
	const fetchusercategory = useCallback(async () => {
		try {
			const response = await get(API_URL.USER_CATEGORY.USER_CATEGORY_GET);
			const user_category = response?.data?.map((i) => ({
				label: i.name,
				value: i.id,
			}));
			user_category.unshift({ label: "Select user category", value: "" });
			if (user_category.length > 0) {
				setFormDataConfig((prevConfig) => ({
					...prevConfig,
					fields: prevConfig.fields.map((field) =>
						field.name === "category_id" ? { ...field, options: user_category } : field
					),
				}));
			}
		} catch (error) {
			console.error("Error:", error);
		}
	}, []);

	// states dropdown
	const fetchstates = useCallback(async () => {
		try {
			const response = await get(API_URL.STATES.STATES_GET);
			const states = response?.data?.map((i) => ({
				label: i.name,
				value: i.id,
			}));
			states.unshift({ label: "Select state", value: "" });
			if (states.length > 0) {
				setFormDataConfig((prevConfig) => ({
					...prevConfig,
					fields: prevConfig.fields.map((field) =>
						field.name === "state_id" ? { ...field, options: states } : field
					),
				}));
			}
		} catch (error) {
			console.error("Error:", error);
		}
	}, []);

	// country dropdown
	const fetchcountries = useCallback(async () => {
		try {
			const response = await get(API_URL.COUNTRIES.COUNTRIES_GET);
			const countries = response?.data?.map((i) => ({
				label: i.name,
				value: i.id,
			}));
			countries.unshift({ label: "Select country", value: "" });
			if (countries.length > 0) {
				setFormDataConfig((prevConfig) => ({
					...prevConfig,
					fields: prevConfig.fields.map((field) =>
						field.name === "country_id" ? { ...field, options: countries } : field
					),
				}));
			}
		} catch (error) {
			console.error("Error:", error);
		}
	}, []);

	// hearaboutus dropdown
	const fetchhearaboutus = useCallback(async () => {
		try {
			const response = await get(API_URL.HEAR_ABOUT_US.HEAR_ABOUT_US_GET);
			const hearaboutus = response?.data?.map((i) => ({
				label: i.aboutus,
				value: i.id,
			}));
			hearaboutus.unshift({ label: "Select hearaboutus", value: "" });
			if (hearaboutus.length > 0) {
				setFormDataConfig((prevConfig) => ({
					...prevConfig,
					fields: prevConfig.fields.map((field) =>
						field.name === "hearaboutus_id" ? { ...field, options: hearaboutus } : field
					),
				}));
			}
		} catch (error) {
			console.error("Error:", error);
		}
	}, []);

	

	const onSave = (formData) => {
		const data = constructFormData(formData, ['cover_image', 'profile_image']);
		
		if (formData?.uuid) {
			patch(API_URL.USERS.USERS_PATCH(formData?.uuid), data)
				.then((response) => {
					toast.success("Updated successfully");
					handleNavigateToUsers();
				})
				.catch((error) => {
					console.error("Error updating data:", error);
					toast.error("Oops! something went wrong, try again");
				});
		} else {
			post(API_URL.USERS.USERS_POST, data)
				.then((response) => {
					toast.success("User Registered successfully");
					handleNavigateToUsers();
				})
				.catch((error) => {
					console.error("Error saving data:", error);
					toast.error("Oops! something went wrong, try again");
				});
		}
	};


	

	return (
		<div>
			<FormCommon
				isModal={false}
				title={`${editUserData ? 'Edit' : 'Add'} User Details`}
				formDataConfig={formDataConfig}
				onSave={onSave}
			// editMode={!!formDataConfig?.initialState?.id}
			/>
		</div>
	);
};

export default NewUser;
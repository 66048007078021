import React, { Fragment, useCallback, useEffect, useState } from 'react';
import FormCommon from '../common/FormCommon';
import { get, patch, post } from '../config';
import { API_URL } from '../config/api_urls';
import { toast } from 'react-toastify';

const Whatsapp = () => {

    const [data, setData] = useState([]);
 console.log("data ", data);
    const [formDataConfig, setFormDataConfig] = useState({
        initialState: {
          id: null,
          whatsapptext: ""
        },
        fields: [
          {
            name: "whatsapptext",
            label: "Whatsapp Text",
            type: "text",
            placeholder: "Enter Whatsapp Text",
            required: true,
          }
        ]
      });


      useEffect(() => {
        fetchWhatsappSettings();
      }, [])
      
      const fetchWhatsappSettings = useCallback(() => {
        get(API_URL.WHATSAPP_SETTINGS.GET_WHATSAPP_SETTINGS)
          .then((response) => {
            if (response?.data)
              setData(response.data);
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }, []);

      

     useEffect(()=>{
  if(data){
  setFormDataConfig(prevValue => ({
    ...prevValue,
    initialState: data
  }));
}
},[data])



      const handleSave = async (formData) => {
 console.log("formData ", formData);
        
        if (formData?.id) {
          patch(API_URL.WHATSAPP_SETTINGS.PATCH_WHATSAPP_SETTINGS(formData?.id), formData)
            .then((response) => {
              toast.success("Updated successfully");
              fetchWhatsappSettings();
            })
            .catch((error) => {
              console.error("Error updating data:", error);
              toast.error("Oops! something went wrong");
            });
        }else{
          try {
            await post(API_URL.WHATSAPP_SETTINGS.POST_WHATSAPP_SETTINGS, formData);
            toast.success("Whatsapp Settings Submited successfully");
            fetchWhatsappSettings();
          } catch (error) {
            console.error("Error saving data:", error);
            toast.error("Oops! something went wrong");
          }
        }
      };

    return (
        <div>
        <FormCommon
        isModal={false}
        title={`Whatsapp Settings`}
        formDataConfig={formDataConfig}
        onSave={handleSave}
      />
    </div>
    );
};

export default Whatsapp;
import React, { useEffect, useState } from 'react'
import FormCommon from '../common/FormCommon';
import EditCommonModal from '../common/EditCommonModal';
import { FormGroup, Input, Label } from 'reactstrap';

function NewJobtype({jopTypeData,isOpen,toggle,title,onSave,onCancel}) {

    const [isEditing,setIsEditing] = useState(false);
    const [formDataConfig, setFormDataConfig] = useState({
            initialState: {
              id: null,
              name: ""
            },
            fields: [
              {
                name: "name",
                label: "Event Type",
                type: "text",
                placeholder:"Enter the event Type",
                required: true,
              }
            ],
          });

          // useEffect(()=>{
          //   setIsEditing(jopTypeData ? true : false);
          //   setFormDataConfig(value=>{
          //   return jopTypeData ? {
          //     ...value,
          //     initialState:jopTypeData,
          //   }:
          //   value
          //   })
          // },[jopTypeData])


          
          useEffect(() => {
            if (jopTypeData) {
              setIsEditing(true);
              setFormDataConfig(value => ({
                ...value,
                initialState: jopTypeData,
              }));
            } else {
              setIsEditing(false);
              setFormDataConfig(value => ({
                ...value,
                initialState: {
                  id: null,
                  name: '',
                },
              }));
            }
          }, [jopTypeData, isOpen]);  
        
        
            const handleSave = () => {
              onSave(formDataConfig.initialState);
            };
                  
            const handleCancel = () => {
              console.log("handleCancel");
              onCancel();   
            };
          
    const setEventType =(e)=>{
      setFormDataConfig({
        ...formDataConfig,
        initialState: {
          ...formDataConfig.initialState,
          name: e.target.value
        }
      })
    }




  return (
    <div>
          <EditCommonModal
            isOpen={isOpen}
            toggle={toggle}
            title={`${isEditing ? 'Edit' : 'Add'} ${title}`}
            onSave={handleSave}
            onCancel={handleCancel}
            formDataConfig={formDataConfig}
    > 
       <div>
       <FormGroup>
					<Label className="col-form-label pt-0" >Event Type</Label>
					<Input value={formDataConfig.initialState.name} onChange={setEventType} className="form-control" type="text" placeholder="Enter Event Type" />

				</FormGroup>
      </div>

    </EditCommonModal>
    </div>
  )
}

export default NewJobtype
export const Switchcolor = [
    {
        id: 1,
        title: 'Primary Color',
        spanclass: 'switch-state bg-primary',
        mediaclass: ' icon-state switch-outline'
    },
    // {
    //     id: 2,
    //     title: 'Secondary Color',
    //     spanclass: 'switch-state bg-secondary',
    //     mediaclass: 'text-end icon-state switch-outline'
    // },
    // {
    //     id: 3,
    //     title: 'Success Color',
    //     spanclass: 'switch-state bg-success',
    //     mediaclass: 'text-end icon-state switch-outline'
    // },
    // {
    //     id: 4,
    //     title: 'Info Color',
    //     spanclass: 'switch-state bg-info',
    //     mediaclass: 'text-end icon-state switch-outline'
    // },
    // {
    //     id: 5,
    //     title: 'Warning Color',
    //     spanclass: 'switch-state bg-warning',
    //     mediaclass: 'text-end icon-state switch-outline'
    // },
    // {
    //     id: 6,
    //     title: 'Danger Color',
    //     spanclass: 'switch-state bg-danger',
    //     mediaclass: 'text-end icon-state switch-outline'
    // }
];

export const BasicSwitchData = [
    {
        id: 1,
        title: 'Default Switch',
        mediaclass: 'text-end'
    },
    {
        id: 2,
        title: 'Unhecked Switch',
        mediaclass: 'text-end'
    },
    {
        id: 3,
        title: 'With Icon',
        mediaclass: 'text-end icon-state'
    },
    {
        id: 4,
        title: 'Unhecked With Icon',
        mediaclass: 'text-end icon-state'
    },
    {
        id: 5,
        title: 'Disabled State',
        mediaclass: 'text-end',
        disabled: true
    },
    {
        id: 6,
        title: 'Disabled With Icon',
        mediaclass: 'text-end icon-state',
        disabled: true
    }
];

export const Switchwithcolor = [
    {
        id: 1,
        title: 'Primary Color (Disabled)',
        spanclass: 'switch-state bg-primary',
        mediaclass: 'text-end icon-state switch-lg',
        disabled: true
    },
    {
        id: 2,
        title: 'Secondary Color',
        spanclass: 'switch-state bg-secondary',
        mediaclass: 'text-end icon-state switch-lg'
    },
    {
        id: 3,
        title: 'Success Color',
        spanclass: 'switch-state bg-success',
        mediaclass: 'text-end icon-state'
    },
    {
        id: 4,
        title: 'Info Color',
        spanclass: 'switch-state bg-info',
        mediaclass: 'text-end icon-state'
    },
    {
        id: 5,
        title: 'Warning Color',
        spanclass: 'switch-state bg-warning',
        mediaclass: 'text-end icon-state switch-sm'
    },
    {
        id: 6,
        title: 'Danger Color',
        spanclass: 'switch-state bg-danger',
        mediaclass: 'text-end icon-state switch-sm'
    }
];

export const SwitchSizingData = [
    {
        id: 1,
        title: 'Large Size',
        spanclass: 'switch-state',
        mediaclass: 'text-end switch-lg',
        mediaclass1: 'text-end switch-lg icon-state'
    },
    {
        id: 2,
        title: 'Large Unhecked',
        spanclass: 'switch-state',
        mediaclass: 'text-end switch-lg',
        mediaclass1: 'text-end switch-lg icon-state'
    },
    {
        id: 3,
        title: 'Normal Size',
        spanclass: 'switch-state',
        mediaclass: 'text-end ',
        mediaclass1: 'text-end icon-state'
    },
    {
        id: 4,
        title: 'Normal Unhecked',
        spanclass: 'switch-state',
        mediaclass: 'text-end ',
        mediaclass1: 'text-end icon-state'
    },
    {
        id: 5,
        title: 'Small Size',
        spanclass: 'switch-state',
        mediaclass: 'text-end switch-sm',
        mediaclass1: 'text-end switch-sm icon-state'
    },
    {
        id: 6,
        title: 'Small Size Unhecked',
        spanclass: 'switch-state',
        mediaclass: 'text-end switch-sm',
        mediaclass1: 'text-end switch-sm icon-state'
    }
];
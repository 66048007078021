import React, { Fragment } from 'react';
import CardHeader from 'react-bootstrap/esm/CardHeader';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col } from 'reactstrap';
import { H5, UL, LI, Image, P } from '../../../AbstractElements';
import { BlogMessage, Inprogress, ViewProject } from '../../../Constant';
import blogImage from '../../../assets/images/dashboard/papernote.jpg';
import { NoteLabels } from '../../../Data/DefaultDashboard';

const PaperNote = () => {
  return (
    <Col xxl='5' lg='8' md='11' className='box-col-8 col-ed-6'>
      <Card className=' papernote-wrap'>
      </Card>
    </Col>
  );
};

export default PaperNote;

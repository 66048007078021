import React, { Fragment, useState } from 'react';
import FormCommon from '../common/FormCommon';

const Notification = () => {

    const [formDataConfig, setFormDataConfig] = useState({
        initialState: {
          id: null,
          user: "",
          user_role: "",
          title: "",
          message: "",
        },
        fields: [
         
          {
            name: "user",
            label:"User",
            type: "select",
            options:[
                {value:"",label:"Select User", disabled: true},
                {value:'Jyothis',label:"Jyothis"},
                {value:'Lena',label:"Lena"},
                {value:'Soumya',label:"Soumya"}
            ],
            required: false,
          },
          {
            name: "user_role",
            label: "User Role",
            type: "select",
            options:[
                {value:"",label:"select user Role",disabled: true},
                {value:'model',label:"model"},
                {value:'model',label:"model"},
                {value:'model',label:"model"}
            ],
            required: true,
          },
          {
            name: "title",
            label: "Title",
            type: "text",
            placeholder: "Enter Title",
            required: true,
          },
          {
            name: "message",
            label: "Message",
            type: "text",
            placeholder: "Enter Message",
            required: false,
          }
        ],
        imageField: "image",
      });


    return (
        <div>
        <FormCommon
        isModal={false}
        title={`Notification`}
        formDataConfig={formDataConfig}
        // onSave={onSave}
        // editMode={!!formDataConfig?.initialState?.id}
      />
    </div>
    );
};

export default Notification;




import React from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { H5, UL, LI, Image } from '../../../AbstractElements';
import { FacebookCampaignTitle, FollowersGoals, FollowersGoalsTotal, FollowersToday, FollowersTodayTotal } from '../../../Constant';

const FacebookCampaign = () => {
  const customersList = ['1', '6', '7', '3', '8'];
  return (
    <Card className=' follower-wrap'>
      <CardHeader className='card-no-border'>
        <H5 className='mb-1'>{FacebookCampaignTitle}</H5>
        <div className='d-inline-block badge badge-light-success rounded-pill'>Active</div>
      </CardHeader>
    </Card>
  );
};

export default FacebookCampaign;

import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Table } from 'reactstrap';
import { Image, H5, P } from '../../../AbstractElements';
import { DailyDropdown, RecentSalesTitle } from '../../../Constant';
import { RecentSalesData } from '../../../Data/DefaultDashboard';
import DropdownCommon from '../../Common/Dropdown';

const RecentSales = () => {
  return (
    <Col xxl='4' md='6' className='appointment-sec box-col-6'>
      <div className='appointment'>
        <Card>
        </Card>
      </div>
    </Col>
  );
};

export default RecentSales;

import React, { useEffect, useState } from 'react'
import FormCommon from '../common/FormCommon';
import EditCommonModal from '../common/EditCommonModal';
import { FormGroup, Input, Label } from 'reactstrap';

function NewJobCategory({eventCategoryData,isOpen,toggle,title,onSave,onCancel}) {


   const [isEditing,setIsEditing] = useState(false);
    const [formDataConfig, setFormDataConfig] = useState({
            initialState: {
              id: null,
              name: ""
            },
            fields: [
              {
                name: "name",
                label: "Event Category",
                type: "text",
                placeholder:"Enter Event category",
                required: true,
              }
            ],
          });

          useEffect(() => {
            if (eventCategoryData) {
              setIsEditing(true);
              setFormDataConfig(value => ({
                ...value,
                initialState: eventCategoryData,
              }));
            } else {
              setIsEditing(false);
              setFormDataConfig(value => ({
                ...value,
                initialState: {
                  id: null,
                  name: '',
                },
              }));
            }
          }, [eventCategoryData, isOpen]);  
          
          
    
    const handleSave = () => {
      onSave(formDataConfig.initialState);
    };
          
    const handleCancel = () => {
      console.log("handleCancel");
      onCancel();   
    }; 

    const setEventCategory = (e)=>{
      setFormDataConfig({
        ...formDataConfig,
        initialState: {
          ...formDataConfig.initialState,
          name: e.target.value
        }
      })
    }



  return (
    <EditCommonModal
    isOpen={isOpen}
    toggle={toggle}
    title={`${isEditing ? 'Edit' : 'Add'} ${title}`}
    onSave={handleSave}
    onCancel={handleCancel}
    formDataConfig={formDataConfig}
    > 
       <div>
       <FormGroup>
					<Label className="col-form-label pt-0" >Event Category</Label>
					<Input value={formDataConfig.initialState.name} onChange={setEventCategory} className="form-control" type="text" placeholder="Enter Event Category" />

				</FormGroup>
      </div>

    </EditCommonModal>
  )
}

export default NewJobCategory
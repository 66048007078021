import React, { useCallback, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { userpreferencesdummydata, userpreferencestableColumns } from '../common/mockData';
import { Btn, H4 } from '../../../../AbstractElements';
import NewUserPreference from './NewUserPreference';
import { API_URL } from '../config/api_urls';
import { DELETE, get, patch, post } from '../config';
import { toast } from 'react-toastify';

function UserPreference() {
  const [data, setData] = useState([]);
  const [users,setUsers] = useState([]);
 console.log("users ", users);
  const [toggleDelet, setToggleDelet] = useState(false);
  const [userPreferenceModal,setUserPreferenceModal] = useState(false);
  const [editUserPreference, setEditUserPreference] = useState();


  useEffect(() => {
    fetchUserPreferences();
    fetchUsers();
  }, [])
  
  const fetchUserPreferences = useCallback(() => {
    get(API_URL.USER_PREFERENCES.GET_USER_PREFERENCES)
      .then((response) => {
        if (response?.data.length > 0)
          setData(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);


  const fetchUsers = useCallback(() => { 
    get(API_URL.USERS.GET_USERS)
      .then((response) => {
        if (response?.data.length > 0) {
         setUsers(response?.data)
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);


 const userpreferenceToggle = ()=>{
  setUserPreferenceModal(!userPreferenceModal);
 }
 const editmodalToggle = (row)=>{
  setEditUserPreference(row)
  setUserPreferenceModal(!userPreferenceModal);
 }
 
const handleCancel = () => {
  setEditUserPreference(null)
  setUserPreferenceModal(!userPreferenceModal);
};

const handleSave = async (formData) => {
  const values = {
    id: formData?.id || '',
    user_uuid: formData?.user_name?.value,
    preference_id: formData?.preference_name?.value
  }
  
  if (formData?.id) {
    patch(API_URL.USER_PREFERENCES.PATCH_USER_PREFERENCES(formData?.id), values)
      .then((response) => {
        toast.success("Updated successfully");
        setEditUserPreference(null)
        setUserPreferenceModal(!userPreferenceModal);
        fetchUserPreferences();
      })
      .catch((error) => {
        console.error("Error updating data:", error);
        toast.error("Oops! something went wrong");
      });
  }else{
    try {
      await post(API_URL.USER_PREFERENCES.POST_USER_PREFERENCES, values);
      toast.success("user preference created successfully");
      setEditUserPreference(null)
      setUserPreferenceModal(!userPreferenceModal);
      fetchUserPreferences();
    } catch (error) {
      console.error("Error saving data:", error);
      toast.error("Oops! something went wrong");
    }
  }
  
};


const handleDeletedata = (row)=>{
  if(row?.id){
   DELETE(API_URL.USER_PREFERENCES.DELETE_USER_PREFERENCES(row?.id))
   .then((response) => {
     toast.success("Deleted successfully");
     fetchUserPreferences();
   })
   .catch((error) => {
     toast.error("Oops! something went wrong");
   });
  }
 }







  return (
    <>
       <div className={`d-flex align-items-center justify-content-between  p-4`}>
                    <H4 attrH4={{ className: 'text-muted m-0' }}>All User Preferences </H4>
                    <Btn attrBtn={{ color: 'primary', onClick:userpreferenceToggle }}>+ New User Preference</Btn>
                   
                      <NewUserPreference
                      editUserPreferenceData = {editUserPreference}
                      isOpen={userPreferenceModal}
                      toggle={userpreferenceToggle}
                      title="User Preference"
                      onSave={handleSave}
                      onCancel={handleCancel}
                      />
                </div>
    <div>
       <DataTable
                data={data}
                columns={userpreferencestableColumns(editmodalToggle,handleDeletedata)}
                striped={true}
                center={true}
                pagination
                selectableRows={false}
                clearSelectedRows={toggleDelet}
            />
    </div>
    </>
   
  )
}

export default UserPreference
import axios from "./axios";

const post = (api, data, config) => {
  return new Promise((resolve, reject) => {
    axios({ headers: { "Content-Type": "multipart/form-data" } })
      .post(api, data, config)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        console.error("err ", err);
        reject(err);
      });
  });
};

const get = (api, config) => {
  return new Promise((resolve, reject) => {
    axios()
      .get(api, config)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        console.error("err ", err);
        reject(err);
      });
  });
};

const put = (api, data, config) => {
  return new Promise((resolve, reject) => {
    axios({ headers: { "Content-Type": "multipart/form-data" } })
      .put(api, data, config)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        console.error("err ", err);
        reject(err);
      });
  });
};

const DELETE = (api, config) => {
  return new Promise((resolve, reject) => {
    axios()
      .delete(api, config)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        console.error("err ", err);
        reject(err);
      });
  });
};

const patch = (api, data, config) => {
  return new Promise((resolve, reject) => {
    axios({ headers: { "Content-Type": "multipart/form-data" } })
      .patch(api, data, config)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        console.error("err ", err);
        reject(err);
      });
  });
};

export { post, get, put, DELETE, patch };


import React, { useEffect, useState } from 'react'
import EditCommonModal from '../common/EditCommonModal';
import { FormGroup, Input, Label } from 'reactstrap';

function AddMarketCategory({marketCategoryData,isOpen,toggle,title,onSave,onCancel}) {

    const [isEditing,setIsEditing] = useState(false);
    const [formDataConfig, setFormDataConfig] = useState({
            initialState: {
              id: null,
              name: ""
            },
            fields: [
              {
                name: "name",
                label: "Market Category",
                type: "text",
                placeholder:"Enter Market Category",
                required: true,
              }
            ]
          });

          useEffect(()=>{
            setIsEditing(marketCategoryData ? true : false);
            setFormDataConfig(value=>{
            return marketCategoryData ? {
              ...value,
              initialState:marketCategoryData,
            }:
            value
            })
          },[marketCategoryData])   
   
    const handleSave = () => {
      onSave(formDataConfig.initialState);
    };
          
    const handleCancel = () => {
      onCancel();   
    };    

    
    const setProductCategory = (e) => {
      setFormDataConfig({
        ...formDataConfig,
        initialState: {
          ...formDataConfig.initialState,
          name: e.target.value
        }
      })
    }


  return (
    <EditCommonModal
    isOpen={isOpen}
    toggle={toggle}
    title={`${isEditing ? 'Edit' : 'Add'} ${title}`}
    onSave={handleSave}
    onCancel={handleCancel}
    > 
       <div className='mb-4'>
				<FormGroup>
					<Label className="col-form-label pt-0" >Product Category</Label>
					<Input value={formDataConfig.initialState.name} onChange={setProductCategory} className="form-control" type="text" placeholder="Enter Product Category" />

				</FormGroup>
			</div>

    </EditCommonModal>
  )
}

export default AddMarketCategory
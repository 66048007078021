import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import FormCommon from '../common/FormCommon';
import { API_URL } from '../config/api_urls';
import { patch } from '../config';
import { toast } from 'react-toastify';

const EditOrder = () => {
    const location = useLocation();
    const ordersData = location.state?.orderData;
    console.log("ordersData ", ordersData);
    
    const navigate = useNavigate();
    const [formDataConfig, setFormDataConfig] = useState({
        initialState: {
            id: null,
            payment_status: "",
            delivery_status: ""
        },
        fields: [
            {
                name: "payment_status",
                label: "Payment Status",
                type: "select",
                options: [
                    { label: "select Payment Status", disabled: true },
                    { value: 'paid', label: "Paid" },
                    { value: 'unpaid', label: "Unpaid" }
                ],
                required: false,
            },
            {
                name: "delivery_status",
                label: "Order Status",
                type: "select",
                options: [
                    { label: "select Order Status", disabled: true },
                    { value: 'pending', label: "Pending" },
                    // { value: 'confirmed', label: "Confirmed" },
                    // { value: 'shipped', label: "Shipped" },
                    { value: 'delivered', label: "Delivered" },
                    // { value: 'completed', label: "Completed" },
                    // { value: 'canceled', label: "Canceled" }
                ],
                required: true,
            }
        ],
        
    });


    const handleNavigateToOrders = ()=>{
        navigate(`${process.env.PUBLIC_URL}/business-section/order/:layout`)
    }


    const formatDate = (date) => {
        if (!date) return "";
        const d = new Date(date);
        const month = `${d.getMonth() + 1}`.padStart(2, '0');
        const day = `${d.getDate()}`.padStart(2, '0');
        return `${d.getFullYear()}-${month}-${day}`;
    }

    useEffect(() => {
        if (ordersData) {
            setFormDataConfig(value => ({
                ...value,
                initialState: {
                    ...ordersData,
                    // order_date: formatDate(ordersData.order_date),
                    // delivery_date: formatDate(ordersData.delivery_date),
                }
            }));
        }
    }, [ordersData]);



    const onSave = (formData) => {
        const values = {
			id: formData?.id || '',
			payment_status: formData?.payment_status,
            delivery_status: formData?.delivery_status,
		}
		
		if (formData?.id) {
			patch(API_URL.ORDERS.PATCH_ORDER(formData?.id), values)
				.then((response) => {
					toast.success("Updated successfully");
                    handleNavigateToOrders();
				})
				.catch((error) => {
					console.error("Error updating data:", error);
					toast.error("Oops! something went wrong, try again");
				});
		} 
	};


    return (
        <div>
            <FormCommon
                isModal={false}
                title={`${ordersData ? 'Edit' : 'Add'} Orders`}
                formDataConfig={formDataConfig}
                onSave={onSave}
                // editMode={!!formDataConfig?.initialState?.id}
            />
        </div>
    );
};

export default EditOrder;

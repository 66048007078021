import React, { useEffect, useState } from 'react'
import FormCommon from '../common/FormCommon';
import EditCommonModal from '../common/EditCommonModal';
import { FormGroup, Input, Label } from 'reactstrap';

function Newsize({editSizeChartData,isOpen,toggle,title,onSave,onCancel}) {

    const [isEditing,setIsEditing] = useState(false);
    const [formDataConfig, setFormDataConfig] = useState({
            initialState: {
              id: null,
              size: "",
              order_number:""
            },
            fields: [
              {
                name: "size",
                label: "Size",
                type: "text",
                placeholder:"Enter New Size",
                required: true,
              },
              {
                name: "order_number",
                label: "Order",
                type: "number",
                placeholder:"Enter Order",
                required: true,
              }
            ]
          });
    
 

  useEffect(() => {
    if (editSizeChartData) {
      setIsEditing(true);
      setFormDataConfig(value => ({
        ...value,
        initialState: editSizeChartData,
      }));
    } else {
      setIsEditing(false);
      setFormDataConfig(value => ({
        ...value,
        initialState: {
          id: null,
          size: '',
          order_number: ''
        },
      }));
    }
  }, [editSizeChartData]);


  const handleSave = () => {
    onSave(formDataConfig.initialState);
    setFormDataConfig(value => ({
      ...value,
      initialState: {
        id: null,
        size: '',
        order_number: ''
      },
    }));
  };
          
    const handleCancel = () => {
      onCancel();   
    };            

    const setProductSize = (e) => {
      setFormDataConfig({
        ...formDataConfig,
        initialState: {
          ...formDataConfig.initialState,
          size: e.target.value
        }
      })
    }
    const setOrderNumber = (e) => {
      setFormDataConfig({
        ...formDataConfig,
        initialState: {
          ...formDataConfig.initialState,
          order_number: e.target.value
        }
      })
    }


  return (
    <EditCommonModal
    isOpen={isOpen}
    toggle={toggle}
    title={`${isEditing ? 'Edit' : 'Add'} ${title}`}
    onSave={handleSave}
    onCancel={handleCancel}
    > 
      <div className='mb-4'>
				<FormGroup>
					<Label className="col-form-label pt-0" >Product Size</Label>
					<Input value={formDataConfig.initialState.size} onChange={setProductSize} className="form-control" type="text" placeholder="Enter Product Size" />
				</FormGroup>

        <FormGroup>
          <Label className="col-form-label pt-0" >Order Number</Label>
          <Input value={formDataConfig.initialState.order_number} onChange={setOrderNumber} className="form-control" type="text" placeholder="Enter Order Number" />
				</FormGroup>

       
			</div>

    </EditCommonModal>
  )
}

export default Newsize
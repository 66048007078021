import React, {useCallback, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { preferencesdummydata, preferencestableColumns } from '../common/mockData';
import { Btn, H4 } from '../../../../AbstractElements';
import NewPreference from './NewPreference';
import { API_URL } from '../config/api_urls';
import { DELETE, get, patch, post } from '../config';
import { toast } from 'react-toastify';

function Preference() {
  const [data, setData] = useState([]);
  const [toggleDelet, setToggleDelet] = useState(false);
  const [preferenceModal,setPreferenceModal] = useState(false);
  const [editPreference, setEditPreference] = useState();

  useEffect(() => {
    fetchPreferences();
  }, [])
  
  const fetchPreferences = useCallback(() => {
    get(API_URL.PREFERENCES.GET_PREFERENCES)
      .then((response) => {
        if (response?.data.length > 0)
          setData(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const preferenceToggle = () => {
    setPreferenceModal(!preferenceModal);
};
const editmodalToggle =(row)=>{
  setEditPreference(row)
  setPreferenceModal(!preferenceModal);
}

const handleCancel = () => {
  setPreferenceModal(!preferenceModal);
  setEditPreference(null)
};

const handleSave = async (formData) => {
  if (formData?.id) {
    patch(API_URL.PREFERENCES.PATCH_PREFERENCES(formData?.id), formData)
      .then((response) => {
        toast.success("Updated successfully");
        setPreferenceModal(!preferenceModal);
        setEditPreference(null)
        fetchPreferences();
      })
      .catch((error) => {
        console.error("Error updating data:", error);
        toast.error("Oops! something went wrong");
      });
  }else{
    try {
      await post(API_URL.PREFERENCES.POST_PREFERENCES, formData);
      toast.success("Preference created successfully");
      setPreferenceModal(!preferenceModal);
      setEditPreference(null)
      fetchPreferences();
    } catch (error) {
      console.error("Error saving data:", error);
      toast.error("Oops! something went wrong");
    }
  }
  
};


const handleDeletedata = (row)=>{
  if(row?.id){
   DELETE(API_URL.PREFERENCES.DELETE_PREFERENCES(row?.id))
   .then((response) => {
     toast.success("Deleted successfully");
     fetchPreferences();
   })
   .catch((error) => {
     toast.error("Oops! something went wrong");
   });
  }
 }




  return (
    <>
       <div className={`d-flex align-items-center justify-content-between  p-4`}>
                    <H4 attrH4={{ className: 'text-muted m-0' }}>All Preferences </H4>
                    <Btn attrBtn={{ color: 'primary', onClick: preferenceToggle }}>+ New Preference</Btn>
                    
                      <NewPreference
                      editPreferenceData = {editPreference}
                      isOpen={preferenceModal}
                      toggle={preferenceToggle}
                      title="Preference"
                      onSave={handleSave}
                      onCancel={handleCancel}
                      />
                   
                </div>
    <div>
       <DataTable
                data={data}
                columns={preferencestableColumns(editmodalToggle,handleDeletedata)}
                striped={true}
                center={true}
                pagination
                selectableRows={false}
                clearSelectedRows={toggleDelet}
            />
    </div>
    </>
   
  )
}

export default Preference
import React, { useCallback, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { userstableColumns } from '../common/mockData';
import { useNavigate } from 'react-router';
import { Btn, H4 } from '../../../../AbstractElements';
import '../../styles/user-table.css'
import EditCommonModal from '../common/EditCommonModal';
import FilterForm from './FilterForm';
import { CSVLink } from "react-csv";
import { DELETE, get } from '../config';
import { API_URL } from '../config/api_urls';
import { toast } from 'react-toastify';

function UsersTable() {
	const navigate = useNavigate();
	const [data, setData] = useState([]);
	const [originalData, setOriginalData] = useState([]);
	const [toggleDelet, setToggleDelet] = useState(false);
	const [categoryModal, setCategoryModal] = useState(false);
	const [userRoles, setUserRoles] = useState([]);
	const [userRole, setUserRole] = useState();
	const [gender, setGender] = useState('');
	const [csvData, setCsvData] = useState(data);
	const [editUsersList, setEditUsersList] = useState();
	const [userCategory, setUserCategory] = useState();
	const [countries,setCountries] = useState();
	const [states,setStates] = useState();
	const [hearAboutus,setHearAboutus] = useState();
	

	useEffect(() => {
		fetchUsersList();
		fetchUserRoles();
		fetchCategory();
		fetchCountry();
		fetchStates();
		fetchHearAbout();
		setCsvData(data);
		
		
	}, [])

	//console
	console.log('hai');

	const fetchUsersList = useCallback(() => { 
		get(API_URL.USERS.GET_USERS)
			.then((response) => {
				if (response?.data.length >= 0) {
					setData(response.data);
					setOriginalData(response.data);
				}
			})
			.catch((error) => {
				console.error("Error:", error);
			});
	}, []);

	const fetchUserRoles = useCallback(() => {
		get(API_URL.USER_ROLE.USER_ROLE_GET)
			.then((response) => {
				if (response?.data.length > 0)
					setUserRoles(response.data)
			})
			.catch((error) => {
				console.error("Error:", error);
			});
	}, []);

	const fetchCategory = useCallback(() => {
		get(API_URL.USER_CATEGORY.USER_CATEGORY_GET)
			.then((response) => {
				if (response?.data.length > 0)
					setUserCategory(response.data)
			})
			.catch((error) => {
				console.error("Error:", error);
			});
	}, []);

	const fetchCountry = useCallback(() => {
		get(API_URL.COUNTRIES.COUNTRIES_GET)
			.then((response) => {
				if (response?.data.length > 0)
					setCountries(response.data)
			})
			.catch((error) => {
				console.error("Error:", error);
			});
	}, []);

	const fetchStates = useCallback(() => {
		get(API_URL.STATES.STATES_GET)
			.then((response) => {
				if (response?.data.length > 0)
					setStates(response.data)
			})
			.catch((error) => {
				console.error("Error:", error);
			});
	}, []);

	const fetchHearAbout = useCallback(() => {
		get(API_URL.HEAR_ABOUT_US.HEAR_ABOUT_US_GET)
			.then((response) => {
				if (response?.data.length > 0)
					setHearAboutus(response.data)
			})
			.catch((error) => {
				console.error("Error:", error);
			});
	}, []);


	const handleNavigate = () => {
		navigate(`${process.env.PUBLIC_URL}/users/new/:layout`)
	}

	const categoryToggle = () => {
		setCategoryModal(!categoryModal);
	};

	const handleEditdata = (row) => {
		setEditUsersList(row)
	}


	const handleDeletedata = (row) => {
		if (row?.uuid) {
			DELETE(API_URL.USERS.USERS_DELETE(row?.uuid))
				.then((response) => {
					console.log('delete');

					
					toast.success("Deleted successfully");
					fetchUsersList();

				
				})
				.catch((error) => {
					toast.error("Oops! something went wrong");
				});
		}
	}

	useEffect(() => {
		if (editUsersList) {
			navigate(`${process.env.PUBLIC_URL}/users/new/:layout`, { state: { userData: editUsersList } });
		}
	}, [editUsersList, navigate]);

	const handleSave = () => {
		const matchingUserRole = userRoles.find(i=>i.role === userRole)
		categoryToggle();
		const filteredData = originalData.filter(item => {
			return (matchingUserRole ?  item.user_role_id === matchingUserRole.id : true) && (gender ? item.gender === gender : true);
		})
		setData(filteredData);
		setCsvData(filteredData);
	};
	console.log(data);
	

	const handleCancel = () => {
		categoryToggle();
		setUserRole("");
		setGender("");
		setData(originalData);
		setCsvData(originalData);
	};

	const handleExport = () => {
		setCsvData(data);
	}

	return (
		<>
			<div className="d-flex align-items-center justify-content-between  p-4">
				<H4 attrH4={{ className: 'text-muted m-0' }}>All Users Details</H4>
				<div className='button-group'>
					<Btn attrBtn={{ color: 'primary', onClick: () => handleNavigate() }}>+ Add New User</Btn>
					<Btn attrBtn={{ color: 'primary', className: 'btn ms-2', onClick: categoryToggle }}><span className="title">Filters</span></Btn>
					<EditCommonModal
						isOpen={categoryModal}
						toggle={categoryToggle}
						title="Apply filters"
						onSave={handleSave}
						onCancel={handleCancel}
					>
						<FilterForm
							userRole={userRole}
							userRoles={userRoles}
							setUserRole={setUserRole}
							gender={gender}
							setGender={setGender}
						/>
					</EditCommonModal>

					<Btn attrBtn={{ color: 'primary', className: 'btn ms-2', onClick: () => handleExport() }}><CSVLink data={csvData} filename={"users-data.csv"} style={{ color: "white" }}>Export</CSVLink></Btn>
				</div>

			</div>
			<div className='table-wrapper'>
				<DataTable
					data={data}
					columns={userstableColumns(handleEditdata, handleDeletedata,userRoles,userCategory,countries,states,hearAboutus)}
					striped={true}
					center={true}
					pagination
					selectableRows={false}
					clearSelectedRows={toggleDelet}
				/>
			</div>
		</>
	)
}

export default UsersTable
import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { H5, P, Btn, Image } from '../../../AbstractElements';
import { DepositNow, GreetingMessage4, GreetingMessage4SubMessage } from '../../../Constant';

import WelcomeImage from '../../../assets/images/dashboard-4/crypto.png';
const GreetingCard = () => {
  return (
    <Card className='crypto-main-card'>

    </Card>
  );
};

export default GreetingCard;
import React, { useCallback, useEffect, useState } from 'react'
import FormCommon from '../common/FormCommon';
import { API_URL } from '../config/api_urls';
import { get, patch, post } from '../config';
import { toast } from 'react-toastify';

function IdCardSettings() {
 
    const [data,setData] = useState([]);   
    const [formDataConfig, setFormDataConfig] = useState({
        initialState: {
          id: null,
          idcard_fee: "",
          payment_instructions: "",
          success_description:""
        },
        fields: [
          {
            name: "idcard_fee",
            label: "IdCard Fee",
            type: "text",
            placeholder: "Enter IdCard Fee",
            required: true,
          },
          {
            name: "payment_instructions",
            label: "Payment Instructions",
            type: "text",
            placeholder: "Enter Payment Instructions",
            required: true,
          },
          {
            name: "success_description",
            label: "Success Description",
            placeholder: "Enter Success Description",
            type: "text",
            required: false,
          }
        ]
      });



      useEffect(() => {
        fetchIdcardSettings();
      }, [])
      
      const fetchIdcardSettings = useCallback(() => {
        get(API_URL.IDCARD_SETTINGS.GET_IDCARD_SETTINGS)
          .then((response) => {
            console.log(response);
            
            if (response?.data.length > 0)
              setData(response.data);
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }, []);


      useEffect(()=>{
        if(data && data.length > 0){
          setFormDataConfig(prevValue => ({
            ...prevValue,
            initialState: data[0]
          }));
        }
        },[data])

        const handleSave = async (formData) => {
          if (formData?.id) {
            console.log(formData);
            
            patch(API_URL.IDCARD_SETTINGS.PATCH_IDCARD_SETTINGS(formData?.id), formData)
              .then((response) => {
                console.log(response);
                
                toast.success("Updated successfully");
                fetchIdcardSettings();
              })
              .catch((error) => {
                console.error("Error updating data:", error);
                toast.error("Oops! something went wrong");
              });
          }else{
            try {
              await post(API_URL.IDCARD_SETTINGS.POST_IDCARD_SETTINGS, formData);
              toast.success("IdCard Settings Created successfully");
              fetchIdcardSettings();
            } catch (error) {
              console.error("Error saving data:", error);
              toast.error("Oops! something went wrong");
            }
          }
          
        };




  return (
    <div>
    <FormCommon
    isModal={false}
    title={`IdCard Settings`}
    formDataConfig={formDataConfig} 
    onSave={handleSave}
  />
</div>
  )
}

export default IdCardSettings
import React, { useEffect, useState } from 'react'
import FormCommon from '../common/FormCommon';
import EditCommonModal from '../common/EditCommonModal';
import { FormGroup, Input, Label } from 'reactstrap';

function AddRegionalPartners({regionalPartnerData,isOpen,toggle,title,onSave,onCancel}) {

  const [isEditing,setIsEditing] = useState(false);
  const [formDataConfig, setFormDataConfig] = useState({
            initialState: {
              id: null,
              name: ""
            },
            fields: [
              {
                name: "name",
                label: "Regional Partner",
                type: "text",
                placeholder:"Enter Regional Partner",
                required: true,
              }
            ]
          });

  
          useEffect(() => {
            if (regionalPartnerData) {
              setIsEditing(true);
              setFormDataConfig(value => ({
                ...value,
                initialState: regionalPartnerData,
                
              }));
            } else {
              setIsEditing(false);
              setFormDataConfig(value => ({
                ...value,
                initialState: {
                  id: null,
                  name: ''
                },
              }));
            }
          }, [regionalPartnerData]);        

const handleSave = () => {
  onSave(formDataConfig.initialState);
};
                
const handleCancel = () => {
  console.log("handleCancel");
  onCancel();   
};      
      

const setRegional = (e) => {
  setFormDataConfig({
    ...formDataConfig,
    initialState: {
      ...formDataConfig.initialState,
      name: e.target.value
    }
  })
}
          

  return (
    <EditCommonModal
    isOpen={isOpen}
    toggle={toggle}
    title={`${isEditing ? 'Edit' : 'Add'} ${title}`}
    onSave={handleSave}
    onCancel={handleCancel}
    > 
      <div className='mb-4'>
				<FormGroup>
					<Label className="col-form-label pt-0" >Regional Partner</Label>
					<Input value={formDataConfig.initialState.name} onChange={setRegional} className="form-control" type="text" placeholder="Enter Regional Partner" />
				</FormGroup>
				
			</div>

    </EditCommonModal>
  )
}

export default AddRegionalPartners